export default [
  {
    uid: '1',
    un: '数と式・集合と論理',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: '展開と因数分解',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '展開',
            qnum1: '1',
            qnum2: '1',
            quid: '01_01_01_01',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: '-XFkrK5jw-iT',
            kuids: [ '01_01_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '展開',
            qnum1: '1',
            qnum2: '2',
            quid: '01_01_01_02',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: '24r1o__G9Jjs',
            kuids: [ '01_01_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '展開',
            qnum1: '1',
            qnum2: '3',
            quid: '01_01_01_03',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'eP7pNk_IwXxu',
            kuids: [ '01_01_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '4',
            qn: '展開',
            qnum1: '1',
            qnum2: '4',
            quid: '01_01_01_04',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: '8ZCO9OJMFtMD',
            kuids: [ '01_01_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '5',
            qn: '展開',
            qnum1: '1',
            qnum2: '5',
            quid: '01_01_01_05',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'boOBte_-8duw',
            kuids: [ '01_01_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '6',
            qn: '展開',
            qnum1: '1',
            qnum2: '6',
            quid: '01_01_01_06',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'e4kvMr-AA-9m',
            kuids: [ '01_01_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '7',
            qn: '展開',
            qnum1: '1',
            qnum2: '7',
            quid: '01_01_01_07',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'skDCTDgo5nLw',
            kuids: [ '01_01_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '8',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '1',
            quid: '01_01_02_01',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'kcQ0P-S5gzCu',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '9',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '2',
            quid: '01_01_02_02',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'uT-AQlQhamIv',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '10',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '3',
            quid: '01_01_02_03',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'ABJK1qxfi1xy',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '11',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '4',
            quid: '01_01_02_04',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'R4N-R3dhryhF',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '12',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '5',
            quid: '01_01_02_05',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'NNT4Z1sqI6lz',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '13',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '6',
            quid: '01_01_02_06',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'D-PHp7cptHR4',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '14',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '7',
            quid: '01_01_02_07',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: '3FGLCFMjhYV8',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '15',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '8',
            quid: '01_01_02_08',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'j2lSoUSeTG9i',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '16',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '9',
            quid: '01_01_02_09',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: '33QT3bQsBiMT',
            kuids: [ '01_01_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '17',
            qn: '因数分解',
            qnum1: '2',
            qnum2: '10',
            quid: '01_01_02_10',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'sdjJn3K-zREg',
            kuids: [ '01_01_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '無理数の計算',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '有理数と無理数',
            qnum1: '3',
            qnum2: '0',
            quid: '01_02_03_00',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'eSABLpEWerJC',
            kuids: [ '01_02_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '無理数の計算',
            qnum1: '4',
            qnum2: '1',
            quid: '01_02_04_01',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: '8WkpFQwXDfiX',
            kuids: [ '01_02_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '無理数の計算',
            qnum1: '4',
            qnum2: '2',
            quid: '01_02_04_02',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'UhSkD9bNDcMR',
            kuids: [ '01_02_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '4',
            qn: '無理数の計算',
            qnum1: '4',
            qnum2: '3',
            quid: '01_02_04_03',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'QF9tGNKnqDWw',
            kuids: [ '01_02_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '5',
            qn: '２重根号をはずす',
            qnum1: '5',
            qnum2: '1',
            quid: '01_02_05_01',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'orLhRb9KCL5T',
            kuids: [ '01_02_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '6',
            qn: '２重根号をはずす',
            qnum1: '5',
            qnum2: '2',
            quid: '01_02_05_02',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'PSx_fV5_tgtq',
            kuids: [ '01_02_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '7',
            qn: '２重根号をはずす',
            qnum1: '5',
            qnum2: '3',
            quid: '01_02_05_03',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'rcf0lb32rdBu',
            kuids: [ '01_02_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '8',
            qn: '２重根号をはずす',
            qnum1: '5',
            qnum2: '4',
            quid: '01_02_05_04',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: '_I8qRy1qan2k',
            kuids: [ '01_02_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '9',
            qn: '２重根号をはずす',
            qnum1: '5',
            qnum2: '5',
            quid: '01_02_05_05',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'HMi0jaj2-eLV',
            kuids: [ '01_02_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '10',
            qn: '２重根号をはずす',
            qnum1: '5',
            qnum2: '6',
            quid: '01_02_05_06',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'V6Yl1LtuzWr8',
            kuids: [ '01_02_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '11',
            qn: '整数部分・小数部分',
            qnum1: '6',
            qnum2: '0',
            quid: '01_02_06_00',
            hn: 'I / 数と式・集合と論理 / 無理数の計算',
            vid: 'Ge8YMuCY7lGq',
            kuids: [ '01_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '対称式',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '対称式の基本',
            qnum1: '7',
            qnum2: '0',
            quid: '01_03_07_00',
            hn: 'I / 数と式・集合と論理 / 対称式',
            vid: '-0hWcX35ZTSJ',
            kuids: [ '01_02_05' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '対称式の応用　その１',
            qnum1: '8',
            qnum2: '0',
            quid: '01_03_08_00',
            hn: 'I / 数と式・集合と論理 / 対称式',
            vid: 'YkTmfzAhNxer',
            kuids: [ '01_02_06' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '対称式の応用　その２',
            qnum1: '9',
            qnum2: '0',
            quid: '01_03_09_00',
            hn: 'I / 数と式・集合と論理 / 対称式',
            vid: 'ovM1ZOmrmVPU',
            kuids: [ '01_02_06' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '4',
            qn: '２重根号と小数部分・対称式',
            qnum1: '10',
            qnum2: '0',
            quid: '01_03_10_00',
            hn: 'I / 数と式・集合と論理 / 対称式',
            vid: '3xjJ0uf_Qfae',
            kuids: [ '01_02_03', '01_02_04', '01_02_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '絶対値',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '絶対値をはずす',
            qnum1: '11',
            qnum2: '1',
            quid: '01_04_11_01',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: '69vYTWXuueCi',
            kuids: [ '01_03_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '絶対値をはずす',
            qnum1: '11',
            qnum2: '2',
            quid: '01_04_11_02',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'upux3_blBFZn',
            kuids: [ '01_03_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '２乗の√　は…',
            qnum1: '12',
            qnum2: '0',
            quid: '01_04_12_00',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'eXINk9FS7Fdk',
            kuids: [ '01_03_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '4',
            qn: '絶対値の応用　その１',
            qnum1: '13',
            qnum2: '0',
            quid: '01_04_13_00',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'BfWiTlBaEYoi',
            kuids: [ '01_03_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '5',
            qn: '絶対値の応用　その２',
            qnum1: '14',
            qnum2: '0',
            quid: '01_04_14_00',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'FUbfCewg6KGd',
            kuids: [ '01_03_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '6',
            qn: '絶対値つきの方程式',
            qnum1: '15',
            qnum2: '1',
            quid: '01_04_15_01',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'QiY_2eWxIORj',
            kuids: [ '01_03_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '7',
            qn: '絶対値つきの方程式',
            qnum1: '15',
            qnum2: '2',
            quid: '01_04_15_02',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'MPJs9gu037gs',
            kuids: [ '01_03_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '8',
            qn: '絶対値つきの方程式',
            qnum1: '15',
            qnum2: '3',
            quid: '01_04_15_03',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'sTEdLMGGltlF',
            kuids: [ '01_03_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '１次不等式の基本',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '１次不等式の基本',
            qnum1: '16',
            qnum2: '1',
            quid: '01_05_16_01',
            hn: 'I / 数と式・集合と論理 / １次不等式の基本',
            vid: 'dsyng-fHhWQr',
            kuids: [ '01_04_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '１次不等式の基本',
            qnum1: '16',
            qnum2: '2',
            quid: '01_05_16_02',
            hn: 'I / 数と式・集合と論理 / １次不等式の基本',
            vid: 'eMGcjB-ozUad',
            kuids: [ '01_04_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '１次不等式の基本',
            qnum1: '16',
            qnum2: '3',
            quid: '01_05_16_03',
            hn: 'I / 数と式・集合と論理 / １次不等式の基本',
            vid: 'C3PFxzHZB_UQ',
            kuids: [ '01_04_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '4',
            qn: '連立１次不等式',
            qnum1: '17',
            qnum2: '1',
            quid: '01_05_17_01',
            hn: 'I / 数と式・集合と論理 / １次不等式の基本',
            vid: 'mybxPQkUXaon',
            kuids: [ '01_04_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '5',
            qn: '連立１次不等式',
            qnum1: '17',
            qnum2: '2',
            quid: '01_05_17_02',
            hn: 'I / 数と式・集合と論理 / １次不等式の基本',
            vid: 'XQDVgnXFdKNe',
            kuids: [ '01_04_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '6',
            qn: '１次不等式と整数値（基本）',
            qnum1: '18',
            qnum2: '0',
            quid: '01_05_18_00',
            hn: 'I / 数と式・集合と論理 / １次不等式の基本',
            vid: 'u3xppoRZ4IwJ',
            kuids: [ '01_04_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '１次不等式の応用',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '１次不等式と整数値（応用）',
            qnum1: '19',
            qnum2: '0',
            quid: '01_06_19_00',
            hn: 'I / 数と式・集合と論理 / １次不等式の応用',
            vid: '0JERhIEy30-f',
            kuids: [ '01_04_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '１次不等式　文字場合分け',
            qnum1: '20',
            qnum2: '0',
            quid: '01_06_20_00',
            hn: 'I / 数と式・集合と論理 / １次不等式の応用',
            vid: 'RSGOj7Yox_qN',
            kuids: [ '01_04_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '絶対値つきの１次不等式',
            qnum1: '21',
            qnum2: '1',
            quid: '01_06_21_01',
            hn: 'I / 数と式・集合と論理 / １次不等式の応用',
            vid: 'uIpAWHbTm5j4',
            kuids: [ '01_04_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '4',
            qn: '絶対値つきの１次不等式',
            qnum1: '21',
            qnum2: '2',
            quid: '01_06_21_02',
            hn: 'I / 数と式・集合と論理 / １次不等式の応用',
            vid: '1mFUNPgAz4Nd',
            kuids: [ '01_04_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '5',
            qn: '絶対値つきの１次不等式',
            qnum1: '21',
            qnum2: '3',
            quid: '01_06_21_03',
            hn: 'I / 数と式・集合と論理 / １次不等式の応用',
            vid: '8NYPDLZZhDIS',
            kuids: [ '01_04_03' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '6',
            qn: '１次不等式の文章題',
            qnum1: '22',
            qnum2: '0',
            quid: '01_06_22_00',
            hn: 'I / 数と式・集合と論理 / １次不等式の応用',
            vid: 'dJc0YgdyEOv2',
            kuids: [ '01_04_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '集合',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '２つの集合の要素の決定',
            qnum1: '23',
            qnum2: '0',
            quid: '01_07_23_00',
            hn: 'I / 数と式・集合と論理 / 集合',
            vid: 'lRmGGol0RA-R',
            kuids: [ '01_05_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '連続型の集合の包含関係',
            qnum1: '24',
            qnum2: '0',
            quid: '01_07_24_00',
            hn: 'I / 数と式・集合と論理 / 集合',
            vid: 'VXLjdYfVDJyG',
            kuids: [ '01_05_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '３つの集合の要素の決定',
            qnum1: '25',
            qnum2: '0',
            quid: '01_07_25_00',
            hn: 'I / 数と式・集合と論理 / 集合',
            vid: 'im3N5H4N5prf',
            kuids: [ '01_05_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '論理（その１）',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '命題と真偽',
            qnum1: '26',
            qnum2: '0',
            quid: '01_08_26_00',
            hn: 'I / 数と式・集合と論理 / 論理（その１）',
            vid: 'Sqd-UdvKe5aR',
            kuids: [ '01_06_01' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '否定',
            qnum1: '27',
            qnum2: '0',
            quid: '01_08_27_00',
            hn: 'I / 数と式・集合と論理 / 論理（その１）',
            vid: 'CCJfbRbwS7G3',
            kuids: [ '01_06_02' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '3',
            qn: '逆・裏・対偶',
            qnum1: '28',
            qnum2: '0',
            quid: '01_08_28_00',
            hn: 'I / 数と式・集合と論理 / 論理（その１）',
            vid: '6ju2hGUXIwsO',
            kuids: [ '01_06_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '論理（その２）',
        hn: 'I / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a',
            qid: '1',
            qn: '必要条件・十分条件',
            qnum1: '29',
            qnum2: '0',
            quid: '01_09_29_00',
            hn: 'I / 数と式・集合と論理 / 論理（その２）',
            vid: 'ZGnNKqK4Iuao',
            kuids: [ '01_06_04' ],
            record: {}
          },
          {
            uid: '1',
            fid: '1a',
            qid: '2',
            qn: '対偶法・背理法',
            qnum1: '30',
            qnum2: '0',
            quid: '01_09_30_00',
            hn: 'I / 数と式・集合と論理 / 論理（その２）',
            vid: 'ATGrDFaRhLPM',
            kuids: [ '01_06_05' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '2',
    un: '２次関数',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: '２次関数のグラフ',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '頂点・平行移動',
            qnum1: '1',
            qnum2: '0',
            quid: '02_01_01_00',
            hn: 'I / ２次関数 / ２次関数のグラフ',
            vid: '-5SpHxxacJzT',
            kuids: [ '02_01_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '対称移動',
            qnum1: '2',
            qnum2: '0',
            quid: '02_01_02_00',
            hn: 'I / ２次関数 / ２次関数のグラフ',
            vid: 'kXwe-hyrZr4X',
            kuids: [ '02_01_02' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: '平行＆対称移動',
            qnum1: '3',
            qnum2: '0',
            quid: '02_01_03_00',
            hn: 'I / ２次関数 / ２次関数のグラフ',
            vid: '_iXUFqYaP_Rv',
            kuids: [ '02_01_01', '02_01_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '最大最小（基本）',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '超基本',
            qnum1: '4',
            qnum2: '0',
            quid: '02_02_04_00',
            hn: 'I / ２次関数 / 最大最小（基本）',
            vid: 'NZZ0ZK-CvRzo',
            kuids: [ '02_02_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '予選決勝その１',
            qnum1: '5',
            qnum2: '0',
            quid: '02_02_05_00',
            hn: 'I / ２次関数 / 最大最小（基本）',
            vid: 'cYXjdyOfk-Pu',
            kuids: [ '02_02_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: '係数決定その１',
            qnum1: '6',
            qnum2: '0',
            quid: '02_02_06_00',
            hn: 'I / ２次関数 / 最大最小（基本）',
            vid: 'sPuIG73xm9XS',
            kuids: [ '02_02_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '4',
            qn: '係数決定その２',
            qnum1: '7',
            qnum2: '0',
            quid: '02_02_07_00',
            hn: 'I / ２次関数 / 最大最小（基本）',
            vid: '7LaiWaMZatCp',
            kuids: [ '02_02_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '5',
            qn: '予選決勝その２',
            qnum1: '8',
            qnum2: '0',
            quid: '02_02_08_00',
            hn: 'I / ２次関数 / 最大最小（基本）',
            vid: 'HxpRfam3Xcos',
            kuids: [ '02_02_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '最大最小（条件つき）',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '文字消去',
            qnum1: '9',
            qnum2: '0',
            quid: '02_03_09_00',
            hn: 'I / ２次関数 / 最大最小（条件つき）',
            vid: 'cAbk-h6feWaW',
            kuids: [ '02_02_02' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '置き換え',
            qnum1: '10',
            qnum2: '0',
            quid: '02_03_10_00',
            hn: 'I / ２次関数 / 最大最小（条件つき）',
            vid: 'EHo8Re9NmWMJ',
            kuids: [ '02_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '最大最小（文字場合分け）',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: 'グラフが動く（場合分け練習）',
            qnum1: '11',
            qnum2: '0',
            quid: '02_04_11_00',
            hn: 'I / ２次関数 / 最大最小（文字場合分け）',
            vid: 'EIJGn5vMxt7u',
            kuids: [ '02_02_04' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: 'グラフが動く（係数決定その１）',
            qnum1: '12',
            qnum2: '0',
            quid: '02_04_12_00',
            hn: 'I / ２次関数 / 最大最小（文字場合分け）',
            vid: 'M6J1LFv_vyxQ',
            kuids: [ '02_02_04' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: 'グラフが動く（係数決定その２）',
            qnum1: '13',
            qnum2: '0',
            quid: '02_04_13_00',
            hn: 'I / ２次関数 / 最大最小（文字場合分け）',
            vid: 'VX-DBh5MT5d6',
            kuids: [ '02_02_04' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '4',
            qn: '区間が動く',
            qnum1: '14',
            qnum2: '0',
            quid: '02_04_14_00',
            hn: 'I / ２次関数 / 最大最小（文字場合分け）',
            vid: 'n77eh9gKTSi0',
            kuids: [ '02_02_05' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '5',
            qn: '「条件つき」との融合',
            qnum1: '15',
            qnum2: '0',
            quid: '02_04_15_00',
            hn: 'I / ２次関数 / 最大最小（文字場合分け）',
            vid: 'tBvIkmhblQgv',
            kuids: [ '02_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '２次関数の決定',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '２次関数を作る',
            qnum1: '16',
            qnum2: '1',
            quid: '02_05_16_01',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: 'nHVVIGofOoX0',
            kuids: [ '02_03_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '２次関数を作る',
            qnum1: '16',
            qnum2: '2',
            quid: '02_05_16_02',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: '744cs6ZjAi9M',
            kuids: [ '02_03_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: '２次関数を作る',
            qnum1: '16',
            qnum2: '3',
            quid: '02_05_16_03',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: '_jj29vlDF3bb',
            kuids: [ '02_03_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '4',
            qn: '２次関数を作る',
            qnum1: '16',
            qnum2: '4',
            quid: '02_05_16_04',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: 'k0ueQdzyXrYP',
            kuids: [ '02_03_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '5',
            qn: '２次関数を作る',
            qnum1: '16',
            qnum2: '5',
            quid: '02_05_16_05',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: 'agC2iBfMkexy',
            kuids: [ '02_03_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '6',
            qn: '２次関数を作る',
            qnum1: '16',
            qnum2: '6',
            quid: '02_05_16_06',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: '5z2Pd3eTCkfz',
            kuids: [ '02_03_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '7',
            qn: '２次関数を作る',
            qnum1: '16',
            qnum2: '7',
            quid: '02_05_16_07',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: 'XELwbmk9zmXW',
            kuids: [ '02_03_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '8',
            qn: '符号決定',
            qnum1: '17',
            qnum2: '0',
            quid: '02_05_17_00',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: 'awSmcNh65EbY',
            kuids: [ '02_03_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '２次方程式の解の個数　その１',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '２次方程式の解の個数（基本）',
            qnum1: '18',
            qnum2: '0',
            quid: '02_06_18_00',
            hn: 'I / ２次関数 / ２次方程式の解の個数　その１',
            vid: 'w5xnQU0inu3S',
            kuids: [ '02_04_02' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '２次方程式の解の個数（ただ一つの解）',
            qnum1: '19',
            qnum2: '0',
            quid: '02_06_19_00',
            hn: 'I / ２次関数 / ２次方程式の解の個数　その１',
            vid: '1fE650uQ_o9p',
            kuids: [ '02_04_02' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: '２次関数とｘ軸との関係（基本）',
            qnum1: '20',
            qnum2: '0',
            quid: '02_06_20_00',
            hn: 'I / ２次関数 / ２次方程式の解の個数　その１',
            vid: 'T9SDbKEPTahM',
            kuids: [ '02_04_02' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '4',
            qn: '２次関数とｘ軸との関係（接する）',
            qnum1: '21',
            qnum2: '0',
            quid: '02_06_21_00',
            hn: 'I / ２次関数 / ２次方程式の解の個数　その１',
            vid: 'WBpCWaCzmYqh',
            kuids: [ '02_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '２次不等式の基本',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '２次不等式を解く',
            qnum1: '22',
            qnum2: '1',
            quid: '02_07_22_01',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'IF_Dn8ASgbJ0',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '２次不等式を解く',
            qnum1: '22',
            qnum2: '2',
            quid: '02_07_22_02',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'fwmFy8Wj1nOY',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: '２次不等式を解く',
            qnum1: '22',
            qnum2: '3',
            quid: '02_07_22_03',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'q610gvlDDogH',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '4',
            qn: '２次不等式を解く',
            qnum1: '22',
            qnum2: '4',
            quid: '02_07_22_04',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'U9BOgjh9Rr4R',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '5',
            qn: '２次不等式を解く',
            qnum1: '22',
            qnum2: '5',
            quid: '02_07_22_05',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'mw-zliZB9VQS',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '6',
            qn: '２次不等式を解く',
            qnum1: '22',
            qnum2: '6',
            quid: '02_07_22_06',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'xvl9M9Gavqor',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '7',
            qn: '２次不等式を解く',
            qnum1: '22',
            qnum2: '7',
            quid: '02_07_22_07',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'Hi8rjHuWBmpF',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '8',
            qn: '２次不等式の連立',
            qnum1: '23',
            qnum2: '1',
            quid: '02_07_23_01',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'BQevxqXnFRmW',
            kuids: [ '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '9',
            qn: '２次不等式の連立',
            qnum1: '23',
            qnum2: '2',
            quid: '02_07_23_02',
            hn: 'I / ２次関数 / ２次不等式の基本',
            vid: 'bCOzxEj6h0uo',
            kuids: [ '02_05_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '２次方程式の解の個数　その２',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '２次方程式の解の個数（２次不等式活用）',
            qnum1: '24',
            qnum2: '0',
            quid: '02_08_24_00',
            hn: 'I / ２次関数 / ２次方程式の解の個数　その２',
            vid: 'O46pWY7a_-gY',
            kuids: [ '02_04_02', '02_05_01' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '２次関数と直線の位置関係',
            qnum1: '25',
            qnum2: '0',
            quid: '02_08_25_00',
            hn: 'I / ２次関数 / ２次方程式の解の個数　その２',
            vid: 'BBRmfnxk1pi0',
            kuids: [ '02_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '２次方程式の解の配置',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '２つの正の解',
            qnum1: '26',
            qnum2: '0',
            quid: '02_09_26_00',
            hn: 'I / ２次関数 / ２次方程式の解の配置',
            vid: 'fNkHiPb1DlFQ',
            kuids: [ '02_04_03' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: 'ともに、〜より大（小）',
            qnum1: '27',
            qnum2: '0',
            quid: '02_09_27_00',
            hn: 'I / ２次関数 / ２次方程式の解の配置',
            vid: 'yrszaXx_DQuP',
            kuids: [ '02_04_03' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: '総合',
            qnum1: '28',
            qnum2: '0',
            quid: '02_09_28_00',
            hn: 'I / ２次関数 / ２次方程式の解の配置',
            vid: 'lN3cqKeHCe30',
            kuids: [ '02_04_03' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '4',
            qn: '２解が〜と〜の間',
            qnum1: '29',
            qnum2: '0',
            quid: '02_09_29_00',
            hn: 'I / ２次関数 / ２次方程式の解の配置',
            vid: 'Uyb3SJsqcyth',
            kuids: [ '02_04_03' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '5',
            qn: '端点のみ',
            qnum1: '30',
            qnum2: '0',
            quid: '02_09_30_00',
            hn: 'I / ２次関数 / ２次方程式の解の配置',
            vid: '369nhPHAAC5y',
            kuids: [ '02_04_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '２次不等式の応用',
        hn: 'I / ２次関数',
        questions: [
          {
            uid: '2',
            fid: '1a',
            qid: '1',
            qn: '係数決定',
            qnum1: '31',
            qnum2: '0',
            quid: '02_10_31_00',
            hn: 'I / ２次関数 / ２次不等式の応用',
            vid: 'AkK_dfEUChLO',
            kuids: [ '02_05_02' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '2',
            qn: '整数値の個数',
            qnum1: '32',
            qnum2: '0',
            quid: '02_10_32_00',
            hn: 'I / ２次関数 / ２次不等式の応用',
            vid: 'prCkRlxHSKRH',
            kuids: [ '02_05_03' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '3',
            qn: '絶対不等式（下に凸）',
            qnum1: '33',
            qnum2: '0',
            quid: '02_10_33_00',
            hn: 'I / ２次関数 / ２次不等式の応用',
            vid: 'pk7Y8iTQ1Hpo',
            kuids: [ '02_05_04' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '4',
            qn: '絶対不等式（下に凸？）',
            qnum1: '34',
            qnum2: '0',
            quid: '02_10_34_00',
            hn: 'I / ２次関数 / ２次不等式の応用',
            vid: 'H94KfoWvW3KG',
            kuids: [ '02_05_04' ],
            record: {}
          },
          {
            uid: '2',
            fid: '1a',
            qid: '5',
            qn: '絶対不等式（範囲限定）',
            qnum1: '35',
            qnum2: '0',
            quid: '02_10_35_00',
            hn: 'I / ２次関数 / ２次不等式の応用',
            vid: 'cWCEiaKcME1P',
            kuids: [ '02_05_04' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '3',
    un: '図形と計量',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: '三角比の値',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '三角比の定義',
            qnum1: '1',
            qnum2: '0',
            quid: '03_01_01_00',
            hn: 'I / 図形と計量 / 三角比の値',
            vid: '3j-nq3fouttN',
            kuids: [ '03_01_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '１５°、７５°、７２°などの三角比',
            qnum1: '2',
            qnum2: '0',
            quid: '03_01_02_00',
            hn: 'I / 図形と計量 / 三角比の値',
            vid: '2L98P_xI5gCR',
            kuids: [ '03_01_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '3',
            qn: '基本的な値',
            qnum1: '3',
            qnum2: '0',
            quid: '03_01_03_00',
            hn: 'I / 図形と計量 / 三角比の値',
            vid: 'lIJ8kjh_YWvv',
            kuids: [ '03_01_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '方程式と不等式',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '方程式の基本',
            qnum1: '4',
            qnum2: '1',
            quid: '03_02_04_01',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'umdIr-0bZJrn',
            kuids: [ '03_02_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '方程式の基本',
            qnum1: '4',
            qnum2: '2',
            quid: '03_02_04_02',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'OGv_DhKId6om',
            kuids: [ '03_02_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '3',
            qn: '方程式の基本',
            qnum1: '4',
            qnum2: '3',
            quid: '03_02_04_03',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'KGAlTArELmE7',
            kuids: [ '03_02_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '4',
            qn: '方程式の基本',
            qnum1: '4',
            qnum2: '4',
            quid: '03_02_04_04',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'vGxnaHeYAsfP',
            kuids: [ '03_02_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '5',
            qn: '不等式の基本',
            qnum1: '5',
            qnum2: '1',
            quid: '03_02_05_01',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'IyIEY_wKIqqu',
            kuids: [ '03_03_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '6',
            qn: '不等式の基本',
            qnum1: '5',
            qnum2: '2',
            quid: '03_02_05_02',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'EHX3gHke5496',
            kuids: [ '03_03_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '7',
            qn: '不等式の基本',
            qnum1: '5',
            qnum2: '3',
            quid: '03_02_05_03',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'OrtJUw1gVisW',
            kuids: [ '03_03_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '8',
            qn: '不等式の基本',
            qnum1: '5',
            qnum2: '4',
            quid: '03_02_05_04',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: '3gxtlGt01CMP',
            kuids: [ '03_03_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '9',
            qn: '不等式の基本',
            qnum1: '5',
            qnum2: '5',
            quid: '03_02_05_05',
            hn: 'I / 図形と計量 / 方程式と不等式',
            vid: 'L2w5rdGSqBe1',
            kuids: [ '03_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '三角比の相互関係',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '相互関係（基本）',
            qnum1: '6',
            qnum2: '1',
            quid: '03_03_06_01',
            hn: 'I / 図形と計量 / 三角比の相互関係',
            vid: 'lM0kKgzzo5Of',
            kuids: [ '03_04_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '相互関係（基本）',
            qnum1: '6',
            qnum2: '2',
            quid: '03_03_06_02',
            hn: 'I / 図形と計量 / 三角比の相互関係',
            vid: 'lJVy3fkO3vOZ',
            kuids: [ '03_04_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '3',
            qn: '相互関係（基本）',
            qnum1: '6',
            qnum2: '3',
            quid: '03_03_06_03',
            hn: 'I / 図形と計量 / 三角比の相互関係',
            vid: 'TgfwyE7yXtQ4',
            kuids: [ '03_04_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '4',
            qn: '相互関係（応用）',
            qnum1: '7',
            qnum2: '1',
            quid: '03_03_07_01',
            hn: 'I / 図形と計量 / 三角比の相互関係',
            vid: '0qan8cQ_6Hh3',
            kuids: [ '03_04_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '5',
            qn: '相互関係（応用）',
            qnum1: '7',
            qnum2: '2',
            quid: '03_03_07_02',
            hn: 'I / 図形と計量 / 三角比の相互関係',
            vid: '8BkKmFpq2XSn',
            kuids: [ '03_04_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '6',
            qn: '方程式応用',
            qnum1: '8',
            qnum2: '0',
            quid: '03_03_08_00',
            hn: 'I / 図形と計量 / 三角比の相互関係',
            vid: '_B9JsWtMH3LX',
            kuids: [ '03_04_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '7',
            qn: '最大最小',
            qnum1: '9',
            qnum2: '0',
            quid: '03_03_09_00',
            hn: 'I / 図形と計量 / 三角比の相互関係',
            vid: 'XVHGHdy-3yMa',
            kuids: [ '03_04_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '４５°以下の三角比に直す',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '４５°以下の基本',
            qnum1: '10',
            qnum2: '0',
            quid: '03_04_10_00',
            hn: 'I / 図形と計量 / ４５°以下の三角比に直す',
            vid: 'onH1ZUXVDTfA',
            kuids: [ '03_05_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '４５°以下の少し応用',
            qnum1: '11',
            qnum2: '1',
            quid: '03_04_11_01',
            hn: 'I / 図形と計量 / ４５°以下の三角比に直す',
            vid: 'HhgAR0geistR',
            kuids: [ '03_05_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '3',
            qn: '４５°以下の少し応用',
            qnum1: '11',
            qnum2: '2',
            quid: '03_04_11_02',
            hn: 'I / 図形と計量 / ４５°以下の三角比に直す',
            vid: '7TmS_k5F0VDS',
            kuids: [ '03_05_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '正弦・余弦定理と三角形の面積',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '正弦＆余弦定理（基本）',
            qnum1: '12',
            qnum2: '1',
            quid: '03_05_12_01',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: 'V5BbxcWKE5Df',
            kuids: [ '03_06_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '正弦＆余弦定理（基本）',
            qnum1: '12',
            qnum2: '2',
            quid: '03_05_12_02',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: 'f0IdM4M7PAou',
            kuids: [ '03_06_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '3',
            qn: '正弦＆余弦定理（基本）',
            qnum1: '12',
            qnum2: '3',
            quid: '03_05_12_03',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: 'XtclFmA_elrc',
            kuids: [ '03_07_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '4',
            qn: '正弦＆余弦定理（基本）',
            qnum1: '12',
            qnum2: '4',
            quid: '03_05_12_04',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: 'CCxOqlr6JGMc',
            kuids: [ '03_07_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '5',
            qn: '正弦＆余弦定理（基本）',
            qnum1: '12',
            qnum2: '5',
            quid: '03_05_12_05',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: 'vlzegT7Ig_WO',
            kuids: [ '03_07_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '6',
            qn: '正弦＆余弦定理（応用）',
            qnum1: '13',
            qnum2: '0',
            quid: '03_05_13_00',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: 'DqIWrHRvwuTK',
            kuids: [ '03_07_02' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '7',
            qn: '三角形の面積',
            qnum1: '14',
            qnum2: '1',
            quid: '03_05_14_01',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: '8sRurc1abomt',
            kuids: [ '03_08_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '8',
            qn: '三角形の面積',
            qnum1: '14',
            qnum2: '2',
            quid: '03_05_14_02',
            hn: 'I / 図形と計量 / 正弦・余弦定理と三角形の面積',
            vid: 'R1CJNRpAAIMX',
            kuids: [ '03_08_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '入試５頻出　その１',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '三角形のすべてを求める（基本）',
            qnum1: '15',
            qnum2: '0',
            quid: '03_06_15_00',
            hn: 'I / 図形と計量 / 入試５頻出　その１',
            vid: 'cRjNlrb1KCkw',
            kuids: [ '03_09_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '三角形のすべてを求める（応用）',
            qnum1: '16',
            qnum2: '0',
            quid: '03_06_16_00',
            hn: 'I / 図形と計量 / 入試５頻出　その１',
            vid: 'IEQlY8VFW2-U',
            kuids: [ '03_09_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '3',
            qn: '三角形の形状決定',
            qnum1: '17',
            qnum2: '1',
            quid: '03_06_17_01',
            hn: 'I / 図形と計量 / 入試５頻出　その１',
            vid: 'KS-c-uLjZz0M',
            kuids: [ '03_10_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '4',
            qn: '三角形の形状決定',
            qnum1: '17',
            qnum2: '2',
            quid: '03_06_17_02',
            hn: 'I / 図形と計量 / 入試５頻出　その１',
            vid: 'jr_g__OTUJ-V',
            kuids: [ '03_10_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '5',
            qn: '三角形の形状決定',
            qnum1: '17',
            qnum2: '3',
            quid: '03_06_17_03',
            hn: 'I / 図形と計量 / 入試５頻出　その１',
            vid: 'fhibva2Si0jG',
            kuids: [ '03_10_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '6',
            qn: '形状決定（応用）',
            qnum1: '18',
            qnum2: '0',
            quid: '03_06_18_00',
            hn: 'I / 図形と計量 / 入試５頻出　その１',
            vid: 'imoTsLFxztEc',
            kuids: [ '03_10_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '入試５頻出　その２',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '角の二等分線の長さを求める',
            qnum1: '19',
            qnum2: '1',
            quid: '03_07_19_01',
            hn: 'I / 図形と計量 / 入試５頻出　その２',
            vid: 'qWFvouGTiTIn',
            kuids: [ '03_11_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '角の二等分線の長さを求める',
            qnum1: '19',
            qnum2: '2',
            quid: '03_07_19_02',
            hn: 'I / 図形と計量 / 入試５頻出　その２',
            vid: 'XYFC9NEjQpWb',
            kuids: [ '03_11_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '3',
            qn: '円に内接する四角形（基本）',
            qnum1: '20',
            qnum2: '0',
            quid: '03_07_20_00',
            hn: 'I / 図形と計量 / 入試５頻出　その２',
            vid: 'ns9ugpc15eIW',
            kuids: [ '03_12_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '4',
            qn: '円に内接する四角形（応用）',
            qnum1: '21',
            qnum2: '0',
            quid: '03_07_21_00',
            hn: 'I / 図形と計量 / 入試５頻出　その２',
            vid: 'wZ0476A-yjCR',
            kuids: [ '03_12_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '入試５頻出　その３',
        hn: 'I / 図形と計量',
        questions: [
          {
            uid: '3',
            fid: '1a',
            qid: '1',
            qn: '立体図形（正四面体）',
            qnum1: '22',
            qnum2: '0',
            quid: '03_08_22_00',
            hn: 'I / 図形と計量 / 入試５頻出　その３',
            vid: '1g5k_Bfhfn7L',
            kuids: [ '03_13_01' ],
            record: {}
          },
          {
            uid: '3',
            fid: '1a',
            qid: '2',
            qn: '立体図形（直方体）',
            qnum1: '23',
            qnum2: '0',
            quid: '03_08_23_00',
            hn: 'I / 図形と計量 / 入試５頻出　その３',
            vid: 'k65S5i5C0Gfx',
            kuids: [ '03_13_01' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '4',
    un: 'データの分析',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: 'データの整理・平均値',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: 'データの整理',
            qnum1: '1',
            qnum2: '0',
            quid: '04_01_01_00',
            hn: 'I / データの分析 / データの整理・平均値',
            vid: '1PhWosLYHBlQ',
            kuids: [ '04_01_01' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '2',
            qn: '平均値',
            qnum1: '2',
            qnum2: '0',
            quid: '04_01_02_00',
            hn: 'I / データの分析 / データの整理・平均値',
            vid: 'QZjkWO3FKh92',
            kuids: [ '04_01_02' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '3',
            qn: '仮平均',
            qnum1: '3',
            qnum2: '0',
            quid: '04_01_03_00',
            hn: 'I / データの分析 / データの整理・平均値',
            vid: 'X6LmZLErYhuC',
            kuids: [ '04_01_02' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '4',
            qn: '度数分布表と仮平均',
            qnum1: '4',
            qnum2: '0',
            quid: '04_01_04_00',
            hn: 'I / データの分析 / データの整理・平均値',
            vid: 'pvUzTimNdgdr',
            kuids: [ '04_01_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '中央値・最頻値',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: '中央値・最頻値',
            qnum1: '5',
            qnum2: '0',
            quid: '04_02_05_00',
            hn: 'I / データの分析 / 中央値・最頻値',
            vid: 'eRoYPXJ2rUPb',
            kuids: [ '04_01_03' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '2',
            qn: '度数分布表と中央値・最頻値',
            qnum1: '6',
            qnum2: '0',
            quid: '04_02_06_00',
            hn: 'I / データの分析 / 中央値・最頻値',
            vid: '59HN0GopBmUs',
            kuids: [ '04_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: 'データの散らばり　その１',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: '四分位数・四分位範囲・四分位偏差',
            qnum1: '7',
            qnum2: '0',
            quid: '04_03_07_00',
            hn: 'I / データの分析 / データの散らばり　その１',
            vid: 'I5LIQxAT248x',
            kuids: [ '04_02_01' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '2',
            qn: '箱ひげ図をかく',
            qnum1: '8',
            qnum2: '0',
            quid: '04_03_08_00',
            hn: 'I / データの分析 / データの散らばり　その１',
            vid: 'bnl2xWLbiMAF',
            kuids: [ '04_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: 'データの散らばり　その２',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: '箱ひげ図の読み取り',
            qnum1: '9',
            qnum2: '0',
            quid: '04_04_09_00',
            hn: 'I / データの分析 / データの散らばり　その２',
            vid: 'ENrZRHUfT4Wc',
            kuids: [ '04_02_02' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '2',
            qn: '箱ひげ図とヒストグラム',
            qnum1: '10',
            qnum2: '0',
            quid: '04_04_10_00',
            hn: 'I / データの分析 / データの散らばり　その２',
            vid: 'X_d7xfxKU_Nb',
            kuids: [ '04_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '分散と標準偏差　その１',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: '基本',
            qnum1: '11',
            qnum2: '0',
            quid: '04_05_11_00',
            hn: 'I / データの分析 / 分散と標準偏差　その１',
            vid: 'q20TrwJOz04t',
            kuids: [ '04_03_01' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '2',
            qn: '合体データの分散',
            qnum1: '12',
            qnum2: '0',
            quid: '04_05_12_00',
            hn: 'I / データの分析 / 分散と標準偏差　その１',
            vid: 'tgjNbe2orQPo',
            kuids: [ '04_03_01', '04_03_02' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '3',
            qn: '度数分布表と分散・標準偏差　その１',
            qnum1: '13',
            qnum2: '0',
            quid: '04_05_13_00',
            hn: 'I / データの分析 / 分散と標準偏差　その１',
            vid: 'uZ5uMW-hrWt7',
            kuids: [ '04_03_01', '04_03_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '分散と標準偏差　その２',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: '２つのデータの散らばりの度合いの比較',
            qnum1: '14',
            qnum2: '0',
            quid: '04_06_14_00',
            hn: 'I / データの分析 / 分散と標準偏差　その２',
            vid: '5WCeoYjKtZIs',
            kuids: [ '04_03_01' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '2',
            qn: '度数分布表と分散・標準偏差　その２',
            qnum1: '15',
            qnum2: '0',
            quid: '04_06_15_00',
            hn: 'I / データの分析 / 分散と標準偏差　その２',
            vid: '2rzxF7xszdE3',
            kuids: [ '04_03_03', '04_03_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '散布図・相関表',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: '散布図',
            qnum1: '16',
            qnum2: '0',
            quid: '04_07_16_00',
            hn: 'I / データの分析 / 散布図・相関表',
            vid: 'Vj_DMgAvNFx8',
            kuids: [ '04_04_01' ],
            record: {}
          },
          {
            uid: '4',
            fid: '1a',
            qid: '2',
            qn: '相関表',
            qnum1: '17',
            qnum2: '0',
            quid: '04_07_17_00',
            hn: 'I / データの分析 / 散布図・相関表',
            vid: 'ZZ7XC8V8sgUe',
            kuids: [ '04_04_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '相関係数',
        hn: 'I / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a',
            qid: '1',
            qn: '相関係数と相関関係',
            qnum1: '18',
            qnum2: '0',
            quid: '04_08_18_00',
            hn: 'I / データの分析 / 相関係数',
            vid: 'arVBneDx-JoE',
            kuids: [ '04_04_02' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '5',
    un: '場合の数と確率',
    fid: '1a',
    hn: 'A',
    prints: [
      {
        pid: '1',
        pn: '順列　その１',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '「〜の倍数」の個数',
            qnum1: '1',
            qnum2: '0',
            quid: '05_01_01_00',
            hn: 'A / 場合の数と確率 / 順列　その１',
            vid: 'w36Ong9r8vjj',
            kuids: [ '05_01_01', '05_01_02' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '〜番目の数字',
            qnum1: '2',
            qnum2: '0',
            quid: '05_01_02_00',
            hn: 'A / 場合の数と確率 / 順列　その１',
            vid: '8_qjW3Edzttw',
            kuids: [ '05_01_01', '05_01_02' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '隣り合う、隣り合わない',
            qnum1: '3',
            qnum2: '0',
            quid: '05_01_03_00',
            hn: 'A / 場合の数と確率 / 順列　その１',
            vid: '6FrywbOQViBn',
            kuids: [ '05_01_01', '05_01_02' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '4',
            qn: '辞書式配列法',
            qnum1: '4',
            qnum2: '0',
            quid: '05_01_04_00',
            hn: 'A / 場合の数と確率 / 順列　その１',
            vid: 'pKh_Q66IWar7',
            kuids: [ '05_01_01', '05_01_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '順列　その２',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '同じもののある順列',
            qnum1: '5',
            qnum2: '0',
            quid: '05_02_05_00',
            hn: 'A / 場合の数と確率 / 順列　その２',
            vid: 'WSyKxrUJK2vI',
            kuids: [ '05_01_03' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '円順列',
            qnum1: '6',
            qnum2: '0',
            quid: '05_02_06_00',
            hn: 'A / 場合の数と確率 / 順列　その２',
            vid: '_7_hf7No2nYD',
            kuids: [ '05_01_04' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '円順列と数珠順列',
            qnum1: '7',
            qnum2: '0',
            quid: '05_02_07_00',
            hn: 'A / 場合の数と確率 / 順列　その２',
            vid: 'nSqw7xCxzlgj',
            kuids: [ '05_01_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '順列　その３',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '正多面体の面の塗り分け',
            qnum1: '8',
            qnum2: '0',
            quid: '05_03_08_00',
            hn: 'A / 場合の数と確率 / 順列　その３',
            vid: 'KDECtGWuk13I',
            kuids: [ '05_01_04' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '重複順列',
            qnum1: '9',
            qnum2: '0',
            quid: '05_03_09_00',
            hn: 'A / 場合の数と確率 / 順列　その３',
            vid: 'y_RLXY2D2OKm',
            kuids: [ '05_01_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '組み合わせの基本',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '組み合わせの基本',
            qnum1: '10',
            qnum2: '0',
            quid: '05_04_10_00',
            hn: 'A / 場合の数と確率 / 組み合わせの基本',
            vid: '-KtzEZfg4QVM',
            kuids: [ '05_02_01' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '平行四辺形の個数',
            qnum1: '11',
            qnum2: '0',
            quid: '05_04_11_00',
            hn: 'A / 場合の数と確率 / 組み合わせの基本',
            vid: 'W3KVGbJHM-F0',
            kuids: [ '05_02_01' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: 'ｎＣｒの定義',
            qnum1: '12',
            qnum2: '1',
            quid: '05_04_12_01',
            hn: 'A / 場合の数と確率 / 組み合わせの基本',
            vid: '4YsIjGKZUouO',
            kuids: [ '05_02_01' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '4',
            qn: 'ｎＣｒの定義',
            qnum1: '12',
            qnum2: '2',
            quid: '05_04_12_02',
            hn: 'A / 場合の数と確率 / 組み合わせの基本',
            vid: 'UCLjeHfpS7dM',
            kuids: [ '05_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '重複組み合わせ',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '整数解方程式',
            qnum1: '13',
            qnum2: '0',
            quid: '05_05_13_00',
            hn: 'A / 場合の数と確率 / 重複組み合わせ',
            vid: 'EGn2hNk34xqX',
            kuids: [ '05_02_02' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '重複組み合わせ',
            qnum1: '14',
            qnum2: '1',
            quid: '05_05_14_01',
            hn: 'A / 場合の数と確率 / 重複組み合わせ',
            vid: 'rXrbEGtkqEWp',
            kuids: [ '05_02_02' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '重複組み合わせ',
            qnum1: '14',
            qnum2: '2',
            quid: '05_05_14_02',
            hn: 'A / 場合の数と確率 / 重複組み合わせ',
            vid: 'G0-_uAtT9twm',
            kuids: [ '05_02_02' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '4',
            qn: '重複組み合わせ',
            qnum1: '14',
            qnum2: '3',
            quid: '05_05_14_03',
            hn: 'A / 場合の数と確率 / 重複組み合わせ',
            vid: 'soVQ2MnG0g0S',
            kuids: [ '05_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '組み合わせ　３頻出',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '道路問題',
            qnum1: '15',
            qnum2: '0',
            quid: '05_06_15_00',
            hn: 'A / 場合の数と確率 / 組み合わせ　３頻出',
            vid: 'BcksEUu8WbGF',
            kuids: [ '05_02_03' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '対角線問題',
            qnum1: '16',
            qnum2: '0',
            quid: '05_06_16_00',
            hn: 'A / 場合の数と確率 / 組み合わせ　３頻出',
            vid: '7tF6Q65a9lCZ',
            kuids: [ '05_02_04' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '組分け問題',
            qnum1: '17',
            qnum2: '0',
            quid: '05_06_17_00',
            hn: 'A / 場合の数と確率 / 組み合わせ　３頻出',
            vid: '1q-NQ_Qxjml0',
            kuids: [ '05_02_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '場合の数の応用題',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '平面図形の塗り分け',
            qnum1: '18',
            qnum2: '0',
            quid: '05_07_18_00',
            hn: 'A / 場合の数と確率 / 場合の数の応用題',
            vid: '6rOZ7OGkbWDp',
            kuids: [ '05_01_01' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '同じ数字の重複を許して整数をつくる',
            qnum1: '19',
            qnum2: '0',
            quid: '05_07_19_00',
            hn: 'A / 場合の数と確率 / 場合の数の応用題',
            vid: 'SB_GnZmOGdYC',
            kuids: [ '05_01_03' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '積が〜の倍数になる組み合わせ',
            qnum1: '20',
            qnum2: '0',
            quid: '05_07_20_00',
            hn: 'A / 場合の数と確率 / 場合の数の応用題',
            vid: '2OMsIfWm1CAe',
            kuids: [ '05_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '確率の基本',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '順列と確率',
            qnum1: '21',
            qnum2: '0',
            quid: '05_08_21_00',
            hn: 'A / 場合の数と確率 / 確率の基本',
            vid: 'Y5itF6bhbMT2',
            kuids: [ '05_03_01' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '組み合わせと確率',
            qnum1: '22',
            qnum2: '0',
            quid: '05_08_22_00',
            hn: 'A / 場合の数と確率 / 確率の基本',
            vid: 'oA4di0pklwr1',
            kuids: [ '05_03_01' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '２次方程式との融合',
            qnum1: '23',
            qnum2: '0',
            quid: '05_08_23_00',
            hn: 'A / 場合の数と確率 / 確率の基本',
            vid: 'YFvXodARK2b_',
            kuids: [ '05_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '集合との融合',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '事象と集合',
            qnum1: '24',
            qnum2: '0',
            quid: '05_09_24_00',
            hn: 'A / 場合の数と確率 / 集合との融合',
            vid: 'YQPxZ2WYAU1u',
            kuids: [ '05_03_02' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '和事象の確率',
            qnum1: '25',
            qnum2: '0',
            quid: '05_09_25_00',
            hn: 'A / 場合の数と確率 / 集合との融合',
            vid: 'YjDetzu_CGz1',
            kuids: [ '05_03_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '独立試行・反復試行',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '独立試行',
            qnum1: '26',
            qnum2: '0',
            quid: '05_10_26_00',
            hn: 'A / 場合の数と確率 / 独立試行・反復試行',
            vid: 'HF4XxbiXxTo9',
            kuids: [ '05_03_03' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '反復試行の基本',
            qnum1: '27',
            qnum2: '0',
            quid: '05_10_27_00',
            hn: 'A / 場合の数と確率 / 独立試行・反復試行',
            vid: 'rkBAsTatD2Ia',
            kuids: [ '05_03_04' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: 'ゲームで勝者となる確率',
            qnum1: '28',
            qnum2: '0',
            quid: '05_10_28_00',
            hn: 'A / 場合の数と確率 / 独立試行・反復試行',
            vid: 'aA_CCUGHREBE',
            kuids: [ '05_03_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '11',
        pn: '条件つき確率と乗法定理',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '条件つき確率　その１',
            qnum1: '29',
            qnum2: '0',
            quid: '05_11_29_00',
            hn: 'A / 場合の数と確率 / 条件つき確率と乗法定理',
            vid: 'Jz0QVQ64oEuc',
            kuids: [ '05_03_05' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '条件つき確率　その２',
            qnum1: '30',
            qnum2: '0',
            quid: '05_11_30_00',
            hn: 'A / 場合の数と確率 / 条件つき確率と乗法定理',
            vid: 'qfqSyNLhsGp4',
            kuids: [ '05_03_05' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '乗法定理',
            qnum1: '31',
            qnum2: '0',
            quid: '05_11_31_00',
            hn: 'A / 場合の数と確率 / 条件つき確率と乗法定理',
            vid: 'ewhP3qmbKKKB',
            kuids: [ '05_03_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '12',
        pn: '期待値・数列との融合',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: '期待値',
            qnum1: '32',
            qnum2: '0',
            quid: '05_12_32_00',
            hn: 'A / 場合の数と確率 / 期待値・数列との融合',
            vid: 'bCj6IPNs9VXp',
            kuids: [ '05_03_06' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: '最大確率',
            qnum1: '33',
            qnum2: '0',
            quid: '05_12_33_00',
            hn: 'A / 場合の数と確率 / 期待値・数列との融合',
            vid: 'yRVHfzjdondQ',
            kuids: [ '05_03_21' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '確率と漸化式',
            qnum1: '34',
            qnum2: '0',
            quid: '05_12_34_00',
            hn: 'A / 場合の数と確率 / 期待値・数列との融合',
            vid: 'Bhrbgh4e_zcP',
            kuids: [ '05_03_22', '14_07_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '13',
        pn: 'さいころ・じゃんけん',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: 'さいころの基本',
            qnum1: '35',
            qnum2: '0',
            quid: '05_13_35_00',
            hn: 'A / 場合の数と確率 / さいころ・じゃんけん',
            vid: 'shTVQJR7f1ea',
            kuids: [ '05_03_11' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: 'さいころ　目の積が〜の倍数',
            qnum1: '36',
            qnum2: '0',
            quid: '05_13_36_00',
            hn: 'A / 場合の数と確率 / さいころ・じゃんけん',
            vid: 'u5ow7K8hs1Ur',
            kuids: [ '05_03_11' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: 'さいころ　出る目の最大・最小',
            qnum1: '37',
            qnum2: '0',
            quid: '05_13_37_00',
            hn: 'A / 場合の数と確率 / さいころ・じゃんけん',
            vid: '6V0Zgwl3h1kG',
            kuids: [ '05_03_11' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '4',
            qn: '１回のじゃんけん',
            qnum1: '38',
            qnum2: '0',
            quid: '05_13_38_00',
            hn: 'A / 場合の数と確率 / さいころ・じゃんけん',
            vid: 'VGpAuHhvpCTw',
            kuids: [ '05_03_12' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '5',
            qn: '勝ち残りじゃんけん',
            qnum1: '39',
            qnum2: '0',
            quid: '05_13_39_00',
            hn: 'A / 場合の数と確率 / さいころ・じゃんけん',
            vid: 'Xtmef6lbGR6R',
            kuids: [ '05_03_12' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '14',
        pn: 'くじ・点の移動',
        hn: 'A / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a',
            qid: '1',
            qn: 'くじびきの基本',
            qnum1: '40',
            qnum2: '0',
            quid: '05_14_40_00',
            hn: 'A / 場合の数と確率 / くじ・点の移動',
            vid: 'ulSYMnWhNobW',
            kuids: [ '05_03_13' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '2',
            qn: 'くじびき　〜人目が〜本目の当たりをひく',
            qnum1: '41',
            qnum2: '0',
            quid: '05_14_41_00',
            hn: 'A / 場合の数と確率 / くじ・点の移動',
            vid: 'L1Ss3ZcLaPul',
            kuids: [ '05_03_13' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '3',
            qn: '点の移動　数直線',
            qnum1: '42',
            qnum2: '0',
            quid: '05_14_42_00',
            hn: 'A / 場合の数と確率 / くじ・点の移動',
            vid: 'O4tLdE8YbDKL',
            kuids: [ '05_03_14' ],
            record: {}
          },
          {
            uid: '5',
            fid: '1a',
            qid: '4',
            qn: '点の移動　座標平面',
            qnum1: '43',
            qnum2: '0',
            quid: '05_14_43_00',
            hn: 'A / 場合の数と確率 / くじ・点の移動',
            vid: 'VHZ2K1WWS0HX',
            kuids: [ '05_03_14' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '6',
    un: '図形の性質',
    fid: '1a',
    hn: 'A',
    prints: [
      {
        pid: '1',
        pn: '三角形の成立＆角の二等分線',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '三角形の角と辺の大小',
            qnum1: '1',
            qnum2: '0',
            quid: '06_01_01_00',
            hn: 'A / 図形の性質 / 三角形の成立＆角の二等分線',
            vid: 'V1re31JYqO5K',
            kuids: [ '06_01_01' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '三角形の成立条件',
            qnum1: '2',
            qnum2: '0',
            quid: '06_01_02_00',
            hn: 'A / 図形の性質 / 三角形の成立＆角の二等分線',
            vid: 'IY8YKoBOdFmA',
            kuids: [ '06_01_01' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '3',
            qn: '平行線と線分の比',
            qnum1: '3',
            qnum2: '0',
            quid: '06_01_03_00',
            hn: 'A / 図形の性質 / 三角形の成立＆角の二等分線',
            vid: '-pe1vcqh3IJe',
            kuids: [ '06_01_02' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '4',
            qn: '角の二等分線と線分の比',
            qnum1: '4',
            qnum2: '0',
            quid: '06_01_04_00',
            hn: 'A / 図形の性質 / 三角形の成立＆角の二等分線',
            vid: 'mN_yu-XVaFYR',
            kuids: [ '06_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '中線定理＆メネ・チェバの定理',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '中線定理',
            qnum1: '5',
            qnum2: '0',
            quid: '06_02_05_00',
            hn: 'A / 図形の性質 / 中線定理＆メネ・チェバの定理',
            vid: '7C1kcSA2k_gl',
            kuids: [ '06_01_05' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: 'メネラウスの定理',
            qnum1: '6',
            qnum2: '0',
            quid: '06_02_06_00',
            hn: 'A / 図形の性質 / 中線定理＆メネ・チェバの定理',
            vid: '320NUvh8jNZ3',
            kuids: [ '06_01_06' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '3',
            qn: 'チェバの定理',
            qnum1: '7',
            qnum2: '0',
            quid: '06_02_07_00',
            hn: 'A / 図形の性質 / 中線定理＆メネ・チェバの定理',
            vid: 'IJjVvKAj51mx',
            kuids: [ '06_01_07' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '4',
            qn: '辺の比と面積比',
            qnum1: '8',
            qnum2: '0',
            quid: '06_02_08_00',
            hn: 'A / 図形の性質 / 中線定理＆メネ・チェバの定理',
            vid: 'ovdvgpnBd75h',
            kuids: [ '06_01_04', '06_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '五心　その１',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '重心',
            qnum1: '9',
            qnum2: '0',
            quid: '06_03_09_00',
            hn: 'A / 図形の性質 / 五心　その１',
            vid: 'ErFNGXEyDHsN',
            kuids: [ '06_01_10', '06_01_05' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '内心と線分の比',
            qnum1: '10',
            qnum2: '0',
            quid: '06_03_10_00',
            hn: 'A / 図形の性質 / 五心　その１',
            vid: 'ymH_d3bMekYV',
            kuids: [ '06_01_13', '06_01_03' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '3',
            qn: '五心と角の求値',
            qnum1: '11',
            qnum2: '0',
            quid: '06_03_11_00',
            hn: 'A / 図形の性質 / 五心　その１',
            vid: 'anI93vlJt8NR',
            kuids: [ '06_01_11', '06_01_13' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '五心　その２',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '円に外接する多角形の辺の長さ',
            qnum1: '12',
            qnum2: '0',
            quid: '06_04_12_00',
            hn: 'A / 図形の性質 / 五心　その２',
            vid: 'hZjlmSlBUDuy',
            kuids: [ '06_01_13' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '正三角形であることの証明',
            qnum1: '13',
            qnum2: '0',
            quid: '06_04_13_00',
            hn: 'A / 図形の性質 / 五心　その２',
            vid: 'ZtlYGvt6ItAP',
            kuids: [ '06_01_10', '06_01_12' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '円に関する角度の定理',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '円周角の定理',
            qnum1: '14',
            qnum2: '0',
            quid: '06_05_14_00',
            hn: 'A / 図形の性質 / 円に関する角度の定理',
            vid: 'MxIgFG0MKQTg',
            kuids: [ '06_02_01' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '円に内接する四角形',
            qnum1: '15',
            qnum2: '0',
            quid: '06_05_15_00',
            hn: 'A / 図形の性質 / 円に関する角度の定理',
            vid: 'cneB33hAZcb2',
            kuids: [ '06_02_02' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '3',
            qn: '接弦定理',
            qnum1: '16',
            qnum2: '0',
            quid: '06_05_16_00',
            hn: 'A / 図形の性質 / 円に関する角度の定理',
            vid: 'wrTrA_sZPcG1',
            kuids: [ '06_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '方べきの定理',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '方べきの定理　その１',
            qnum1: '17',
            qnum2: '1',
            quid: '06_06_17_01',
            hn: 'A / 図形の性質 / 方べきの定理',
            vid: '6MmB4C9sPojq',
            kuids: [ '06_02_04' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '方べきの定理　その１',
            qnum1: '17',
            qnum2: '2',
            quid: '06_06_17_02',
            hn: 'A / 図形の性質 / 方べきの定理',
            vid: '1mnRuUU2AbNs',
            kuids: [ '06_02_04' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '3',
            qn: '方べきの定理　その１',
            qnum1: '17',
            qnum2: '3',
            quid: '06_06_17_03',
            hn: 'A / 図形の性質 / 方べきの定理',
            vid: 'lK5JEESbCCte',
            kuids: [ '06_02_04' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '4',
            qn: '方べきの定理　その２',
            qnum1: '18',
            qnum2: '0',
            quid: '06_06_18_00',
            hn: 'A / 図形の性質 / 方べきの定理',
            vid: 'G-l0av2fBdZO',
            kuids: [ '06_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '円に内接する条件',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '円に内接する条件と求値',
            qnum1: '19',
            qnum2: '0',
            quid: '06_07_19_00',
            hn: 'A / 図形の性質 / 円に内接する条件',
            vid: 'JSslZm1Ax-mR',
            kuids: [ '06_02_01' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '４点が同一円周上にあることの証明',
            qnum1: '20',
            qnum2: '0',
            quid: '06_07_20_00',
            hn: 'A / 図形の性質 / 円に内接する条件',
            vid: 'znhcoKgufRAo',
            kuids: [ '06_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '２円の関係',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '２円の関係　基本',
            qnum1: '21',
            qnum2: '0',
            quid: '06_08_21_00',
            hn: 'A / 図形の性質 / ２円の関係',
            vid: 'Mt4vuVHeo3dK',
            kuids: [ '06_02_05' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '２円の関係　応用',
            qnum1: '22',
            qnum2: '0',
            quid: '06_08_22_00',
            hn: 'A / 図形の性質 / ２円の関係',
            vid: 'xiuMaMbIgepq',
            kuids: [ '06_02_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '空間図形',
        hn: 'A / 図形の性質',
        questions: [
          {
            uid: '6',
            fid: '1a',
            qid: '1',
            qn: '２平面のなす角',
            qnum1: '23',
            qnum2: '0',
            quid: '06_09_23_00',
            hn: 'A / 図形の性質 / 空間図形',
            vid: 'ygwhcky7HKRY',
            kuids: [ '06_03_01' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '2',
            qn: '正多面体　証明',
            qnum1: '24',
            qnum2: '0',
            quid: '06_09_24_00',
            hn: 'A / 図形の性質 / 空間図形',
            vid: 'y_uDuIZj6JCb',
            kuids: [ '06_04_01' ],
            record: {}
          },
          {
            uid: '6',
            fid: '1a',
            qid: '3',
            qn: '正多面体　求値',
            qnum1: '25',
            qnum2: '0',
            quid: '06_09_25_00',
            hn: 'A / 図形の性質 / 空間図形',
            vid: 'WVqTG6Sjit3g',
            kuids: [ '06_04_01', '06_04_02' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '7',
    un: '数学と人間の活動',
    fid: '1a',
    hn: 'A',
    prints: [
      {
        pid: '1',
        pn: '基本事項　その１',
        hn: 'A / 数学と人間の活動',
        questions: [
          {
            uid: '7',
            fid: '1a',
            qid: '1',
            qn: '約数の個数',
            qnum1: '1',
            qnum2: '1',
            quid: '07_01_01_01',
            hn: 'A / 数学と人間の活動 / 基本事項　その１',
            vid: 'aUVCJpQ7GXgr',
            kuids: [ '07_01_01' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '2',
            qn: '最大公約数と最小公倍数',
            qnum1: '1',
            qnum2: '2',
            quid: '07_01_01_02',
            hn: 'A / 数学と人間の活動 / 基本事項　その１',
            vid: 'RliqjP4wVUOr',
            kuids: [ '07_01_02' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '3',
            qn: '素因数の個数',
            qnum1: '2',
            qnum2: '1',
            quid: '07_01_02_01',
            hn: 'A / 数学と人間の活動 / 基本事項　その１',
            vid: 'bgbW9DL7ssn6',
            kuids: [ '07_01_01' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '4',
            qn: '末尾に並ぶ０の個数',
            qnum1: '2',
            qnum2: '2',
            quid: '07_01_02_02',
            hn: 'A / 数学と人間の活動 / 基本事項　その１',
            vid: 'Ingf3clb5vbR',
            kuids: [ '07_01_01' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '5',
            qn: 'ユークリッドの互除法',
            qnum1: '3',
            qnum2: '0',
            quid: '07_01_03_00',
            hn: 'A / 数学と人間の活動 / 基本事項　その１',
            vid: 'eFfNnyJwDQ4i',
            kuids: [ '07_01_03', '07_01_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '基本事項　その２',
        hn: 'A / 数学と人間の活動',
        questions: [
          {
            uid: '7',
            fid: '1a',
            qid: '1',
            qn: 'ｎ進法の基本',
            qnum1: '4',
            qnum2: '0',
            quid: '07_02_04_00',
            hn: 'A / 数学と人間の活動 / 基本事項　その２',
            vid: '7ImRtIYQpsbc',
            kuids: [ '07_01_05' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '2',
            qn: 'ｎ進法（係数決定）',
            qnum1: '5',
            qnum2: '0',
            quid: '07_02_05_00',
            hn: 'A / 数学と人間の活動 / 基本事項　その２',
            vid: 'OKNIWa56A9xV',
            kuids: [ '07_01_05' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '3',
            qn: 'ｎ進法→ｍ進法',
            qnum1: '6',
            qnum2: '0',
            quid: '07_02_06_00',
            hn: 'A / 数学と人間の活動 / 基本事項　その２',
            vid: 'zvn2egUTeXY_',
            kuids: [ '07_01_05' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '4',
            qn: '合同式の利用',
            qnum1: '7',
            qnum2: '1',
            quid: '07_02_07_01',
            hn: 'A / 数学と人間の活動 / 基本事項　その２',
            vid: 'ANwf2_WRqHwA',
            kuids: [ '07_01_06' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '5',
            qn: '合同式の利用',
            qnum1: '7',
            qnum2: '2',
            quid: '07_02_07_02',
            hn: 'A / 数学と人間の活動 / 基本事項　その２',
            vid: 'gPXuXT0kqw-g',
            kuids: [ '07_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '整数解方程式　その１',
        hn: 'A / 数学と人間の活動',
        questions: [
          {
            uid: '7',
            fid: '1a',
            qid: '1',
            qn: '基本',
            qnum1: '8',
            qnum2: '0',
            quid: '07_03_08_00',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その１',
            vid: 'jpxRtyNT9_jk',
            kuids: [ '07_02_01' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '2',
            qn: '因数分解の利用',
            qnum1: '9',
            qnum2: '0',
            quid: '07_03_09_00',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その１',
            vid: 'Yyrqx35IYjgS',
            kuids: [ '07_02_01' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '3',
            qn: '２元１次不定方程式　基本',
            qnum1: '10',
            qnum2: '1',
            quid: '07_03_10_01',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その１',
            vid: 'qwlJd17deRaG',
            kuids: [ '07_02_02' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '4',
            qn: '２元１次不定方程式　基本',
            qnum1: '10',
            qnum2: '2',
            quid: '07_03_10_02',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その１',
            vid: 's9kmAX4h-APw',
            kuids: [ '07_02_02' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '5',
            qn: '２元１次不定方程式　基本',
            qnum1: '10',
            qnum2: '3',
            quid: '07_03_10_03',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その１',
            vid: 'o2aY7IabXHGh',
            kuids: [ '07_02_02' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '6',
            qn: '２元１次不定方程式　応用',
            qnum1: '11',
            qnum2: '0',
            quid: '07_03_11_00',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その１',
            vid: 'lNFPXf-P5hoA',
            kuids: [ '07_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '整数解方程式　その２',
        hn: 'A / 数学と人間の活動',
        questions: [
          {
            uid: '7',
            fid: '1a',
            qid: '1',
            qn: '２元２次不定方程式　典型題',
            qnum1: '12',
            qnum2: '0',
            quid: '07_04_12_00',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その２',
            vid: '60bou90vGtvh',
            kuids: [ '07_02_03' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '2',
            qn: '絞り込み　その１',
            qnum1: '13',
            qnum2: '0',
            quid: '07_04_13_00',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その２',
            vid: 'uqyz3uHxjgjv',
            kuids: [ '07_02_04' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '3',
            qn: '絞り込み　その２',
            qnum1: '14',
            qnum2: '0',
            quid: '07_04_14_00',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その２',
            vid: '9PCZ_1NpKyEe',
            kuids: [ '07_02_05' ],
            record: {}
          },
          {
            uid: '7',
            fid: '1a',
            qid: '4',
            qn: '絞り込み　応用',
            qnum1: '15',
            qnum2: '0',
            quid: '07_04_15_00',
            hn: 'A / 数学と人間の活動 / 整数解方程式　その２',
            vid: 'Zjpi4XlFJiaz',
            kuids: [ '07_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '倍数問題（証明）　その１',
        hn: 'A / 数学と人間の活動',
        questions: [
          {
            uid: '7',
            fid: '1a',
            qid: '1',
            qn: '倍数証明の基本',
            qnum1: '16',
            qnum2: '0',
            quid: '07_05_16_00',
            hn: 'A / 数学と人間の活動 / 倍数問題（証明）　その１',
            vid: 'AWNy205ofJuq',
            kuids: [ '07_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '倍数問題（証明）　その２',
        hn: 'A / 数学と人間の活動',
        questions: [
          {
            uid: '7',
            fid: '1a',
            qid: '1',
            qn: '余りが〜であることの証明',
            qnum1: '17',
            qnum2: '0',
            quid: '07_06_17_00',
            hn: 'A / 数学と人間の活動 / 倍数問題（証明）　その２',
            vid: 'HYL-PtgRnVIy',
            kuids: [ '07_03_01', '07_01_06' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '8',
    un: '式と証明',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '式の扱い',
        hn: 'Ⅱ / 式と証明',
        questions: [
          {
            uid: '8',
            fid: '2bc',
            qid: '1',
            qn: '分数式',
            qnum1: '1',
            qnum2: '1',
            quid: '08_01_01_01',
            hn: 'Ⅱ / 式と証明 / 式の扱い',
            vid: 'ZESVkWEh1zdv',
            kuids: [ '08_00_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '2',
            qn: '分数式',
            qnum1: '1',
            qnum2: '2',
            quid: '08_01_01_02',
            hn: 'Ⅱ / 式と証明 / 式の扱い',
            vid: '7cssN8ZcIOx_',
            kuids: [ '08_00_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '3',
            qn: '比例式　その１',
            qnum1: '2',
            qnum2: '0',
            quid: '08_01_02_00',
            hn: 'Ⅱ / 式と証明 / 式の扱い',
            vid: '5FbrS1U2i3hH',
            kuids: [ '08_00_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '4',
            qn: '比例式　その２',
            qnum1: '3',
            qnum2: '0',
            quid: '08_01_03_00',
            hn: 'Ⅱ / 式と証明 / 式の扱い',
            vid: '_LkKMrUrCJuE',
            kuids: [ '08_00_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '二項定理',
        hn: 'Ⅱ / 式と証明',
        questions: [
          {
            uid: '8',
            fid: '2bc',
            qid: '1',
            qn: '二項定理・多項定理',
            qnum1: '4',
            qnum2: '1',
            quid: '08_02_04_01',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: 'PCWsINnSvgdH',
            kuids: [ '08_01_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '2',
            qn: '二項定理・多項定理',
            qnum1: '4',
            qnum2: '2',
            quid: '08_02_04_02',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: '_LSPVxXyxeQB',
            kuids: [ '08_01_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '3',
            qn: '二項定理・多項定理',
            qnum1: '4',
            qnum2: '3',
            quid: '08_02_04_03',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: 'b9f8OAaM7WNn',
            kuids: [ '08_01_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '4',
            qn: '二項定理・多項定理',
            qnum1: '4',
            qnum2: '4',
            quid: '08_02_04_04',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: 'yqAXEWaTIGmY',
            kuids: [ '08_01_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '5',
            qn: '二項定理の応用',
            qnum1: '5',
            qnum2: '1',
            quid: '08_02_05_01',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: 'HRffVAUHr3Ug',
            kuids: [ '08_01_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '6',
            qn: '二項定理の応用',
            qnum1: '5',
            qnum2: '2',
            quid: '08_02_05_02',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: 'lrJR6XJWVUPT',
            kuids: [ '08_01_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '7',
            qn: '多項定理の応用',
            qnum1: '6',
            qnum2: '0',
            quid: '08_02_06_00',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: '5v53FyA23PTm',
            kuids: [ '08_01_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '8',
            qn: '下位〜桁',
            qnum1: '7',
            qnum2: '0',
            quid: '08_02_07_00',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: 'F2kqS6z5YB7_',
            kuids: [ '08_01_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '恒等式',
        hn: 'Ⅱ / 式と証明',
        questions: [
          {
            uid: '8',
            fid: '2bc',
            qid: '1',
            qn: '恒等式　未定係数法',
            qnum1: '8',
            qnum2: '1',
            quid: '08_03_08_01',
            hn: 'Ⅱ / 式と証明 / 恒等式',
            vid: 'JKhahI8y4SJo',
            kuids: [ '08_02_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '2',
            qn: '恒等式　未定係数法',
            qnum1: '8',
            qnum2: '2',
            quid: '08_03_08_02',
            hn: 'Ⅱ / 式と証明 / 恒等式',
            vid: 'ZWcO8cj6cXJr',
            kuids: [ '08_02_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '3',
            qn: '恒等式　x、y以外の文字',
            qnum1: '9',
            qnum2: '0',
            quid: '08_03_09_00',
            hn: 'Ⅱ / 式と証明 / 恒等式',
            vid: 'ORJPBzARD9KZ',
            kuids: [ '08_02_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '4',
            qn: '恒等式　文字消去',
            qnum1: '10',
            qnum2: '0',
            quid: '08_03_10_00',
            hn: 'Ⅱ / 式と証明 / 恒等式',
            vid: '94tR_h-B95NB',
            kuids: [ '08_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '等式の証明',
        hn: 'Ⅱ / 式と証明',
        questions: [
          {
            uid: '8',
            fid: '2bc',
            qid: '1',
            qn: '等式の証明',
            qnum1: '11',
            qnum2: '1',
            quid: '08_04_11_01',
            hn: 'Ⅱ / 式と証明 / 等式の証明',
            vid: 'c86LxXzKVqNN',
            kuids: [ '08_03_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '2',
            qn: '等式の証明',
            qnum1: '11',
            qnum2: '2',
            quid: '08_04_11_02',
            hn: 'Ⅱ / 式と証明 / 等式の証明',
            vid: '5mjQHXgb2DIB',
            kuids: [ '08_03_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '3',
            qn: '等式の証明',
            qnum1: '11',
            qnum2: '3',
            quid: '08_04_11_03',
            hn: 'Ⅱ / 式と証明 / 等式の証明',
            vid: '4myUxtwnT5Bp',
            kuids: [ '08_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '不等式の証明',
        hn: 'Ⅱ / 式と証明',
        questions: [
          {
            uid: '8',
            fid: '2bc',
            qid: '1',
            qn: '不等式の証明',
            qnum1: '12',
            qnum2: '1',
            quid: '08_05_12_01',
            hn: 'Ⅱ / 式と証明 / 不等式の証明',
            vid: 'NEvxOmrbwPoo',
            kuids: [ '08_04_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '2',
            qn: '不等式の証明',
            qnum1: '12',
            qnum2: '2',
            quid: '08_05_12_02',
            hn: 'Ⅱ / 式と証明 / 不等式の証明',
            vid: 'H57kJZ_9wtzu',
            kuids: [ '08_04_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '3',
            qn: '不等式の証明',
            qnum1: '12',
            qnum2: '3',
            quid: '08_05_12_03',
            hn: 'Ⅱ / 式と証明 / 不等式の証明',
            vid: 'JB9_ohPM9__q',
            kuids: [ '08_04_02' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '4',
            qn: '大小比較',
            qnum1: '13',
            qnum2: '0',
            quid: '08_05_13_00',
            hn: 'Ⅱ / 式と証明 / 不等式の証明',
            vid: 'CBYMUKscahWH',
            kuids: [ '08_04_01', '08_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '有名不等式の利用',
        hn: 'Ⅱ / 式と証明',
        questions: [
          {
            uid: '8',
            fid: '2bc',
            qid: '1',
            qn: '相加・相乗平均の関係',
            qnum1: '14',
            qnum2: '1',
            quid: '08_06_14_01',
            hn: 'Ⅱ / 式と証明 / 有名不等式の利用',
            vid: 'NSYmj-Vs-UQb',
            kuids: [ '08_05_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '2',
            qn: '相加・相乗平均の関係',
            qnum1: '14',
            qnum2: '2',
            quid: '08_06_14_02',
            hn: 'Ⅱ / 式と証明 / 有名不等式の利用',
            vid: '-5aMaaWGUuKL',
            kuids: [ '08_05_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '3',
            qn: '相加・相乗平均の関係',
            qnum1: '14',
            qnum2: '3',
            quid: '08_06_14_03',
            hn: 'Ⅱ / 式と証明 / 有名不等式の利用',
            vid: 'AWgI0DqJe5ZQ',
            kuids: [ '08_05_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '4',
            qn: '相加・相乗平均の関係',
            qnum1: '14',
            qnum2: '4',
            quid: '08_06_14_04',
            hn: 'Ⅱ / 式と証明 / 有名不等式の利用',
            vid: 'CS9lFQ-s0vkF',
            kuids: [ '08_05_01' ],
            record: {}
          },
          {
            uid: '8',
            fid: '2bc',
            qid: '5',
            qn: 'コーシー・シュワルツの不等式',
            qnum1: '15',
            qnum2: '0',
            quid: '08_06_15_00',
            hn: 'Ⅱ / 式と証明 / 有名不等式の利用',
            vid: 'AtQTmuL84WNb',
            kuids: [ '08_05_02' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '9',
    un: '複素数と方程式',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '複素数',
        hn: 'Ⅱ / 複素数と方程式',
        questions: [
          {
            uid: '9',
            fid: '2bc',
            qid: '1',
            qn: '虚数の計算',
            qnum1: '1',
            qnum2: '0',
            quid: '09_01_01_00',
            hn: 'Ⅱ / 複素数と方程式 / 複素数',
            vid: 'DU3jBaoCm9ln',
            kuids: [ '09_01_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '2',
            qn: '実部・虚部',
            qnum1: '2',
            qnum2: '0',
            quid: '09_01_02_00',
            hn: 'Ⅱ / 複素数と方程式 / 複素数',
            vid: 'BVQzBEecy89Z',
            kuids: [ '09_01_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '3',
            qn: '累乗',
            qnum1: '3',
            qnum2: '0',
            quid: '09_01_03_00',
            hn: 'Ⅱ / 複素数と方程式 / 複素数',
            vid: '3w7_Rh6xtd2F',
            kuids: [ '09_01_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '4',
            qn: '恒等式',
            qnum1: '4',
            qnum2: '0',
            quid: '09_01_04_00',
            hn: 'Ⅱ / 複素数と方程式 / 複素数',
            vid: 'EUdtBZw--UMR',
            kuids: [ '09_01_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '5',
            qn: '方程式',
            qnum1: '5',
            qnum2: '0',
            quid: '09_01_05_00',
            hn: 'Ⅱ / 複素数と方程式 / 複素数',
            vid: 'ZvAtqr8CPYk6',
            kuids: [ '09_01_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '2次方程式の「解の判別」',
        hn: 'Ⅱ / 複素数と方程式',
        questions: [
          {
            uid: '9',
            fid: '2bc',
            qid: '1',
            qn: '２次方程式の虚数解',
            qnum1: '6',
            qnum2: '0',
            quid: '09_02_06_00',
            hn: 'Ⅱ / 複素数と方程式 / 2次方程式の「解の判別」',
            vid: 'Wh9iKe0kxALa',
            kuids: [ '09_02_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '2',
            qn: '虚数係数の２次方程式',
            qnum1: '7',
            qnum2: '0',
            quid: '09_02_07_00',
            hn: 'Ⅱ / 複素数と方程式 / 2次方程式の「解の判別」',
            vid: 'wfXq4TUe-L8d',
            kuids: [ '09_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '2次方程式の「解と係数の関係」',
        hn: 'Ⅱ / 複素数と方程式',
        questions: [
          {
            uid: '9',
            fid: '2bc',
            qid: '1',
            qn: '解と係数の関係(2次)',
            qnum1: '8',
            qnum2: '0',
            quid: '09_03_08_00',
            hn: 'Ⅱ / 複素数と方程式 / 2次方程式の「解と係数の関係」',
            vid: '8PnCNiRCnaja',
            kuids: [ '09_02_03' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '2',
            qn: '２つの解の差や比',
            qnum1: '9',
            qnum2: '0',
            quid: '09_03_09_00',
            hn: 'Ⅱ / 複素数と方程式 / 2次方程式の「解と係数の関係」',
            vid: 'Ssx0HOsnPokH',
            kuids: [ '09_02_03' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '3',
            qn: '２次方程式をつくる',
            qnum1: '10',
            qnum2: '0',
            quid: '09_03_10_00',
            hn: 'Ⅱ / 複素数と方程式 / 2次方程式の「解と係数の関係」',
            vid: 'V69lypgXITj_',
            kuids: [ '09_02_04' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '4',
            qn: '解から係数決定（２次）',
            qnum1: '11',
            qnum2: '0',
            quid: '09_03_11_00',
            hn: 'Ⅱ / 複素数と方程式 / 2次方程式の「解と係数の関係」',
            vid: 'ekDqioDbgN3p',
            kuids: [ '09_02_03', '09_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '剰余の定理',
        hn: 'Ⅱ / 複素数と方程式',
        questions: [
          {
            uid: '9',
            fid: '2bc',
            qid: '1',
            qn: '除法の原理',
            qnum1: '12',
            qnum2: '0',
            quid: '09_04_12_00',
            hn: 'Ⅱ / 複素数と方程式 / 剰余の定理',
            vid: 'UQGq1PTLKqjv',
            kuids: [ '09_03_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '2',
            qn: '計算の工夫',
            qnum1: '13',
            qnum2: '0',
            quid: '09_04_13_00',
            hn: 'Ⅱ / 複素数と方程式 / 剰余の定理',
            vid: 'owXUMu0hGjSm',
            kuids: [ '09_03_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '3',
            qn: '剰余の定理',
            qnum1: '14',
            qnum2: '0',
            quid: '09_04_14_00',
            hn: 'Ⅱ / 複素数と方程式 / 剰余の定理',
            vid: 'BMBS6a9cMDZM',
            kuids: [ '09_03_01' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '4',
            qn: '余りの決定（基本）',
            qnum1: '15',
            qnum2: '0',
            quid: '09_04_15_00',
            hn: 'Ⅱ / 複素数と方程式 / 剰余の定理',
            vid: 'Yuue5XcRB5cx',
            kuids: [ '09_03_01', '09_03_04' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '5',
            qn: '余りの決定（応用）',
            qnum1: '16',
            qnum2: '0',
            quid: '09_04_16_00',
            hn: 'Ⅱ / 複素数と方程式 / 剰余の定理',
            vid: 'pjCYyV_N2cBR',
            kuids: [ '09_03_01', '09_03_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '因数定理・解と係数の関係',
        hn: 'Ⅱ / 複素数と方程式',
        questions: [
          {
            uid: '9',
            fid: '2bc',
            qid: '1',
            qn: '３次方程式を解く',
            qnum1: '17',
            qnum2: '1',
            quid: '09_05_17_01',
            hn: 'Ⅱ / 複素数と方程式 / 因数定理・解と係数の関係',
            vid: 'v7EBT-GXUTyI',
            kuids: [ '09_03_02' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '2',
            qn: '３次方程式を解く',
            qnum1: '17',
            qnum2: '2',
            quid: '09_05_17_02',
            hn: 'Ⅱ / 複素数と方程式 / 因数定理・解と係数の関係',
            vid: '8HOhmqiLsCT4',
            kuids: [ '09_03_02' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '3',
            qn: '３次方程式を解く',
            qnum1: '17',
            qnum2: '3',
            quid: '09_05_17_03',
            hn: 'Ⅱ / 複素数と方程式 / 因数定理・解と係数の関係',
            vid: 'hQqpQeKpsUbA',
            kuids: [ '09_03_02' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '4',
            qn: '３次方程式を解く',
            qnum1: '17',
            qnum2: '4',
            quid: '09_05_17_04',
            hn: 'Ⅱ / 複素数と方程式 / 因数定理・解と係数の関係',
            vid: 'koZ4jPquPprN',
            kuids: [ '09_03_02' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '5',
            qn: '３次方程式を解く',
            qnum1: '17',
            qnum2: '5',
            quid: '09_05_17_05',
            hn: 'Ⅱ / 複素数と方程式 / 因数定理・解と係数の関係',
            vid: 'Ln85MM_UjdUS',
            kuids: [ '09_03_02' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '6',
            qn: '解と係数の関係(3次)',
            qnum1: '18',
            qnum2: '0',
            quid: '09_05_18_00',
            hn: 'Ⅱ / 複素数と方程式 / 因数定理・解と係数の関係',
            vid: 'D5SWDFw4lfnN',
            kuids: [ '09_03_03' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '7',
            qn: '３次方程式をつくる',
            qnum1: '19',
            qnum2: '0',
            quid: '09_05_19_00',
            hn: 'Ⅱ / 複素数と方程式 / 因数定理・解と係数の関係',
            vid: 'KgQmv7ja3E5W',
            kuids: [ '09_03_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '頻出問題　仕上げ',
        hn: 'Ⅱ / 複素数と方程式',
        questions: [
          {
            uid: '9',
            fid: '2bc',
            qid: '1',
            qn: '解から係数決定（３次）',
            qnum1: '20',
            qnum2: '0',
            quid: '09_06_20_00',
            hn: 'Ⅱ / 複素数と方程式 / 頻出問題　仕上げ',
            vid: 'opjC-l4Iypmw',
            kuids: [ '09_03_05' ],
            record: {}
          },
          {
            uid: '9',
            fid: '2bc',
            qid: '2',
            qn: 'ωの問題',
            qnum1: '21',
            qnum2: '0',
            quid: '09_06_21_00',
            hn: 'Ⅱ / 複素数と方程式 / 頻出問題　仕上げ',
            vid: '7IC5x_bHyFNd',
            kuids: [ '09_03_06' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '10',
    un: '図形と方程式',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '点と直線（基本編）　Part1',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '２点間の距離',
            qnum1: '1',
            qnum2: '0',
            quid: '10_01_01_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part1',
            vid: 'cUEXIaAXBBb6',
            kuids: [ '10_01_01' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '内分・外分',
            qnum1: '2',
            qnum2: '0',
            quid: '10_01_02_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part1',
            vid: '2fXclYYldYZ9',
            kuids: [ '10_01_02' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '直線の方程式',
            qnum1: '3',
            qnum2: '1',
            quid: '10_01_03_01',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part1',
            vid: 'BDuRdUr5KjDC',
            kuids: [ '10_01_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '4',
            qn: '直線の方程式',
            qnum1: '3',
            qnum2: '2',
            quid: '10_01_03_02',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part1',
            vid: 'A1rys7RlmM_S',
            kuids: [ '10_01_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '5',
            qn: '直線の方程式',
            qnum1: '3',
            qnum2: '3',
            quid: '10_01_03_03',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part1',
            vid: 'ge0ziZaEt-u_',
            kuids: [ '10_01_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '6',
            qn: '直線の方程式',
            qnum1: '3',
            qnum2: '4',
            quid: '10_01_03_04',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part1',
            vid: 'QaIMZAVWWv25',
            kuids: [ '10_01_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '7',
            qn: '同一直線上',
            qnum1: '4',
            qnum2: '0',
            quid: '10_01_04_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part1',
            vid: '1RXJNJcf0yTq',
            kuids: [ '10_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '点と直線（基本編）　Part2',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '点と直線の距離（基本）',
            qnum1: '5',
            qnum2: '0',
            quid: '10_02_05_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part2',
            vid: 'iu1IwyqZgDhv',
            kuids: [ '10_01_04' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '点と直線の距離（応用）',
            qnum1: '6',
            qnum2: '0',
            quid: '10_02_06_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part2',
            vid: 't3diqyCmPsRc',
            kuids: [ '10_01_04', '10_01_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '平行・垂直条件',
            qnum1: '7',
            qnum2: '0',
            quid: '10_02_07_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part2',
            vid: 'cG0_rKhzPDsl',
            kuids: [ '10_01_05' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '4',
            qn: '直線の「束」',
            qnum1: '8',
            qnum2: '0',
            quid: '10_02_08_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part2',
            vid: 'tnreMldFfrCi',
            kuids: [ '10_01_06' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '5',
            qn: '定点通過',
            qnum1: '9',
            qnum2: '0',
            quid: '10_02_09_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（基本編）　Part2',
            vid: 'gDK4Xbn4sj6X',
            kuids: [ '10_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '点と直線（応用編）',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '三角形の面積',
            qnum1: '10',
            qnum2: '0',
            quid: '10_03_10_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（応用編）',
            vid: 'Y2v08to-mQAC',
            kuids: [ '10_01_07' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '対称点（基本）',
            qnum1: '11',
            qnum2: '0',
            quid: '10_03_11_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（応用編）',
            vid: '-2eyP8G41ioJ',
            kuids: [ '10_01_08' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '対称点（応用）',
            qnum1: '12',
            qnum2: '0',
            quid: '10_03_12_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（応用編）',
            vid: 'ITOiAHeawfQP',
            kuids: [ '10_01_08' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '4',
            qn: '３直線',
            qnum1: '13',
            qnum2: '0',
            quid: '10_03_13_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（応用編）',
            vid: '_TUwE_kibqxn',
            kuids: [ '10_01_09' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '5',
            qn: '２直線を表す方程式',
            qnum1: '14',
            qnum2: '0',
            quid: '10_03_14_00',
            hn: 'Ⅱ / 図形と方程式 / 点と直線（応用編）',
            vid: 'w2118pCvGrtB',
            kuids: [ '10_01_10' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '円（基本編）　Part１',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '円の決定',
            qnum1: '15',
            qnum2: '1',
            quid: '10_04_15_01',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part１',
            vid: '-02t3C7lAVU4',
            kuids: [ '10_02_01' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '円の決定',
            qnum1: '15',
            qnum2: '2',
            quid: '10_04_15_02',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part１',
            vid: 'df2qCsljfnND',
            kuids: [ '10_02_01' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '円の決定',
            qnum1: '15',
            qnum2: '3',
            quid: '10_04_15_03',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part１',
            vid: 'Xqi3wLKJD09F',
            kuids: [ '10_02_01' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '4',
            qn: '円の決定',
            qnum1: '15',
            qnum2: '4',
            quid: '10_04_15_04',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part１',
            vid: 'Lg6OmK-h4jrq',
            kuids: [ '10_02_01' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '5',
            qn: '円の成立条件',
            qnum1: '16',
            qnum2: '0',
            quid: '10_04_16_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part１',
            vid: 'wE5S7Kh82srB',
            kuids: [ '10_02_01' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '6',
            qn: '円の「束」',
            qnum1: '17',
            qnum2: '0',
            quid: '10_04_17_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part１',
            vid: 'G5PnSKutJUp9',
            kuids: [ '10_02_02' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '7',
            qn: '円と直線の位置関係',
            qnum1: '18',
            qnum2: '0',
            quid: '10_04_18_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part１',
            vid: 'j-w0LsFpL1ML',
            kuids: [ '10_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '円（基本編）　Part２',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '円と弦　その１',
            qnum1: '19',
            qnum2: '0',
            quid: '10_05_19_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part２',
            vid: '2xTSIl2yTZVM',
            kuids: [ '10_02_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '円と弦　その２',
            qnum1: '20',
            qnum2: '0',
            quid: '10_05_20_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part２',
            vid: 'OrRWmHrG06u6',
            kuids: [ '10_02_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '円の接線',
            qnum1: '21',
            qnum2: '0',
            quid: '10_05_21_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part２',
            vid: 'FPwBcOnLqh7O',
            kuids: [ '10_02_04' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '4',
            qn: '２円の関係（基本）',
            qnum1: '22',
            qnum2: '0',
            quid: '10_05_22_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part２',
            vid: 'Wtp613VjCXDj',
            kuids: [ '10_02_05' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '5',
            qn: '２円の関係（応用）',
            qnum1: '23',
            qnum2: '0',
            quid: '10_05_23_00',
            hn: 'Ⅱ / 図形と方程式 / 円（基本編）　Part２',
            vid: 'LL6iAmSwrEF2',
            kuids: [ '10_02_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '円（応用編）',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '極線',
            qnum1: '24',
            qnum2: '0',
            quid: '10_06_24_00',
            hn: 'Ⅱ / 図形と方程式 / 円（応用編）',
            vid: 'VaztG2UnxiCf',
            kuids: [ '10_02_06' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '同一円周上の３点',
            qnum1: '25',
            qnum2: '0',
            quid: '10_06_25_00',
            hn: 'Ⅱ / 図形と方程式 / 円（応用編）',
            vid: 'vizwpCthS1lr',
            kuids: [ '10_02_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '対称円',
            qnum1: '26',
            qnum2: '0',
            quid: '10_06_26_00',
            hn: 'Ⅱ / 図形と方程式 / 円（応用編）',
            vid: '4RtOQv9NTPVg',
            kuids: [ '10_01_08', '10_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '軌跡',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '軌跡（いらない文字なし）',
            qnum1: '27',
            qnum2: '0',
            quid: '10_07_27_00',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: '3Q7wqAxwnzs0',
            kuids: [ '10_03_02' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '軌跡（いらない文字あり）',
            qnum1: '28',
            qnum2: '0',
            quid: '10_07_28_00',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'x-KN_BdBpwIR',
            kuids: [ '10_03_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '軌跡（いらない文字ありの応用）',
            qnum1: '29',
            qnum2: '0',
            quid: '10_07_29_00',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'MPPRysgyqRnu',
            kuids: [ '10_03_05' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '4',
            qn: '軌跡（いらない文字を自分で作る　その１）',
            qnum1: '30',
            qnum2: '0',
            quid: '10_07_30_00',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'JygdPcA0xRgi',
            kuids: [ '10_03_04' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '5',
            qn: '軌跡（いらない文字を自分で作る　その２）',
            qnum1: '31',
            qnum2: '0',
            quid: '10_07_31_00',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'lqT5A3X6cHBT',
            kuids: [ '10_03_04' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '6',
            qn: '軌跡（図形的性質の利用）',
            qnum1: '32',
            qnum2: '0',
            quid: '10_07_32_00',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'DKh8baAYRYTy',
            kuids: [ '10_03_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '領域　Part1',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '領域（図示）',
            qnum1: '33',
            qnum2: '0',
            quid: '10_08_33_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part1',
            vid: '_lcdaAcK6-01',
            kuids: [ '10_04_01' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '領域と最大最小　その１',
            qnum1: '34',
            qnum2: '0',
            quid: '10_08_34_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part1',
            vid: 'R9JaKl8oEBz2',
            kuids: [ '10_04_02' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '領域と最大最小　その２',
            qnum1: '35',
            qnum2: '0',
            quid: '10_08_35_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part1',
            vid: 'z-KiUyAf1Bc6',
            kuids: [ '10_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '領域　Part2',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '領域と最大最小　その３',
            qnum1: '36',
            qnum2: '0',
            quid: '10_09_36_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part2',
            vid: 'LIwCmmLp8CWN',
            kuids: [ '10_04_02' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '領域と最大最小　その４',
            qnum1: '37',
            qnum2: '0',
            quid: '10_09_37_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part2',
            vid: 'NrRWhzlAQMhu',
            kuids: [ '10_04_02' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '3',
            qn: '領域と最大最小　その５',
            qnum1: '38',
            qnum2: '0',
            quid: '10_09_38_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part2',
            vid: 'gsnlaaLP9_wu',
            kuids: [ '10_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '領域　Part3',
        hn: 'Ⅱ / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '2bc',
            qid: '1',
            qn: '通過領域（基本）',
            qnum1: '39',
            qnum2: '0',
            quid: '10_10_39_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part3',
            vid: '5XVJTkh7dDJQ',
            kuids: [ '10_04_03' ],
            record: {}
          },
          {
            uid: '10',
            fid: '2bc',
            qid: '2',
            qn: '通貨領域（応用）',
            qnum1: '40',
            qnum2: '0',
            quid: '10_10_40_00',
            hn: 'Ⅱ / 図形と方程式 / 領域　Part3',
            vid: 'duOZ46pSOTNZ',
            kuids: [ '10_04_03' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '11',
    un: '三角関数',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '基本編  弧度法・一般角',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '値',
            qnum1: '1',
            qnum2: '0',
            quid: '11_01_01_00',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: '8BcyEqgMepmV',
            kuids: [ '11_01_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: '弧度法',
            qnum1: '2',
            qnum2: '0',
            quid: '11_01_02_00',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: 'Y28Ec8PyYJiG',
            kuids: [ '11_01_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: '補角・余角',
            qnum1: '3',
            qnum2: '0',
            quid: '11_01_03_00',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: 'Nz_9GnZYpi0b',
            kuids: [ '03_05_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '4',
            qn: '方程式・不等式',
            qnum1: '4',
            qnum2: '1',
            quid: '11_01_04_01',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: 'hdk42iQKybYj',
            kuids: [ '11_01_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '5',
            qn: '方程式・不等式',
            qnum1: '4',
            qnum2: '2',
            quid: '11_01_04_02',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: 'qNUvHS01SKxh',
            kuids: [ '11_01_01', '11_01_02' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '6',
            qn: '方程式・不等式',
            qnum1: '4',
            qnum2: '3',
            quid: '11_01_04_03',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: '5BmRwhbeVJSH',
            kuids: [ '11_01_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '7',
            qn: '方程式・不等式',
            qnum1: '4',
            qnum2: '4',
            quid: '11_01_04_04',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: 'Cx2Y0AT8STaT',
            kuids: [ '11_01_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '8',
            qn: '方程式・不等式',
            qnum1: '4',
            qnum2: '5',
            quid: '11_01_04_05',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: 'GuW3m7UHKxta',
            kuids: [ '11_01_01', '11_02_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '9',
            qn: '方程式・不等式',
            qnum1: '4',
            qnum2: '5.6',
            quid: '11_01_04_06',
            hn: 'Ⅱ / 三角関数 / 基本編  弧度法・一般角',
            vid: 'f9ZAhT2WSeyc',
            kuids: [ '11_01_01', '11_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '基本編  相互関係・グラフ',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '相互関係（基本）',
            qnum1: '5',
            qnum2: '0',
            quid: '11_02_05_00',
            hn: 'Ⅱ / 三角関数 / 基本編  相互関係・グラフ',
            vid: 'v9VeEqDjjWUE',
            kuids: [ '11_02_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: '相互関係（応用）',
            qnum1: '6',
            qnum2: '0',
            quid: '11_02_06_00',
            hn: 'Ⅱ / 三角関数 / 基本編  相互関係・グラフ',
            vid: 'gJx58hv2Do5x',
            kuids: [ '11_02_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: 'グラフ',
            qnum1: '7',
            qnum2: '0',
            quid: '11_02_07_00',
            hn: 'Ⅱ / 三角関数 / 基本編  相互関係・グラフ',
            vid: 'SpQGImUn-v0c',
            kuids: [ '11_03_01', '11_03_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '基本編  ２５公式　その１',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '加法定理　その１',
            qnum1: '8',
            qnum2: '0',
            quid: '11_03_08_00',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その１',
            vid: 'rJwjfEKlhgn4',
            kuids: [ '11_04_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: '加法定理　その２',
            qnum1: '9',
            qnum2: '0',
            quid: '11_03_09_00',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その１',
            vid: 'cCQ22yqreRIF',
            kuids: [ '11_04_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: '２直線のなす角',
            qnum1: '10',
            qnum2: '0',
            quid: '11_03_10_00',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その１',
            vid: 'SRi9zt94CFdJ',
            kuids: [ '11_04_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '4',
            qn: '倍角公式の練習',
            qnum1: '11',
            qnum2: '1',
            quid: '11_03_11_01',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その１',
            vid: 'rODB2yTE3y4d',
            kuids: [ '11_04_02' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '5',
            qn: '倍角公式の練習',
            qnum1: '11',
            qnum2: '2',
            quid: '11_03_11_02',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その１',
            vid: 'DuwHRoKPCHoB',
            kuids: [ '11_04_02' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '6',
            qn: '倍角公式の練習',
            qnum1: '11',
            qnum2: '3',
            quid: '11_03_11_03',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その１',
            vid: '5zmYUwoK9tCi',
            kuids: [ '11_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '基本編  ２５公式　その２',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '２倍角の重要題',
            qnum1: '12',
            qnum2: '0',
            quid: '11_04_12_00',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その２',
            vid: 'trr2QxZx6z8E',
            kuids: [ '11_04_02' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: 'その他倍角系の練習',
            qnum1: '13',
            qnum2: '0',
            quid: '11_04_13_00',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その２',
            vid: 'kc7pO-PTxWjj',
            kuids: [ '11_04_02' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: '合成の基本',
            qnum1: '14',
            qnum2: '1',
            quid: '11_04_14_01',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その２',
            vid: 'N_QHn3TQ1eXy',
            kuids: [ '11_04_03' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '4',
            qn: '合成の基本',
            qnum1: '14',
            qnum2: '2',
            quid: '11_04_14_02',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その２',
            vid: '-QII-Ank6rBN',
            kuids: [ '11_04_03' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '5',
            qn: '合成の基本',
            qnum1: '14',
            qnum2: '3',
            quid: '11_04_14_03',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その２',
            vid: 'cQrxM_dQd_3s',
            kuids: [ '11_04_03' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '6',
            qn: '合成の応用',
            qnum1: '15',
            qnum2: '0',
            quid: '11_04_15_00',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その２',
            vid: 'vYh4tUwfB5fN',
            kuids: [ '11_04_03' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '7',
            qn: '積和・和積の公式',
            qnum1: '16',
            qnum2: '0',
            quid: '11_04_16_00',
            hn: 'Ⅱ / 三角関数 / 基本編  ２５公式　その２',
            vid: 'pexekSvw0rmj',
            kuids: [ '11_04_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '応用編  その１',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '０．基本的な方程式＆不等式',
            qnum1: '17',
            qnum2: '1',
            quid: '11_05_17_01',
            hn: 'Ⅱ / 三角関数 / 応用編  その１',
            vid: 'AB2Q6PdKBKJ0',
            kuids: [ '11_10_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: '０．基本的な方程式＆不等式',
            qnum1: '17',
            qnum2: '2',
            quid: '11_05_17_02',
            hn: 'Ⅱ / 三角関数 / 応用編  その１',
            vid: 'nouW1iHkGPM6',
            kuids: [ '11_10_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: '１．公式のみで解けるもの',
            qnum1: '18',
            qnum2: '0',
            quid: '11_05_18_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その１',
            vid: 'XF8R6XE9zM7w',
            kuids: [ '11_11_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '4',
            qn: '２．２次方程式・不等式に帰着',
            qnum1: '19',
            qnum2: '0',
            quid: '11_05_19_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その１',
            vid: 'X_rK67zpfogl',
            kuids: [ '11_12_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '5',
            qn: '２．２次関数に帰着',
            qnum1: '20',
            qnum2: '0',
            quid: '11_05_20_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その１',
            vid: 'o2WNQJqf8x4i',
            kuids: [ '11_12_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '応用編  その２',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '２．の応用題（方程式の解の個数）',
            qnum1: '21',
            qnum2: '0',
            quid: '11_06_21_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その２',
            vid: 'dERJgMUYd0BE',
            kuids: [ '11_12_03' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: '３．sinとcosの１次式の和・差　その１',
            qnum1: '22',
            qnum2: '1',
            quid: '11_06_22_01',
            hn: 'Ⅱ / 三角関数 / 応用編  その２',
            vid: '73XSZLX1f4q4',
            kuids: [ '11_13_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: '３．sinとcosの１次式の和・差　その１',
            qnum1: '22',
            qnum2: '2',
            quid: '11_06_22_02',
            hn: 'Ⅱ / 三角関数 / 応用編  その２',
            vid: 'TZ_0LM3vGljl',
            kuids: [ '11_13_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '4',
            qn: '３．sinとcosの１次式の和・差　その１',
            qnum1: '22',
            qnum2: '3',
            quid: '11_06_22_03',
            hn: 'Ⅱ / 三角関数 / 応用編  その２',
            vid: 'xMq3Tw66Rq7u',
            kuids: [ '11_13_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '5',
            qn: '３．sinとcosの１次式の和・差　その２',
            qnum1: '23',
            qnum2: '0',
            quid: '11_06_23_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その２',
            vid: 'Yu-b4FjfH_eh',
            kuids: [ '11_13_02' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '6',
            qn: '４．sinとcosの積以外がsinとcosの和（or差）のみ',
            qnum1: '24',
            qnum2: '0',
            quid: '11_06_24_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その２',
            vid: 'CRJI2xgw2oVa',
            kuids: [ '11_14_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '応用編  その３',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '５．sinとcosの積以外がsinとcosの２乗または２倍角のみ（基本）',
            qnum1: '25',
            qnum2: '0',
            quid: '11_07_25_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その３',
            vid: 'DAMaqkyUMeoH',
            kuids: [ '11_15_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: '５．sinとcosの積以外がsinとcosの２乗または２倍角のみ（応用）',
            qnum1: '26',
            qnum2: '0',
            quid: '11_07_26_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その３',
            vid: '-yw7zDAEVz4Y',
            kuids: [ '11_15_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: '６．積和・和積の公式の左辺の形',
            qnum1: '27',
            qnum2: '0',
            quid: '11_07_27_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その３',
            vid: 'h0pKJvG-iDaS',
            kuids: [ '11_16_01' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '4',
            qn: '７．１〜６でダメなら…',
            qnum1: '28',
            qnum2: '0',
            quid: '11_07_28_00',
            hn: 'Ⅱ / 三角関数 / 応用編  その３',
            vid: 'bGs5Q9gQVJ62',
            kuids: [ '11_17_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '総合応用題',
        hn: 'Ⅱ / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '2bc',
            qid: '1',
            qn: '２次関数に帰着（文字場合分け）',
            qnum1: '29',
            qnum2: '0',
            quid: '11_08_29_00',
            hn: 'Ⅱ / 三角関数 / 総合応用題',
            vid: 'D8U4gXdYiPvJ',
            kuids: [ '11_12_02', '02_02_04' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '2',
            qn: '方程式の応用題',
            qnum1: '30',
            qnum2: '0',
            quid: '11_08_30_00',
            hn: 'Ⅱ / 三角関数 / 総合応用題',
            vid: '4mVsKVpA2L9o',
            kuids: [ '11_04_03', '11_04_04' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '3',
            qn: '文章題',
            qnum1: '31',
            qnum2: '0',
            quid: '11_08_31_00',
            hn: 'Ⅱ / 三角関数 / 総合応用題',
            vid: 'x3mcHTt-n0L9',
            kuids: [ '11_04_02' ],
            record: {}
          },
          {
            uid: '11',
            fid: '2bc',
            qid: '4',
            qn: '積和・和積の応用',
            qnum1: '32',
            qnum2: '0',
            quid: '11_08_32_00',
            hn: 'Ⅱ / 三角関数 / 総合応用題',
            vid: '3mcU92_YtiBV',
            kuids: [ '11_04_04' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '12',
    un: '指数・対数関数',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '「法則」と「性質」＜指数編＞',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '指数法則',
            qnum1: '1',
            qnum2: '1',
            quid: '12_01_01_01',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: 'nw9VQwQv-Bvm',
            kuids: [ '12_01_01', '12_01_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '指数法則',
            qnum1: '1',
            qnum2: '2',
            quid: '12_01_01_02',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: 'En-nWBTA4vg0',
            kuids: [ '12_01_01', '12_01_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '指数法則',
            qnum1: '1',
            qnum2: '3',
            quid: '12_01_01_03',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: '3M6Rp5fjlQi7',
            kuids: [ '12_01_01', '12_01_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '4',
            qn: '指数法則',
            qnum1: '1',
            qnum2: '4',
            quid: '12_01_01_04',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: 'Ca0TpMM-hX8r',
            kuids: [ '12_01_01', '12_01_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '5',
            qn: '指数の性質',
            qnum1: '2',
            qnum2: '1',
            quid: '12_01_02_01',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: '_jvcJSSy1VML',
            kuids: [ '12_01_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '6',
            qn: '指数の性質',
            qnum1: '2',
            qnum2: '2',
            quid: '12_01_02_02',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: 'JOOVR04_v-1X',
            kuids: [ '12_01_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '7',
            qn: '指数の性質',
            qnum1: '2',
            qnum2: '3',
            quid: '12_01_02_03',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: 'twCqVkC3c7R7',
            kuids: [ '12_01_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '8',
            qn: '指数の性質',
            qnum1: '2',
            qnum2: '4',
            quid: '12_01_02_04',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜指数編＞',
            vid: '0NgmkRhGop3m',
            kuids: [ '12_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '「法則」と「性質」＜対数編＞',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '対数の法則（基本）',
            qnum1: '3',
            qnum2: '1',
            quid: '12_02_03_01',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜対数編＞',
            vid: '29SlqSaIofQi',
            kuids: [ '12_01_04', '12_01_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '対数の法則（基本）',
            qnum1: '3',
            qnum2: '2',
            quid: '12_02_03_02',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜対数編＞',
            vid: '6Fz4D4ZRTALl',
            kuids: [ '12_01_04', '12_01_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '対数の法則（基本）',
            qnum1: '3',
            qnum2: '3',
            quid: '12_02_03_03',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜対数編＞',
            vid: '7-J_PJghs5Vz',
            kuids: [ '12_01_04', '12_01_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '4',
            qn: '対数の法則（基本）',
            qnum1: '3',
            qnum2: '4',
            quid: '12_02_03_04',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜対数編＞',
            vid: 'Q2JPF-HKJ3jd',
            kuids: [ '12_01_04', '12_01_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '5',
            qn: '対数の法則（典型題その１）',
            qnum1: '4',
            qnum2: '0',
            quid: '12_02_04_00',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜対数編＞',
            vid: 'oAi20EA31z8Y',
            kuids: [ '12_01_04', '12_01_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '6',
            qn: '対数の法則（典型題その２）',
            qnum1: '5',
            qnum2: '0',
            quid: '12_02_05_00',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜対数編＞',
            vid: 'ayNZfDukpQf7',
            kuids: [ '12_01_04', '12_01_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '7',
            qn: '対数の性質',
            qnum1: '6',
            qnum2: '0',
            quid: '12_02_06_00',
            hn: 'Ⅱ / 指数・対数関数 / 「法則」と「性質」＜対数編＞',
            vid: 'es_lWeEiUdZ4',
            kuids: [ '12_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: 'グラフと指数方程式・不等式',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: 'グラフの性質',
            qnum1: '7',
            qnum2: '0',
            quid: '12_03_07_00',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: 'mO3BMY1jzmQz',
            kuids: [ '12_02_01' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '指数方程式',
            qnum1: '8',
            qnum2: '1',
            quid: '12_03_08_01',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: 'zbhs4hb-PC86',
            kuids: [ '12_02_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '指数方程式',
            qnum1: '8',
            qnum2: '2',
            quid: '12_03_08_02',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: '67Os1p17LZHM',
            kuids: [ '12_02_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '4',
            qn: '指数方程式',
            qnum1: '8',
            qnum2: '3',
            quid: '12_03_08_03',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: '9BF2ZUaUCNQL',
            kuids: [ '12_02_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '5',
            qn: '指数方程式',
            qnum1: '8',
            qnum2: '4',
            quid: '12_03_08_04',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: 'XIw7J1wqDne2',
            kuids: [ '12_02_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '6',
            qn: '指数不等式',
            qnum1: '9',
            qnum2: '1',
            quid: '12_03_09_01',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: 'Ewk4tTxSRMGj',
            kuids: [ '12_02_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '7',
            qn: '指数不等式',
            qnum1: '9',
            qnum2: '2',
            quid: '12_03_09_02',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: 'm9AWc2_gereL',
            kuids: [ '12_02_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '8',
            qn: '指数不等式',
            qnum1: '9',
            qnum2: '3',
            quid: '12_03_09_03',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: 'hkdefvMUqIrs',
            kuids: [ '12_02_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '9',
            qn: '指数方程式の応用',
            qnum1: '10',
            qnum2: '0',
            quid: '12_03_10_00',
            hn: 'Ⅱ / 指数・対数関数 / グラフと指数方程式・不等式',
            vid: 'Cjgn9haMIuWF',
            kuids: [ '12_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: 'グラフと対数方程式・不等式',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '対数方程式',
            qnum1: '11',
            qnum2: '1',
            quid: '12_04_11_01',
            hn: 'Ⅱ / 指数・対数関数 / グラフと対数方程式・不等式',
            vid: 'wCZFJ2zPLS2k',
            kuids: [ '12_02_04' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '対数方程式',
            qnum1: '11',
            qnum2: '2',
            quid: '12_04_11_02',
            hn: 'Ⅱ / 指数・対数関数 / グラフと対数方程式・不等式',
            vid: '6Wlt1kEPR_Md',
            kuids: [ '12_02_04' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '対数方程式',
            qnum1: '11',
            qnum2: '3',
            quid: '12_04_11_03',
            hn: 'Ⅱ / 指数・対数関数 / グラフと対数方程式・不等式',
            vid: 'EgtREdBJyg6A',
            kuids: [ '12_02_04' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '4',
            qn: '対数不等式',
            qnum1: '12',
            qnum2: '1',
            quid: '12_04_12_01',
            hn: 'Ⅱ / 指数・対数関数 / グラフと対数方程式・不等式',
            vid: '_6trXeWojHvQ',
            kuids: [ '12_02_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '5',
            qn: '対数不等式',
            qnum1: '12',
            qnum2: '2',
            quid: '12_04_12_02',
            hn: 'Ⅱ / 指数・対数関数 / グラフと対数方程式・不等式',
            vid: 'G6onDXmqB7-J',
            kuids: [ '12_02_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '6',
            qn: '対数不等式',
            qnum1: '12',
            qnum2: '3',
            quid: '12_04_12_03',
            hn: 'Ⅱ / 指数・対数関数 / グラフと対数方程式・不等式',
            vid: 'xCpGO1GRz1uX',
            kuids: [ '12_02_05' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '7',
            qn: '対数不等式',
            qnum1: '12',
            qnum2: '4',
            quid: '12_04_12_04',
            hn: 'Ⅱ / 指数・対数関数 / グラフと対数方程式・不等式',
            vid: 'm0aWmcsU3HU_',
            kuids: [ '12_02_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '最大最小（基本的なもの）',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '指数関数の最大最小',
            qnum1: '13',
            qnum2: '1',
            quid: '12_05_13_01',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本的なもの）',
            vid: 'NLDMCMlYjD7u',
            kuids: [ '12_03_01' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '指数関数の最大最小',
            qnum1: '13',
            qnum2: '2',
            quid: '12_05_13_02',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本的なもの）',
            vid: 'KUY_ifQkNgsh',
            kuids: [ '12_03_01' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '指数関数の最大最小',
            qnum1: '13',
            qnum2: '3',
            quid: '12_05_13_03',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本的なもの）',
            vid: 'SgfzH14R7NAW',
            kuids: [ '12_03_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '4',
            qn: '対数関数の最大最小',
            qnum1: '14',
            qnum2: '1',
            quid: '12_05_14_01',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本的なもの）',
            vid: 'n5HWJig0uftu',
            kuids: [ '12_03_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '5',
            qn: '対数関数の最大最小',
            qnum1: '14',
            qnum2: '2',
            quid: '12_05_14_02',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本的なもの）',
            vid: 'EKgRcBLUAgUy',
            kuids: [ '12_03_04' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '6',
            qn: '対数関数の最大最小',
            qnum1: '14',
            qnum2: '3',
            quid: '12_05_14_03',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本的なもの）',
            vid: 'H2TL3gc_CMcz',
            kuids: [ '12_03_04' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '7',
            qn: '対数関数の最大最小',
            qnum1: '14',
            qnum2: '4',
            quid: '12_05_14_04',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本的なもの）',
            vid: 'rmgzOKAEx96B',
            kuids: [ '12_03_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '種々の応用題　その１',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '対称式の置き換え（基本）',
            qnum1: '15',
            qnum2: '0',
            quid: '12_06_15_00',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題　その１',
            vid: '3ycwI3i2jmnM',
            kuids: [ '12_04_01' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '対称式の置き換え（文字場合分け）',
            qnum1: '16',
            qnum2: '0',
            quid: '12_06_16_00',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題　その１',
            vid: 'yo3Iq2RVle-8',
            kuids: [ '12_04_01' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '大小比較',
            qnum1: '17',
            qnum2: '1',
            quid: '12_06_17_01',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題　その１',
            vid: 'rPHaFNLqBmDz',
            kuids: [ '12_04_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '4',
            qn: '大小比較',
            qnum1: '17',
            qnum2: '2',
            quid: '12_06_17_02',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題　その１',
            vid: 'rcFN8yzwjOyJ',
            kuids: [ '12_04_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '種々の応用題　その２',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '領域（図示）',
            qnum1: '18',
            qnum2: '1',
            quid: '12_07_18_01',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題　その２',
            vid: 'OYhsmbeI_YfP',
            kuids: [ '12_04_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '領域（図示）',
            qnum1: '18',
            qnum2: '2',
            quid: '12_07_18_02',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題　その２',
            vid: 'C6yz04CjWHL_',
            kuids: [ '12_04_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '領域（図示）',
            qnum1: '18',
            qnum2: '3',
            quid: '12_07_18_03',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題　その２',
            vid: 'auUR1sCDbtyX',
            kuids: [ '12_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '桁数問題の準備',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '常用対数の準備題',
            qnum1: '19',
            qnum2: '0',
            quid: '12_08_19_00',
            hn: 'Ⅱ / 指数・対数関数 / 桁数問題の準備',
            vid: 'fL_8mV5nMxJD',
            kuids: [ '12_05_01' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '常用対数',
            qnum1: '20',
            qnum2: '98',
            quid: '12_08_20_98',
            hn: 'Ⅱ / 指数・対数関数 / 桁数問題の準備',
            vid: 'dDT2bjuC0iX9',
            kuids: [ '12_05_01' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '複利計算',
            qnum1: '21',
            qnum2: '0',
            quid: '12_08_21_00',
            hn: 'Ⅱ / 指数・対数関数 / 桁数問題の準備',
            vid: 'Kl9eYzIuh717',
            kuids: [ '12_05_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '桁数&小数首位・最高位の数',
        hn: 'Ⅱ / 指数・対数関数',
        questions: [
          {
            uid: '12',
            fid: '2bc',
            qid: '1',
            qn: '桁数&小数首位の桁',
            qnum1: '22',
            qnum2: '98',
            quid: '12_09_22_98',
            hn: 'Ⅱ / 指数・対数関数 / 桁数&小数首位・最高位の数',
            vid: 'vg1vq5vkbyMC',
            kuids: [ '12_05_02' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '2',
            qn: '桁数&最高位の数',
            qnum1: '23',
            qnum2: '98',
            quid: '12_09_23_98',
            hn: 'Ⅱ / 指数・対数関数 / 桁数&小数首位・最高位の数',
            vid: '6ie9rWJt9CPp',
            kuids: [ '12_05_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '3',
            qn: '初めて現れる０でない数',
            qnum1: '24',
            qnum2: '98',
            quid: '12_09_24_98',
            hn: 'Ⅱ / 指数・対数関数 / 桁数&小数首位・最高位の数',
            vid: 'x0iMpRKJcWV1',
            kuids: [ '12_05_03' ],
            record: {}
          },
          {
            uid: '12',
            fid: '2bc',
            qid: '4',
            qn: '最高位の数',
            qnum1: '25',
            qnum2: '98',
            quid: '12_09_25_98',
            hn: 'Ⅱ / 指数・対数関数 / 桁数&小数首位・最高位の数',
            vid: 'Cqawk7_rp1UV',
            kuids: [ '12_05_03' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '13',
    un: '微分積分',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '極限＆微分係数',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '極限の基本',
            qnum1: '1',
            qnum2: '1',
            quid: '13_01_01_01',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'rluCQXCjPpu0',
            kuids: [ '13_01_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '極限の基本',
            qnum1: '1',
            qnum2: '2',
            quid: '13_01_01_02',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'N-vRSL303jo1',
            kuids: [ '13_01_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '極限の基本',
            qnum1: '1',
            qnum2: '3',
            quid: '13_01_01_03',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'vcY3HfYdmIDF',
            kuids: [ '13_01_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '極限（係数決定）',
            qnum1: '2',
            qnum2: '1',
            quid: '13_01_02_01',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'DSMRAc5aaJsz',
            kuids: [ '13_01_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '5',
            qn: '極限（係数決定）',
            qnum1: '2',
            qnum2: '2',
            quid: '13_01_02_02',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'CT8T4hasX1bX',
            kuids: [ '13_01_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '6',
            qn: '平均変化率と微分係数',
            qnum1: '3',
            qnum2: '0',
            quid: '13_01_03_00',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'Qm96hEpakx68',
            kuids: [ '13_01_02' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '7',
            qn: "f(a)、f'(a)を用いて表す",
            qnum1: '4',
            qnum2: '1',
            quid: '13_01_04_01',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'o58fqVhkj9HZ',
            kuids: [ '13_01_02' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '8',
            qn: "f(a)、f'(a)を用いて表す",
            qnum1: '4',
            qnum2: '2',
            quid: '13_01_04_02',
            hn: 'Ⅱ / 微分積分 / 極限＆微分係数',
            vid: 'JOg71vHfwD-T',
            kuids: [ '13_01_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '導関数＆極値・グラフ',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '導関数を求める',
            qnum1: '5',
            qnum2: '1',
            quid: '13_02_05_01',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: '7OEwT0qD_M3C',
            kuids: [ '13_01_03' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '導関数を求める',
            qnum1: '5',
            qnum2: '2',
            quid: '13_02_05_02',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'Xo0gciAdu4IM',
            kuids: [ '13_01_03' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '導関数を求める',
            qnum1: '5',
            qnum2: '3',
            quid: '13_02_05_03',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'j2MsSJM3CUtq',
            kuids: [ '13_01_04' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '恒等式',
            qnum1: '6',
            qnum2: '0',
            quid: '13_02_06_00',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'Ai1dVJ17Y58O',
            kuids: [ '13_01_03' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '5',
            qn: '極値をとるｘの値',
            qnum1: '7',
            qnum2: '0',
            quid: '13_02_07_00',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'FT3e1qxHG9JJ',
            kuids: [ '13_01_05' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '6',
            qn: '極値に関する求値問題',
            qnum1: '8',
            qnum2: '1',
            quid: '13_02_08_01',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'FO-46nqhzie1',
            kuids: [ '13_01_05', '13_01_06' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '7',
            qn: '極値に関する求値問題',
            qnum1: '8',
            qnum2: '2',
            quid: '13_02_08_02',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'o343W9UpNH1h',
            kuids: [ '13_01_05', '13_01_06' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '8',
            qn: '極値に関する求値問題',
            qnum1: '8',
            qnum2: '3',
            quid: '13_02_08_03',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'jE-yAqUUFXI5',
            kuids: [ '13_01_05', '13_01_06' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '9',
            qn: '極値に関する求値問題',
            qnum1: '8',
            qnum2: '4',
            quid: '13_02_08_04',
            hn: 'Ⅱ / 微分積分 / 導関数＆極値・グラフ',
            vid: 'Sbw7EkzyFmco',
            kuids: [ '13_01_05', '13_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '最大最小　その１',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '最大最小（基本）',
            qnum1: '9',
            qnum2: '0',
            quid: '13_03_09_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その１',
            vid: 'bhz-lrlUauCf',
            kuids: [ '13_01_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '最大最小（文字消去）',
            qnum1: '10',
            qnum2: '0',
            quid: '13_03_10_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その１',
            vid: 'td-onUOMikqI',
            kuids: [ '13_01_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '最大最小（文字場合分け）　その１',
            qnum1: '11',
            qnum2: '0',
            quid: '13_03_11_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その１',
            vid: '_4KkatiUGJi3',
            kuids: [ '13_01_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '最大最小（文字場合分け）　その２',
            qnum1: '12',
            qnum2: '0',
            quid: '13_03_12_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その１',
            vid: 'QNA7yEnwHjfX',
            kuids: [ '13_01_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '5',
            qn: '最大最小（文字場合分け）　その３',
            qnum1: '13',
            qnum2: '0',
            quid: '13_03_13_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その１',
            vid: 'EKYsmzyAuBNS',
            kuids: [ '13_01_07' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '最大最小　その２',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '最大最小（文章題）',
            qnum1: '14',
            qnum2: '0',
            quid: '13_04_14_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その２',
            vid: 'zmGVedtpesD4',
            kuids: [ '13_01_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '最大最小（三角関数）　基本',
            qnum1: '15',
            qnum2: '0',
            quid: '13_04_15_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その２',
            vid: 'KtOQtp5bYH5Y',
            kuids: [ '13_01_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '最大最小（三角関数）　対称式',
            qnum1: '16',
            qnum2: '0',
            quid: '13_04_16_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その２',
            vid: 'YT0ojTlVXnLQ',
            kuids: [ '13_01_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '最大最小（指数対数関数）',
            qnum1: '17',
            qnum2: '0',
            quid: '13_04_17_00',
            hn: 'Ⅱ / 微分積分 / 最大最小　その２',
            vid: 'gUUCo5trsHmN',
            kuids: [ '13_01_07' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '方程式・不等式への応用',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '方程式への応用（定数分離）',
            qnum1: '18',
            qnum2: '0',
            quid: '13_05_18_00',
            hn: 'Ⅱ / 微分積分 / 方程式・不等式への応用',
            vid: 'Tzmx7o5nOUYo',
            kuids: [ '13_01_08' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '共有点の個数（定数分離）',
            qnum1: '19',
            qnum2: '0',
            quid: '13_05_19_00',
            hn: 'Ⅱ / 微分積分 / 方程式・不等式への応用',
            vid: 'oZhyTD8tAvIe',
            kuids: [ '13_01_08' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '方程式への応用（極値の積）',
            qnum1: '20',
            qnum2: '0',
            quid: '13_05_20_00',
            hn: 'Ⅱ / 微分積分 / 方程式・不等式への応用',
            vid: 'Zb_g1Rm4jScu',
            kuids: [ '13_01_08' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '不等式への応用',
            qnum1: '21',
            qnum2: '0',
            quid: '13_05_21_00',
            hn: 'Ⅱ / 微分積分 / 方程式・不等式への応用',
            vid: 'ujundNbq7nYO',
            kuids: [ '13_01_09' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '接線',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '接線の基本題',
            qnum1: '22',
            qnum2: '1',
            quid: '13_06_22_01',
            hn: 'Ⅱ / 微分積分 / 接線',
            vid: '4UItkp3Q8iMT',
            kuids: [ '13_01_10' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '接線の基本題',
            qnum1: '22',
            qnum2: '2',
            quid: '13_06_22_02',
            hn: 'Ⅱ / 微分積分 / 接線',
            vid: 'QBVwkBlTn7eh',
            kuids: [ '13_01_10' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '接線の基本題',
            qnum1: '22',
            qnum2: '3',
            quid: '13_06_22_03',
            hn: 'Ⅱ / 微分積分 / 接線',
            vid: '-HLPtqo99LhU',
            kuids: [ '13_01_10' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '２曲線が接する・直交する',
            qnum1: '23',
            qnum2: '0',
            quid: '13_06_23_00',
            hn: 'Ⅱ / 微分積分 / 接線',
            vid: 'kmtooogO9Bhv',
            kuids: [ '13_01_11' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '5',
            qn: '接線の本数（定数分離）',
            qnum1: '24',
            qnum2: '0',
            quid: '13_06_24_00',
            hn: 'Ⅱ / 微分積分 / 接線',
            vid: 'G-bG3oGfBYnm',
            kuids: [ '13_01_11' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '6',
            qn: '接線の本数（極値の値）',
            qnum1: '25',
            qnum2: '0',
            quid: '13_06_25_00',
            hn: 'Ⅱ / 微分積分 / 接線',
            vid: 'wkV_Vu2ZQ2RP',
            kuids: [ '13_01_11' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '積分計算の基本',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '不定積分の計算',
            qnum1: '26',
            qnum2: '1',
            quid: '13_07_26_01',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'yMhXCgwzUsey',
            kuids: [ '13_02_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '不定積分の計算',
            qnum1: '26',
            qnum2: '2',
            quid: '13_07_26_02',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'crh2Xgm7plho',
            kuids: [ '13_02_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '不定積分（関数決定）',
            qnum1: '27',
            qnum2: '0',
            quid: '13_07_27_00',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'JfrV-8R4QzK4',
            kuids: [ '13_02_01' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '定積分の計算',
            qnum1: '28',
            qnum2: '1',
            quid: '13_07_28_01',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'MIBaYMxC6Tfw',
            kuids: [ '13_02_02' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '5',
            qn: '定積分の計算',
            qnum1: '28',
            qnum2: '2',
            quid: '13_07_28_02',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'v6KCCIt9tueb',
            kuids: [ '13_02_04' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '6',
            qn: '定積分の計算',
            qnum1: '28',
            qnum2: '3',
            quid: '13_07_28_03',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'K0o3_xEf-Dx2',
            kuids: [ '13_02_05' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '7',
            qn: '定積分の計算',
            qnum1: '28',
            qnum2: '4',
            quid: '13_07_28_04',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'QOWcT0GykeCa',
            kuids: [ '13_02_06' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '8',
            qn: '定積分の計算',
            qnum1: '28',
            qnum2: '5',
            quid: '13_07_28_05',
            hn: 'Ⅱ / 微分積分 / 積分計算の基本',
            vid: 'TNitbSB2OJFa',
            kuids: [ '13_02_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '積分計算の応用',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '定積分の応用　その１',
            qnum1: '29',
            qnum2: '0',
            quid: '13_08_29_00',
            hn: 'Ⅱ / 微分積分 / 積分計算の応用',
            vid: 'dy6TnPkZWH4c',
            kuids: [ '13_02_05' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '定積分の応用　その２',
            qnum1: '30',
            qnum2: '0',
            quid: '13_08_30_00',
            hn: 'Ⅱ / 微分積分 / 積分計算の応用',
            vid: 'GH1JWoT50p4Q',
            kuids: [ '13_02_05' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '定積分の応用　その３',
            qnum1: '31',
            qnum2: '0',
            quid: '13_08_31_00',
            hn: 'Ⅱ / 微分積分 / 積分計算の応用',
            vid: 'gcpC7erdlIBS',
            kuids: [ '13_02_05' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '定積分の応用　その４',
            qnum1: '32',
            qnum2: '0',
            quid: '13_08_32_00',
            hn: 'Ⅱ / 微分積分 / 積分計算の応用',
            vid: 'jScH5figuROg',
            kuids: [ '13_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '定積分関数＆面積の基本',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '関数決定（区間が定数のみ）',
            qnum1: '33',
            qnum2: '1',
            quid: '13_09_33_01',
            hn: 'Ⅱ / 微分積分 / 定積分関数＆面積の基本',
            vid: 'di1xNMBet-F9',
            kuids: [ '13_02_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '関数決定（区間が定数のみ）',
            qnum1: '33',
            qnum2: '2',
            quid: '13_09_33_02',
            hn: 'Ⅱ / 微分積分 / 定積分関数＆面積の基本',
            vid: 'QM9SvazqntW4',
            kuids: [ '13_02_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '関数決定（区間に変数含む）',
            qnum1: '34',
            qnum2: '0',
            quid: '13_09_34_00',
            hn: 'Ⅱ / 微分積分 / 定積分関数＆面積の基本',
            vid: 'SfzTVeej9Op2',
            kuids: [ '13_02_07' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '4',
            qn: '面積の基本題',
            qnum1: '35',
            qnum2: '1',
            quid: '13_09_35_01',
            hn: 'Ⅱ / 微分積分 / 定積分関数＆面積の基本',
            vid: 'OIZIM358-Y-g',
            kuids: [ '13_02_08' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '5',
            qn: '面積の基本題',
            qnum1: '35',
            qnum2: '2',
            quid: '13_09_35_02',
            hn: 'Ⅱ / 微分積分 / 定積分関数＆面積の基本',
            vid: 'bJPbLAL7X0yO',
            kuids: [ '13_02_08' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '6',
            qn: '面積の基本題',
            qnum1: '35',
            qnum2: '3',
            quid: '13_09_35_03',
            hn: 'Ⅱ / 微分積分 / 定積分関数＆面積の基本',
            vid: 'upiN1Vj1Mv1i',
            kuids: [ '13_02_08' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '7',
            qn: '1／６公式',
            qnum1: '36',
            qnum2: '0',
            quid: '13_09_36_00',
            hn: 'Ⅱ / 微分積分 / 定積分関数＆面積の基本',
            vid: '1qCDFizepwEt',
            kuids: [ '13_02_08' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '面積（頻１）〜（頻２）',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '（頻１）面積の最大最小',
            qnum1: '37',
            qnum2: '0',
            quid: '13_10_37_00',
            hn: 'Ⅱ / 微分積分 / 面積（頻１）〜（頻２）',
            vid: 'wvXyQFQVNZfI',
            kuids: [ '13_02_09' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '（頻２）外の点から２接線（基本）',
            qnum1: '38',
            qnum2: '0',
            quid: '13_10_38_00',
            hn: 'Ⅱ / 微分積分 / 面積（頻１）〜（頻２）',
            vid: 'vNu8wViMMJ4g',
            kuids: [ '13_02_10' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '（頻２）外の点から２接線（文字）',
            qnum1: '39',
            qnum2: '0',
            quid: '13_10_39_00',
            hn: 'Ⅱ / 微分積分 / 面積（頻１）〜（頻２）',
            vid: 'exuRqi4hc0bE',
            kuids: [ '13_02_10' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '11',
        pn: '面積（頻３）〜（頻５）',
        hn: 'Ⅱ / 微分積分',
        questions: [
          {
            uid: '13',
            fid: '2bc',
            qid: '1',
            qn: '（頻３）２次関数の共通接線',
            qnum1: '40',
            qnum2: '0',
            quid: '13_11_40_00',
            hn: 'Ⅱ / 微分積分 / 面積（頻３）〜（頻５）',
            vid: 'tJM4zB28Apyt',
            kuids: [ '13_02_11' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '2',
            qn: '（頻４）３次関数に接する',
            qnum1: '41',
            qnum2: '0',
            quid: '13_11_41_00',
            hn: 'Ⅱ / 微分積分 / 面積（頻３）〜（頻５）',
            vid: 'tDd20KPC1VEm',
            kuids: [ '13_02_12' ],
            record: {}
          },
          {
            uid: '13',
            fid: '2bc',
            qid: '3',
            qn: '（頻５）面積の等分',
            qnum1: '42',
            qnum2: '0',
            quid: '13_11_42_00',
            hn: 'Ⅱ / 微分積分 / 面積（頻３）〜（頻５）',
            vid: 'KAYkaGmxkGbS',
            kuids: [ '13_02_13' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '14',
    un: '数列',
    fid: '2bc',
    hn: 'B',
    prints: [
      {
        pid: '1',
        pn: '等差数列',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: '一般項と和',
            qnum1: '1',
            qnum2: '0',
            quid: '14_01_01_00',
            hn: 'B / 数列 / 等差数列',
            vid: '0aOkdzizHYEI',
            kuids: [ '14_01_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: '和の最大',
            qnum1: '2',
            qnum2: '0',
            quid: '14_01_02_00',
            hn: 'B / 数列 / 等差数列',
            vid: 'gR5UX9PvBbi1',
            kuids: [ '14_01_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '3',
            qn: '集合と等差数列',
            qnum1: '3',
            qnum2: '0',
            quid: '14_01_03_00',
            hn: 'B / 数列 / 等差数列',
            vid: 'Whb6WdNnCYnA',
            kuids: [ '14_01_01', '14_01_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '4',
            qn: '等差数列の応用',
            qnum1: '4',
            qnum2: '0',
            quid: '14_01_04_00',
            hn: 'B / 数列 / 等差数列',
            vid: 'T4C4w-mpx0yl',
            kuids: [ '14_01_01', '14_01_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '等比数列',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: '一般項と和',
            qnum1: '5',
            qnum2: '0',
            quid: '14_02_05_00',
            hn: 'B / 数列 / 等比数列',
            vid: 'aUge-PZBVhkG',
            kuids: [ '14_02_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: '〜より初めて大きくなる',
            qnum1: '6',
            qnum2: '0',
            quid: '14_02_06_00',
            hn: 'B / 数列 / 等比数列',
            vid: 'qf1AwK2OQqgs',
            kuids: [ '14_02_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '3',
            qn: '２つの和から、新たな和',
            qnum1: '7',
            qnum2: '0',
            quid: '14_02_07_00',
            hn: 'B / 数列 / 等比数列',
            vid: 'xeICa4qYL93F',
            kuids: [ '14_02_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '4',
            qn: '等比数列の応用',
            qnum1: '8',
            qnum2: '0',
            quid: '14_02_08_00',
            hn: 'B / 数列 / 等比数列',
            vid: '4bFj1dNLB5K0',
            kuids: [ '14_02_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '5',
            qn: '等差中項・等比中項',
            qnum1: '9',
            qnum2: '0',
            quid: '14_02_09_00',
            hn: 'B / 数列 / 等比数列',
            vid: 'nmzFByzZJEyM',
            kuids: [ '14_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: 'Σ（基本編）',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '1',
            quid: '14_03_10_01',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'SE_kfMaoF4eG',
            kuids: [ '14_03_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '2',
            quid: '14_03_10_02',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'ICX6cnVXrvu_',
            kuids: [ '14_03_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '3',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '3',
            quid: '14_03_10_03',
            hn: 'B / 数列 / Σ（基本編）',
            vid: '1WMJO16bE5wp',
            kuids: [ '14_03_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '4',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '4',
            quid: '14_03_10_04',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'JkOKHA2g2syG',
            kuids: [ '14_03_03' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '5',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '5',
            quid: '14_03_10_05',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'oBw66L60kTq4',
            kuids: [ '14_03_03' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '6',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '6',
            quid: '14_03_10_06',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'EpdFEBQA1xY5',
            kuids: [ '14_03_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '7',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '7',
            quid: '14_03_10_07',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'Z4kk2K2P9Van',
            kuids: [ '14_03_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '8',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '8',
            quid: '14_03_10_08',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'c3DZyh_AQ2Mm',
            kuids: [ '14_03_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '9',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '9',
            quid: '14_03_10_09',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'QLwgNkfWc9RT',
            kuids: [ '14_03_03' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '10',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '10',
            quid: '14_03_10_10',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'apy4eRWYp2u4',
            kuids: [ '14_03_04' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '11',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '11',
            quid: '14_03_10_11',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'uMFMpAzwevlE',
            kuids: [ '14_03_04' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '12',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '12',
            quid: '14_03_10_12',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'RxAjoR_AXLxy',
            kuids: [ '14_03_04' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '13',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '13',
            quid: '14_03_10_13',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'nouPWVZRcFYq',
            kuids: [ '14_03_05' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '14',
            qn: 'Σの計算練習',
            qnum1: '10',
            qnum2: '14',
            quid: '14_03_10_14',
            hn: 'B / 数列 / Σ（基本編）',
            vid: 'HCd0HmDNrxZn',
            kuids: [ '14_03_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: 'Σ（応用編）＆群数列',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: '異なる２項の積の和',
            qnum1: '11',
            qnum2: '0',
            quid: '14_04_11_00',
            hn: 'B / 数列 / Σ（応用編）＆群数列',
            vid: 'u_CG21gkumxn',
            kuids: [ '14_02_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: '格子点',
            qnum1: '12',
            qnum2: '0',
            quid: '14_04_12_00',
            hn: 'B / 数列 / Σ（応用編）＆群数列',
            vid: 'VJ8Mmw3QBq4R',
            kuids: [ '14_02_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '3',
            qn: '群数列（基本）',
            qnum1: '13',
            qnum2: '0',
            quid: '14_04_13_00',
            hn: 'B / 数列 / Σ（応用編）＆群数列',
            vid: 'ObP-ScRrKvTY',
            kuids: [ '14_04_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '4',
            qn: '群数列（やや応用）',
            qnum1: '14',
            qnum2: '0',
            quid: '14_04_14_00',
            hn: 'B / 数列 / Σ（応用編）＆群数列',
            vid: '-6aRh31lNCeN',
            kuids: [ '14_04_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '階差数列＆AnとSnの関係',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: '階差数列の基本',
            qnum1: '15',
            qnum2: '1',
            quid: '14_05_15_01',
            hn: 'B / 数列 / 階差数列＆AnとSnの関係',
            vid: 'J4CYe3nFEtFk',
            kuids: [ '14_05_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: '階差数列の基本',
            qnum1: '15',
            qnum2: '2',
            quid: '14_05_15_02',
            hn: 'B / 数列 / 階差数列＆AnとSnの関係',
            vid: '9S_SzZ4htwP0',
            kuids: [ '14_05_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '3',
            qn: '第２階差',
            qnum1: '16',
            qnum2: '0',
            quid: '14_05_16_00',
            hn: 'B / 数列 / 階差数列＆AnとSnの関係',
            vid: 'zzM3DIONecW6',
            kuids: [ '14_05_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '4',
            qn: '分母の階差',
            qnum1: '17',
            qnum2: '0',
            quid: '14_05_17_00',
            hn: 'B / 数列 / 階差数列＆AnとSnの関係',
            vid: 'MwrWUcmT_BNk',
            kuids: [ '14_05_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '5',
            qn: 'AnとSnの関係（基本）',
            qnum1: '18',
            qnum2: '1',
            quid: '14_05_18_01',
            hn: 'B / 数列 / 階差数列＆AnとSnの関係',
            vid: 'ip0dgIGjk-LS',
            kuids: [ '14_06_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '6',
            qn: 'AnとSnの関係（基本）',
            qnum1: '18',
            qnum2: '2',
            quid: '14_05_18_02',
            hn: 'B / 数列 / 階差数列＆AnとSnの関係',
            vid: 'vmq_JyO7y2i8',
            kuids: [ '14_06_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '7',
            qn: 'AnとSnの関係（応用）',
            qnum1: '19',
            qnum2: '0',
            quid: '14_05_19_00',
            hn: 'B / 数列 / 階差数列＆AnとSnの関係',
            vid: 'DMBJh3hnF7qp',
            kuids: [ '14_06_01', '14_06_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '漸化式',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '1',
            quid: '14_06_20_01',
            hn: 'B / 数列 / 漸化式',
            vid: 'lHr1F0A0Zbit',
            kuids: [ '14_07_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '2',
            quid: '14_06_20_02',
            hn: 'B / 数列 / 漸化式',
            vid: 'lzGzFTrd-fT2',
            kuids: [ '14_07_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '3',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '3',
            quid: '14_06_20_03',
            hn: 'B / 数列 / 漸化式',
            vid: 'U3oCT9X-9SvJ',
            kuids: [ '14_07_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '4',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '4',
            quid: '14_06_20_04',
            hn: 'B / 数列 / 漸化式',
            vid: 'LvG5S0s3EJO6',
            kuids: [ '14_07_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '5',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '5',
            quid: '14_06_20_05',
            hn: 'B / 数列 / 漸化式',
            vid: '_miIy8oKIjpf',
            kuids: [ '14_07_02' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '6',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '6',
            quid: '14_06_20_06',
            hn: 'B / 数列 / 漸化式',
            vid: '-KwMtmW7DEvN',
            kuids: [ '14_07_03' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '7',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '7',
            quid: '14_06_20_07',
            hn: 'B / 数列 / 漸化式',
            vid: 'MX89okzUZDn3',
            kuids: [ '14_07_03' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '8',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '8',
            quid: '14_06_20_08',
            hn: 'B / 数列 / 漸化式',
            vid: '2cpzbElbZ1qf',
            kuids: [ '14_07_04' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '9',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '9',
            quid: '14_06_20_09',
            hn: 'B / 数列 / 漸化式',
            vid: 'qxK1GniMVBl3',
            kuids: [ '14_07_05' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '10',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '10',
            quid: '14_06_20_10',
            hn: 'B / 数列 / 漸化式',
            vid: 'aBR_stn9L2Pa',
            kuids: [ '14_07_05' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '11',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '11',
            quid: '14_06_20_11',
            hn: 'B / 数列 / 漸化式',
            vid: 'ZvqOmNdu-wH2',
            kuids: [ '14_07_06' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '12',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '12',
            quid: '14_06_20_12',
            hn: 'B / 数列 / 漸化式',
            vid: 'ql1lAW940_w5',
            kuids: [ '14_07_06' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '13',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '13',
            quid: '14_06_20_13',
            hn: 'B / 数列 / 漸化式',
            vid: 'lMs1-uJ16jJm',
            kuids: [ '14_07_07' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '14',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '14',
            quid: '14_06_20_14',
            hn: 'B / 数列 / 漸化式',
            vid: 'WkXWUeZx5SH8',
            kuids: [ '14_07_07' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '15',
            qn: '漸化式の基本練習',
            qnum1: '20',
            qnum2: '15',
            quid: '14_06_20_15',
            hn: 'B / 数列 / 漸化式',
            vid: 'PtYPVJ4ANrZJ',
            kuids: [ '14_07_08' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '数学的帰納法　その１',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: '等式の証明',
            qnum1: '21',
            qnum2: '0',
            quid: '14_07_21_00',
            hn: 'B / 数列 / 数学的帰納法　その１',
            vid: 'tN6TSGUy4mcD',
            kuids: [ '14_08_01' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: '不等式の証明',
            qnum1: '22',
            qnum2: '0',
            quid: '14_07_22_00',
            hn: 'B / 数列 / 数学的帰納法　その１',
            vid: 'h0uckPyA9yuQ',
            kuids: [ '14_08_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '数学的帰納法　その２',
        hn: 'B / 数列',
        questions: [
          {
            uid: '14',
            fid: '2bc',
            qid: '1',
            qn: '倍数証明',
            qnum1: '23',
            qnum2: '0',
            quid: '14_08_23_00',
            hn: 'B / 数列 / 数学的帰納法　その２',
            vid: 'gcwSufXXRqkK',
            kuids: [ '14_08_03' ],
            record: {}
          },
          {
            uid: '14',
            fid: '2bc',
            qid: '2',
            qn: '推定→帰納法',
            qnum1: '24',
            qnum2: '0',
            quid: '14_08_24_00',
            hn: 'B / 数列 / 数学的帰納法　その２',
            vid: 'dcNW4sZOEbLa',
            kuids: [ '14_08_04' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '15',
    un: '確率分布と統計的な推測',
    fid: '2bc',
    hn: 'B',
    prints: [
      {
        pid: '1',
        pn: '＜確率分布＞　　　確率変数の期待値・分散・標準偏差',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '1',
            qn: '確率分布の基本',
            qnum1: '1',
            qnum2: '0',
            quid: '15_01_01_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　確率変数の期待値・分散・標準偏差',
            vid: 'vvtJ7i2M0SbL',
            kuids: [ '15_01_01' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '2',
            qn: '期待値（平均）',
            qnum1: '2',
            qnum2: '0',
            quid: '15_01_02_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　確率変数の期待値・分散・標準偏差',
            vid: 'fGnkp8HhzWcT',
            kuids: [ '15_01_02', '05_03_06' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '3',
            qn: '分散と標準偏差',
            qnum1: '3',
            qnum2: '0',
            quid: '15_01_03_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　確率変数の期待値・分散・標準偏差',
            vid: 'Md06tfqbEbVN',
            kuids: [ '15_01_02', '04_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜確率分布＞　　　確率変数の変換・確率変数の和と期待値',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '4',
            qn: '確率変数と期待値・分散・標準偏差（その１）',
            qnum1: '4',
            qnum2: '0',
            quid: '15_02_04_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　確率変数の変換・確率変数の和と期待値',
            vid: '5alTvBLsCOPO',
            kuids: [ '15_01_03' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '5',
            qn: '確率変数と期待値・分散・標準偏差（その２）',
            qnum1: '5',
            qnum2: '0',
            quid: '15_02_05_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　確率変数の変換・確率変数の和と期待値',
            vid: '4GWjKXSK2cq7',
            kuids: [ '15_01_03' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '6',
            qn: '確率変数の和と期待値',
            qnum1: '6',
            qnum2: '0',
            quid: '15_02_06_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　確率変数の変換・確率変数の和と期待値',
            vid: 'YM-CLgUYkdZg',
            kuids: [ '15_01_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜確率分布＞　　　独立な確率変数',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '7',
            qn: '事象の独立',
            qnum1: '7',
            qnum2: '0',
            quid: '15_03_07_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　独立な確率変数',
            vid: 'n52TK-O9tqRd',
            kuids: [ '15_01_05' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '8',
            qn: '独立な確率変数の積の期待値・和の分散',
            qnum1: '8',
            qnum2: '0',
            quid: '15_03_08_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　独立な確率変数',
            vid: '_NrAMxX6oHi4',
            kuids: [ '15_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜確率分布＞　　　二項分布',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '9',
            qn: '二項分布の基本',
            qnum1: '9',
            qnum2: '0',
            quid: '15_04_09_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　二項分布',
            vid: '08ZEmECoPqSs',
            kuids: [ '15_01_07' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '10',
            qn: '二項分布と確率変数の期待値・分散・標準偏差（その１）',
            qnum1: '10',
            qnum2: '0',
            quid: '15_04_10_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　二項分布',
            vid: '8yGgnV8BIpx8',
            kuids: [ '15_01_07' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '11',
            qn: '二項分布と確率変数の期待値・分散・標準偏差（その２）',
            qnum1: '11',
            qnum2: '0',
            quid: '15_04_11_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　二項分布',
            vid: 'XE2muHf6l4kt',
            kuids: [ '15_01_07' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '＜確率分布＞　　　連続型確率変数と確率密度関数',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '12',
            qn: '確率密度関数',
            qnum1: '12',
            qnum2: '0',
            quid: '15_05_12_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　連続型確率変数と確率密度関数',
            vid: 'prlzpMUyixdX',
            kuids: [ '15_01_08' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '13',
            qn: '連続型確率変数の期待値と分散',
            qnum1: '13',
            qnum2: '0',
            quid: '15_05_13_00',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　連続型確率変数と確率密度関数',
            vid: '1QhDFYaXQGak',
            kuids: [ '15_01_08' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '＜確率分布＞　　　正規分布',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '14',
            qn: '正規分布と標準正規分布（その１）',
            qnum1: '14',
            qnum2: '99',
            quid: '15_06_14_99',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　正規分布',
            vid: 'eiBs6wjr-SCl',
            kuids: [ '15_01_09_F', '15_01_09_S' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '15',
            qn: '正規分布と標準正規分布（その２）',
            qnum1: '15',
            qnum2: '99',
            quid: '15_06_15_99',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　正規分布',
            vid: '9tmTDNKQBPUH',
            kuids: [ '15_01_09_F', '15_01_09_S' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '16',
            qn: '正規分布の応用',
            qnum1: '16',
            qnum2: '99',
            quid: '15_06_16_99',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　正規分布',
            vid: 'gPoIp4dWMrze',
            kuids: [ '15_01_09_F', '15_01_09_S' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '17',
            qn: '二項分布の正規分布による近似',
            qnum1: '17',
            qnum2: '99',
            quid: '15_06_17_99',
            hn: 'B / 確率分布と統計的な推測 / ＜確率分布＞　　　正規分布',
            vid: 'gTBSsccbeH1h',
            kuids: [ '15_01_10' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '＜統計的な推測＞　標本平均とその分布',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '18',
            qn: '母平均と母分散・母標準偏差',
            qnum1: '18',
            qnum2: '0',
            quid: '15_07_18_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　標本平均とその分布',
            vid: 'ZHEUr8a-wVsw',
            kuids: [ '15_02_01' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '19',
            qn: '標本平均の期待値と標準偏差',
            qnum1: '19',
            qnum2: '0',
            quid: '15_07_19_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　標本平均とその分布',
            vid: 'Q2iCEtK4thZL',
            kuids: [ '15_02_02_F', '15_02_02_S' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '20',
            qn: '標本平均の分布と正規分布',
            qnum1: '20',
            qnum2: '99',
            quid: '15_07_20_99',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　標本平均とその分布',
            vid: 'yaYaai6swJWC',
            kuids: [ '15_02_02_F', '15_02_02_S' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '＜統計的な推測＞　標本比率と正規分布・大数の法則',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '21',
            qn: '標本比率と正規分布',
            qnum1: '21',
            qnum2: '99',
            quid: '15_08_21_99',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　標本比率と正規分布・大数の法則',
            vid: 'KVmvRFr2zT5K',
            kuids: [ '15_02_02_F', '15_02_02_S' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '22',
            qn: '相対度数と確率',
            qnum1: '22',
            qnum2: '99',
            quid: '15_08_22_99',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　標本比率と正規分布・大数の法則',
            vid: '2y5M5tEH38jB',
            kuids: [ '15_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '＜統計的な推測＞　推定',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '23',
            qn: '母平均の推定（母標準偏差が与えられる）',
            qnum1: '23',
            qnum2: '0',
            quid: '15_09_23_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　推定',
            vid: 'Q_V305zPeZYA',
            kuids: [ '15_02_04' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '24',
            qn: '母平均の推定（母標準偏差わからず）',
            qnum1: '24',
            qnum2: '0',
            quid: '15_09_24_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　推定',
            vid: 'WOHYTlVKgvJM',
            kuids: [ '15_02_04' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '25',
            qn: '母比率の推定',
            qnum1: '25',
            qnum2: '0',
            quid: '15_09_25_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　推定',
            vid: 'DPp1O480WCye',
            kuids: [ '15_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '＜統計的な推測＞　仮説検定',
        hn: 'B / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '2bc',
            qid: '26',
            qn: '母比率　両側検定',
            qnum1: '26',
            qnum2: '0',
            quid: '15_10_26_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　仮説検定',
            vid: 'u092Y_dn2fna',
            kuids: [ '15_02_05' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '27',
            qn: '母比率　片側検定',
            qnum1: '27',
            qnum2: '0',
            quid: '15_10_27_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　仮説検定',
            vid: 'rUBCJnScWMe3',
            kuids: [ '15_02_05' ],
            record: {}
          },
          {
            uid: '15',
            fid: '2bc',
            qid: '28',
            qn: '母平均の検定',
            qnum1: '28',
            qnum2: '0',
            quid: '15_10_28_00',
            hn: 'B / 確率分布と統計的な推測 / ＜統計的な推測＞　仮説検定',
            vid: 'co05fYgRB9uT',
            kuids: [ '15_02_05' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '16',
    un: 'ベクトル',
    fid: '2bc',
    hn: 'C',
    prints: [
      {
        pid: '1',
        pn: '＜平面＞　ベクトルの基本くん',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '和と差と実数倍（正六角形）',
            qnum1: '1',
            qnum2: '0',
            quid: '16_01_01_00',
            hn: 'C / ベクトル / ＜平面＞　ベクトルの基本くん',
            vid: 'EG9gAWl0jL1Q',
            kuids: [ '16_00_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '成分表示',
            qnum1: '2',
            qnum2: '1',
            quid: '16_01_02_01',
            hn: 'C / ベクトル / ＜平面＞　ベクトルの基本くん',
            vid: 'S0yIWdPrDuRp',
            kuids: [ '16_00_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '成分表示',
            qnum1: '2',
            qnum2: '2',
            quid: '16_01_02_02',
            hn: 'C / ベクトル / ＜平面＞　ベクトルの基本くん',
            vid: 'oCnIrL5tWj4L',
            kuids: [ '16_00_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '一次独立の基本（成分表示）',
            qnum1: '3',
            qnum2: '0',
            quid: '16_01_03_00',
            hn: 'C / ベクトル / ＜平面＞　ベクトルの基本くん',
            vid: 'QVikEIhkDSrD',
            kuids: [ '16_00_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜平面＞　内積系　基本編',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '内積となす角',
            qnum1: '4',
            qnum2: '1',
            quid: '16_02_04_01',
            hn: 'C / ベクトル / ＜平面＞　内積系　基本編',
            vid: 'ZCvmXCqvoPrk',
            kuids: [ '16_01_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '内積となす角',
            qnum1: '4',
            qnum2: '2',
            quid: '16_02_04_02',
            hn: 'C / ベクトル / ＜平面＞　内積系　基本編',
            vid: 'ER4Nx0qt3EmO',
            kuids: [ '16_01_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '大きさの最小（成分なし）',
            qnum1: '5',
            qnum2: '0',
            quid: '16_02_05_00',
            hn: 'C / ベクトル / ＜平面＞　内積系　基本編',
            vid: 'qrZkqYlWLyzq',
            kuids: [ '16_01_03' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '大きさの最小（成分あり）',
            qnum1: '6',
            qnum2: '0',
            quid: '16_02_06_00',
            hn: 'C / ベクトル / ＜平面＞　内積系　基本編',
            vid: '7NmANdsMFIFN',
            kuids: [ '16_01_03', '16_00_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜平面＞　内積系　応用編',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '平行・垂直条件（成分表示）',
            qnum1: '7',
            qnum2: '1',
            quid: '16_03_07_01',
            hn: 'C / ベクトル / ＜平面＞　内積系　応用編',
            vid: 'i01RAC5vXTyr',
            kuids: [ '16_01_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '平行・垂直条件（成分表示）',
            qnum1: '7',
            qnum2: '2',
            quid: '16_03_07_02',
            hn: 'C / ベクトル / ＜平面＞　内積系　応用編',
            vid: 'gbidceKvaPyf',
            kuids: [ '16_01_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '平行・垂直条件（成分表示）',
            qnum1: '7',
            qnum2: '3',
            quid: '16_03_07_03',
            hn: 'C / ベクトル / ＜平面＞　内積系　応用編',
            vid: 'FYPnmDKQ1VlH',
            kuids: [ '16_01_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '大きさと垂直条件（成分なし）',
            qnum1: '8',
            qnum2: '0',
            quid: '16_03_08_00',
            hn: 'C / ベクトル / ＜平面＞　内積系　応用編',
            vid: 'f4bMTDks3-w3',
            kuids: [ '16_01_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '5',
            qn: '三角形の面積（成分なし）',
            qnum1: '9',
            qnum2: '0',
            quid: '16_03_09_00',
            hn: 'C / ベクトル / ＜平面＞　内積系　応用編',
            vid: 'wZ0DO5R4CRc7',
            kuids: [ '16_01_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '6',
            qn: '三角形の面積（成分あり）',
            qnum1: '10',
            qnum2: '0',
            quid: '16_03_10_00',
            hn: 'C / ベクトル / ＜平面＞　内積系　応用編',
            vid: 'ohCWzIMv2CkX',
            kuids: [ '16_01_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '7',
            qn: '内積と最大最小',
            qnum1: '11',
            qnum2: '0',
            quid: '16_03_11_00',
            hn: 'C / ベクトル / ＜平面＞　内積系　応用編',
            vid: 'YlMeI41hFbo2',
            kuids: [ '16_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜平面＞　点の位置・面積比',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '点の位置（三角形）',
            qnum1: '12',
            qnum2: '0',
            quid: '16_04_12_00',
            hn: 'C / ベクトル / ＜平面＞　点の位置・面積比',
            vid: 'W8WnZKsGblTo',
            kuids: [ '16_02_02', '16_02_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '点の位置（平行四辺形）',
            qnum1: '13',
            qnum2: '0',
            quid: '16_04_13_00',
            hn: 'C / ベクトル / ＜平面＞　点の位置・面積比',
            vid: '5h2eQGQejD0s',
            kuids: [ '16_02_02', '16_02_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '面積比　その１',
            qnum1: '14',
            qnum2: '0',
            quid: '16_04_14_00',
            hn: 'C / ベクトル / ＜平面＞　点の位置・面積比',
            vid: 'tHZ3Fur1FwBa',
            kuids: [ '16_02_03' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '面積比　その２',
            qnum1: '15',
            qnum2: '0',
            quid: '16_04_15_00',
            hn: 'C / ベクトル / ＜平面＞　点の位置・面積比',
            vid: 'W0UZoQLJFE9s',
            kuids: [ '16_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '＜平面＞　領域',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '領域図示',
            qnum1: '16',
            qnum2: '1',
            quid: '16_05_16_01',
            hn: 'C / ベクトル / ＜平面＞　領域',
            vid: 'JRPpJ5XPlo1v',
            kuids: [ '16_02_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '領域図示',
            qnum1: '16',
            qnum2: '2',
            quid: '16_05_16_02',
            hn: 'C / ベクトル / ＜平面＞　領域',
            vid: 't5TEh1qglzD5',
            kuids: [ '16_02_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '領域図示',
            qnum1: '16',
            qnum2: '3',
            quid: '16_05_16_03',
            hn: 'C / ベクトル / ＜平面＞　領域',
            vid: 'ODRmlHqICMdA',
            kuids: [ '16_02_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '領域図示',
            qnum1: '16',
            qnum2: '4',
            quid: '16_05_16_04',
            hn: 'C / ベクトル / ＜平面＞　領域',
            vid: 'LqgMIFiKSyTP',
            kuids: [ '16_02_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '5',
            qn: '領域と面積',
            qnum1: '17',
            qnum2: '0',
            quid: '16_05_17_00',
            hn: 'C / ベクトル / ＜平面＞　領域',
            vid: 'obIxqTGd4SdV',
            kuids: [ '16_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '＜平面＞　ベクトル方程式',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '直線の方程式',
            qnum1: '18',
            qnum2: '1',
            quid: '16_06_18_01',
            hn: 'C / ベクトル / ＜平面＞　ベクトル方程式',
            vid: 'asIt4PgwytTp',
            kuids: [ '16_02_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '直線の方程式',
            qnum1: '18',
            qnum2: '2',
            quid: '16_06_18_02',
            hn: 'C / ベクトル / ＜平面＞　ベクトル方程式',
            vid: 'MXI4qNbG8D0Q',
            kuids: [ '16_02_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '円の方程式',
            qnum1: '19',
            qnum2: '0',
            quid: '16_06_19_00',
            hn: 'C / ベクトル / ＜平面＞　ベクトル方程式',
            vid: 'ROVS7S2yriHr',
            kuids: [ '16_02_06' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '円と軌跡',
            qnum1: '20',
            qnum2: '0',
            quid: '16_06_20_00',
            hn: 'C / ベクトル / ＜平面＞　ベクトル方程式',
            vid: 'un2ljY1EOq5J',
            kuids: [ '16_02_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '＜平面＞　内積系と位置ベクトル系の融合',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '大きさの最小',
            qnum1: '21',
            qnum2: '0',
            quid: '16_07_21_00',
            hn: 'C / ベクトル / ＜平面＞　内積系と位置ベクトル系の融合',
            vid: '4qNqIoJhig-R',
            kuids: [ '16_03_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '内心',
            qnum1: '22',
            qnum2: '0',
            quid: '16_07_22_00',
            hn: 'C / ベクトル / ＜平面＞　内積系と位置ベクトル系の融合',
            vid: 'IDch5w8HSbkF',
            kuids: [ '16_03_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '垂心',
            qnum1: '23',
            qnum2: '0',
            quid: '16_07_23_00',
            hn: 'C / ベクトル / ＜平面＞　内積系と位置ベクトル系の融合',
            vid: 'bpG_CxDVhWj5',
            kuids: [ '16_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '＜平面＞　平面ベクトル　重要問題',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '同一直線上（成分なし）',
            qnum1: '24',
            qnum2: '0',
            quid: '16_08_24_00',
            hn: 'C / ベクトル / ＜平面＞　平面ベクトル　重要問題',
            vid: 'VbP513uT1r-C',
            kuids: [ '16_00_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '同一直線上（成分あり）',
            qnum1: '25',
            qnum2: '0',
            quid: '16_08_25_00',
            hn: 'C / ベクトル / ＜平面＞　平面ベクトル　重要問題',
            vid: '4_lbJjfdT8Pu',
            kuids: [ '16_00_01', '16_00_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '外心',
            qnum1: '26',
            qnum2: '0',
            quid: '16_08_26_00',
            hn: 'C / ベクトル / ＜平面＞　平面ベクトル　重要問題',
            vid: 'm9RwZo3iyFMq',
            kuids: [ '16_03_01', '16_01_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '点の位置と領域',
            qnum1: '27',
            qnum2: '0',
            quid: '16_08_27_00',
            hn: 'C / ベクトル / ＜平面＞　平面ベクトル　重要問題',
            vid: 'Sy9rGHz77umJ',
            kuids: [ '16_02_02', '16_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '＜空間＞　内積の基本',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '２点間の距離',
            qnum1: '28',
            qnum2: '0',
            quid: '16_09_28_00',
            hn: 'C / ベクトル / ＜空間＞　内積の基本',
            vid: 'ENLTUg_xGH3n',
            kuids: [ '16_04_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '内積となす角（基本）',
            qnum1: '29',
            qnum2: '0',
            quid: '16_09_29_00',
            hn: 'C / ベクトル / ＜空間＞　内積の基本',
            vid: 'IDNRYWHN8IBh',
            kuids: [ '16_04_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '内積となす角（応用）',
            qnum1: '30',
            qnum2: '0',
            quid: '16_09_30_00',
            hn: 'C / ベクトル / ＜空間＞　内積の基本',
            vid: '-2syREq-amFG',
            kuids: [ '16_04_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: 'なす角と三角形の面積',
            qnum1: '31',
            qnum2: '0',
            quid: '16_09_31_00',
            hn: 'C / ベクトル / ＜空間＞　内積の基本',
            vid: '-eQRw9_nmXQd',
            kuids: [ '16_04_02', '16_04_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '＜空間＞　内積の応用',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '２つのベクトルに垂直なベクトル',
            qnum1: '32',
            qnum2: '0',
            quid: '16_10_32_00',
            hn: 'C / ベクトル / ＜空間＞　内積の応用',
            vid: 'Ol56NAK_4Cxb',
            kuids: [ '16_04_03' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '正射影ベクトルの大きさ',
            qnum1: '33',
            qnum2: '0',
            quid: '16_10_33_00',
            hn: 'C / ベクトル / ＜空間＞　内積の応用',
            vid: 'xnrVmiYMGpI9',
            kuids: [ '16_04_04' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '正射影ベクトルと四面体の体積',
            qnum1: '34',
            qnum2: '0',
            quid: '16_10_34_00',
            hn: 'C / ベクトル / ＜空間＞　内積の応用',
            vid: 'lhP8olhDzf5c',
            kuids: [ '16_04_03', '16_04_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '11',
        pn: '＜空間＞　内積系　その他重要事項',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '大きさの最小',
            qnum1: '35',
            qnum2: '1',
            quid: '16_11_35_01',
            hn: 'C / ベクトル / ＜空間＞　内積系　その他重要事項',
            vid: '7gRcp-nl0CIx',
            kuids: [ '16_04_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '大きさの最小',
            qnum1: '35',
            qnum2: '2',
            quid: '16_11_35_02',
            hn: 'C / ベクトル / ＜空間＞　内積系　その他重要事項',
            vid: '28VmAHrlUzH0',
            kuids: [ '16_04_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '平行条件',
            qnum1: '36',
            qnum2: '0',
            quid: '16_11_36_00',
            hn: 'C / ベクトル / ＜空間＞　内積系　その他重要事項',
            vid: 'm4FIYbceaBUf',
            kuids: [ '16_04_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '同一直線上',
            qnum1: '37',
            qnum2: '0',
            quid: '16_11_37_00',
            hn: 'C / ベクトル / ＜空間＞　内積系　その他重要事項',
            vid: 'kZ1D4XDSRKu3',
            kuids: [ '16_04_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '5',
            qn: '内分・外分',
            qnum1: '38',
            qnum2: '0',
            quid: '16_11_38_00',
            hn: 'C / ベクトル / ＜空間＞　内積系　その他重要事項',
            vid: '1sJ3jKHJhPq3',
            kuids: [ '16_04_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '6',
            qn: '内積と最大最小',
            qnum1: '39',
            qnum2: '0',
            quid: '16_11_39_00',
            hn: 'C / ベクトル / ＜空間＞　内積系　その他重要事項',
            vid: 'rtyfceIhRnYw',
            kuids: [ '16_04_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '12',
        pn: '＜空間＞　点の位置',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '同一平面上（成分なし）',
            qnum1: '40',
            qnum2: '0',
            quid: '16_12_40_00',
            hn: 'C / ベクトル / ＜空間＞　点の位置',
            vid: '4g7MtR-DDe1d',
            kuids: [ '16_05_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '同一平面上（成分あり）',
            qnum1: '41',
            qnum2: '0',
            quid: '16_12_41_00',
            hn: 'C / ベクトル / ＜空間＞　点の位置',
            vid: 'x7-8Q0V8VJ-0',
            kuids: [ '16_05_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '点の位置　その１',
            qnum1: '42',
            qnum2: '0',
            quid: '16_12_42_00',
            hn: 'C / ベクトル / ＜空間＞　点の位置',
            vid: 'Yly5V5KgfQMf',
            kuids: [ '16_05_03', '16_05_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '点の位置　その２',
            qnum1: '43',
            qnum2: '0',
            quid: '16_12_43_00',
            hn: 'C / ベクトル / ＜空間＞　点の位置',
            vid: 'wc_HGbyl5UER',
            kuids: [ '16_05_03', '16_05_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '13',
        pn: '＜空間＞　内積系と位置ベクトル系の融合',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '内積と空間図形（成分なし）',
            qnum1: '44',
            qnum2: '0',
            quid: '16_13_44_00',
            hn: 'C / ベクトル / ＜空間＞　内積系と位置ベクトル系の融合',
            vid: 'JjZV4Ko9s_ag',
            kuids: [ '16_06_01' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '垂線の長さ（成分あり）',
            qnum1: '45',
            qnum2: '0',
            quid: '16_13_45_00',
            hn: 'C / ベクトル / ＜空間＞　内積系と位置ベクトル系の融合',
            vid: 'LQUcfLVKMAjz',
            kuids: [ '16_04_04', '16_05_09', '16_05_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '14',
        pn: '＜空間＞　ベクトル方程式',
        hn: 'C / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '2bc',
            qid: '1',
            qn: '直線と垂直条件',
            qnum1: '46',
            qnum2: '0',
            quid: '16_14_46_00',
            hn: 'C / ベクトル / ＜空間＞　ベクトル方程式',
            vid: 'kVZPatDuj3UV',
            kuids: [ '16_05_04', '16_04_02' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '2',
            qn: '２直線（交わる）',
            qnum1: '47',
            qnum2: '0',
            quid: '16_14_47_00',
            hn: 'C / ベクトル / ＜空間＞　ベクトル方程式',
            vid: 'JfPK1beJ7d_O',
            kuids: [ '16_05_04', '16_05_07' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '3',
            qn: '２直線（ねじれの位置）',
            qnum1: '48',
            qnum2: '0',
            quid: '16_14_48_00',
            hn: 'C / ベクトル / ＜空間＞　ベクトル方程式',
            vid: 'GVU2nUSIr17O',
            kuids: [ '16_05_04', '16_05_07' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '4',
            qn: '球面の半径',
            qnum1: '49',
            qnum2: '0',
            quid: '16_14_49_00',
            hn: 'C / ベクトル / ＜空間＞　ベクトル方程式',
            vid: 'EY7-N4g0x14w',
            kuids: [ '16_05_05' ],
            record: {}
          },
          {
            uid: '16',
            fid: '2bc',
            qid: '5',
            qn: '直線と球面',
            qnum1: '50',
            qnum2: '0',
            quid: '16_14_50_00',
            hn: 'C / ベクトル / ＜空間＞　ベクトル方程式',
            vid: 'D_N6L2Suq-KO',
            kuids: [ '16_05_08' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '17',
    un: '平面上の曲線と複素数平面',
    fid: '2bc',
    hn: 'C',
    prints: [
      {
        pid: '1',
        pn: '＜平面上の曲線＞　放物線',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '放物線の基本',
            qnum1: '1',
            qnum2: '1',
            quid: '17_01_01_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: 'o6_cA3MV0Dwe',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '放物線の基本',
            qnum1: '1',
            qnum2: '2',
            quid: '17_01_01_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: 'V0a_RFWUVskH',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '放物線の基本',
            qnum1: '1',
            qnum2: '3',
            quid: '17_01_01_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: 'b_TmI_1lE5tG',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '放物線の基本',
            qnum1: '1',
            qnum2: '4',
            quid: '17_01_01_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: '-Un7mAQE-lJG',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: '放物線の基本',
            qnum1: '1',
            qnum2: '5',
            quid: '17_01_01_05',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: 'WEErUZkUdjks',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '6',
            qn: '放物線となる軌跡',
            qnum1: '2',
            qnum2: '1',
            quid: '17_01_02_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: 'ez5kHEhgwR42',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '7',
            qn: '放物線となる軌跡',
            qnum1: '2',
            qnum2: '2',
            quid: '17_01_02_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: 'dZLwxcMvh-ia',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '8',
            qn: '放物線となる軌跡',
            qnum1: '2',
            qnum2: '3',
            quid: '17_01_02_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: '6LpOX6hUeS2T',
            kuids: [ '17_01_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '9',
            qn: '頂点・準線など',
            qnum1: '3',
            qnum2: '0',
            quid: '17_01_03_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: 'EP_PTAKoZbSG',
            kuids: [ '17_01_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜平面上の曲線＞　楕円（基本）',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '楕円の基本',
            qnum1: '4',
            qnum2: '1',
            quid: '17_02_04_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（基本）',
            vid: 'sV3D00U6AOem',
            kuids: [ '17_01_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '楕円の基本',
            qnum1: '4',
            qnum2: '2',
            quid: '17_02_04_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（基本）',
            vid: 'X-_PscVjnSuf',
            kuids: [ '17_01_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '楕円の基本',
            qnum1: '4',
            qnum2: '3',
            quid: '17_02_04_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（基本）',
            vid: 'NbJjqTkn9GPp',
            kuids: [ '17_01_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '焦点など',
            qnum1: '5',
            qnum2: '0',
            quid: '17_02_05_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（基本）',
            vid: 'pDDM5HP8xA4q',
            kuids: [ '17_01_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: '拡大・縮小',
            qnum1: '6',
            qnum2: '0',
            quid: '17_02_06_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（基本）',
            vid: 'yEsTyIa-Ksbp',
            kuids: [ '17_01_02', '17_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜平面上の曲線＞　楕円（応用）',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '楕円となる軌跡',
            qnum1: '7',
            qnum2: '0',
            quid: '17_03_07_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（応用）',
            vid: 'rP36yhZp887n',
            kuids: [ '17_01_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: 'パラメータ　その１',
            qnum1: '8',
            qnum2: '0',
            quid: '17_03_08_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（応用）',
            vid: 'EFNfxH8C1v7O',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: 'パラメータ　その２',
            qnum1: '9',
            qnum2: '0',
            quid: '17_03_09_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円（応用）',
            vid: 'hy6Zu4md5KrB',
            kuids: [ '17_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜平面上の曲線＞　双曲線',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '双曲線の基本',
            qnum1: '10',
            qnum2: '1',
            quid: '17_04_10_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線',
            vid: '5Tkfcss9kvzM',
            kuids: [ '17_01_03' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '双曲線の基本',
            qnum1: '10',
            qnum2: '2',
            quid: '17_04_10_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線',
            vid: 'eQOgKL4YOLWM',
            kuids: [ '17_01_03' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '双曲線の基本',
            qnum1: '10',
            qnum2: '3',
            quid: '17_04_10_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線',
            vid: '_TSub_qR-c2W',
            kuids: [ '17_01_03' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '双曲線の基本',
            qnum1: '10',
            qnum2: '4',
            quid: '17_04_10_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線',
            vid: '-4Q5kS-mK2Xs',
            kuids: [ '17_01_03' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: '双曲線となる軌跡',
            qnum1: '11',
            qnum2: '0',
            quid: '17_04_11_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線',
            vid: '3oZKSLn2fO2r',
            kuids: [ '17_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '＜平面上の曲線＞　２次曲線と直線',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '接線の基本',
            qnum1: '12',
            qnum2: '1',
            quid: '17_05_12_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と直線',
            vid: 'XmUz37BLcRZv',
            kuids: [ '17_01_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '接線の基本',
            qnum1: '12',
            qnum2: '2',
            quid: '17_05_12_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と直線',
            vid: 'Z3iA71EXabMn',
            kuids: [ '17_01_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '接線の基本',
            qnum1: '12',
            qnum2: '3',
            quid: '17_05_12_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と直線',
            vid: 'f0iPlhX1meGS',
            kuids: [ '17_01_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '接点わからず',
            qnum1: '13',
            qnum2: '1',
            quid: '17_05_13_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と直線',
            vid: 'CQDck8R0Uvok',
            kuids: [ '17_01_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: '接点わからず',
            qnum1: '13',
            qnum2: '2',
            quid: '17_05_13_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と直線',
            vid: 'qSk-Li-axmsa',
            kuids: [ '17_01_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '6',
            qn: '接点わからず',
            qnum1: '13',
            qnum2: '3',
            quid: '17_05_13_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と直線',
            vid: 'UALS5gQ5gMnn',
            kuids: [ '17_01_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '7',
            qn: '中点の軌跡',
            qnum1: '14',
            qnum2: '0',
            quid: '17_05_14_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と直線',
            vid: '1sUffl8ODi3o',
            kuids: [ '17_01_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '＜平面上の曲線＞　２次曲線と離心率',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '離心率',
            qnum1: '15',
            qnum2: '0',
            quid: '17_06_15_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　２次曲線と離心率',
            vid: '1Dd8oCxHz5Tj',
            kuids: [ '17_01_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '＜平面上の曲線＞　媒介変数表示',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '媒介変数表示→直交表示',
            qnum1: '16',
            qnum2: '1',
            quid: '17_07_16_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: '6lNkSkhocqk9',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '媒介変数表示→直交表示',
            qnum1: '16',
            qnum2: '2',
            quid: '17_07_16_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'YPfZhevAHk3r',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '媒介変数表示→直交表示',
            qnum1: '16',
            qnum2: '3',
            quid: '17_07_16_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'ZCYDE8whJCdq',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '媒介変数表示→直交表示',
            qnum1: '16',
            qnum2: '4',
            quid: '17_07_16_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'mxJWvlG3vBrK',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: '媒介変数表示→直交表示',
            qnum1: '16',
            qnum2: '5',
            quid: '17_07_16_05',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'cympyuCEMUNk',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '6',
            qn: '媒介変数表示→直交表示',
            qnum1: '16',
            qnum2: '6',
            quid: '17_07_16_06',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'IJaBitBd7vii',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '7',
            qn: '直交表示→媒介変数表示',
            qnum1: '17',
            qnum2: '1',
            quid: '17_07_17_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'ZqAxnQCp2t78',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '8',
            qn: '直交表示→媒介変数表示',
            qnum1: '17',
            qnum2: '2',
            quid: '17_07_17_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'nBwDw3COl7cM',
            kuids: [ '17_02_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '9',
            qn: '直交表示→媒介変数表示',
            qnum1: '17',
            qnum2: '3',
            quid: '17_07_17_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示',
            vid: 'xscn2UY5eKmP',
            kuids: [ '17_02_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '＜平面上の曲線＞　極座標と極方程式',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '２点間の距離',
            qnum1: '18',
            qnum2: '0',
            quid: '17_08_18_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: 'GdHVy2XLpDxc',
            kuids: [ '17_02_03' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '極方程式→直交座標に関する方程式',
            qnum1: '19',
            qnum2: '1',
            quid: '17_08_19_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: 'JGoQ1UN1nDeg',
            kuids: [ '17_02_05' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '極方程式→直交座標に関する方程式',
            qnum1: '19',
            qnum2: '2',
            quid: '17_08_19_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: '4RZQIn8ntB-7',
            kuids: [ '17_02_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '極方程式→直交座標に関する方程式',
            qnum1: '19',
            qnum2: '3',
            quid: '17_08_19_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: '5jFpgiLiwykD',
            kuids: [ '17_02_04' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: '極方程式→直交座標に関する方程式',
            qnum1: '19',
            qnum2: '4',
            quid: '17_08_19_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: '0rT3wUSs962w',
            kuids: [ '17_02_05' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '6',
            qn: '極方程式→直交座標に関する方程式',
            qnum1: '19',
            qnum2: '5',
            quid: '17_08_19_05',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: 'RCROI8kCLMQZ',
            kuids: [ '17_02_06' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '7',
            qn: '極方程式→直交座標に関する方程式',
            qnum1: '19',
            qnum2: '6',
            quid: '17_08_19_06',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: '8nJpZugLsQoU',
            kuids: [ '17_02_06' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '8',
            qn: '極方程式→直交座標に関する方程式',
            qnum1: '19',
            qnum2: '7',
            quid: '17_08_19_07',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: '2dR90A4WCTec',
            kuids: [ '17_02_06' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '9',
            qn: '極方程式を作る',
            qnum1: '20',
            qnum2: '1',
            quid: '17_08_20_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: 'YmnnuxPt14Og',
            kuids: [ '17_02_05' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '10',
            qn: '極方程式を作る',
            qnum1: '20',
            qnum2: '2',
            quid: '17_08_20_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: 'mrpkcmMVClyx',
            kuids: [ '17_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '11',
        pn: '＜複素数平面＞　極形式の基本',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '共役複素数',
            qnum1: '1',
            qnum2: '0',
            quid: '17_11_01_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式の基本',
            vid: 'NQ7J5hxQSV9N',
            kuids: [ '17_03_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '絶対値と共役複素数の計算',
            qnum1: '2',
            qnum2: '0',
            quid: '17_11_02_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式の基本',
            vid: 'p7AD9DzFw5oL',
            kuids: [ '17_04_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '極形式を作る',
            qnum1: '3',
            qnum2: '0',
            quid: '17_11_03_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式の基本',
            vid: 'yWmf4TDvmGGU',
            kuids: [ '17_04_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '複素数の絶対値',
            qnum1: '4',
            qnum2: '0',
            quid: '17_11_04_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式の基本',
            vid: 'J7hHV8ken6At',
            kuids: [ '17_04_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: '倍角利用',
            qnum1: '5',
            qnum2: '0',
            quid: '17_11_05_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式の基本',
            vid: 'At42brYrp5DK',
            kuids: [ '17_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '12',
        pn: '＜複素数平面＞　ド・モアブルの定理',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: 'かけ算・わり算',
            qnum1: '6',
            qnum2: '0',
            quid: '17_12_06_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　ド・モアブルの定理',
            vid: '0F6Nz2F50-b9',
            kuids: [ '17_04_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '加法定理',
            qnum1: '7',
            qnum2: '0',
            quid: '17_12_07_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　ド・モアブルの定理',
            vid: 'xFYPZHIAe4UT',
            kuids: [ '17_04_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: 'ド・モアブルの定理',
            qnum1: '8',
            qnum2: '0',
            quid: '17_12_08_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　ド・モアブルの定理',
            vid: 'hL-nF14_t6D9',
            kuids: [ '17_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '13',
        pn: '＜複素数平面＞　極形式　頻出題',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '極形式の応用題',
            qnum1: '9',
            qnum2: '0',
            quid: '17_13_09_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式　頻出題',
            vid: 'XBri35Vn0h-8',
            kuids: [ '17_04_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '方程式',
            qnum1: '10',
            qnum2: '1',
            quid: '17_13_10_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式　頻出題',
            vid: 'sBpQXgwkGcPR',
            kuids: [ '17_04_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '方程式',
            qnum1: '10',
            qnum2: '2',
            quid: '17_13_10_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式　頻出題',
            vid: 'Lh30gXEVjRnn',
            kuids: [ '17_04_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: 'z+1/z',
            qnum1: '11',
            qnum2: '0',
            quid: '17_13_11_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式　頻出題',
            vid: '7iDtCbkoyEns',
            kuids: [ '17_04_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '14',
        pn: '＜複素数平面＞　極形式　５次・７次',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '5次',
            qnum1: '12',
            qnum2: '0',
            quid: '17_14_12_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式　５次・７次',
            vid: 'DISSdT6ypzOL',
            kuids: [ '17_04_03' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '7次',
            qnum1: '13',
            qnum2: '0',
            quid: '17_14_13_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　極形式　５次・７次',
            vid: 'iqtceXDawvKi',
            kuids: [ '17_04_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '15',
        pn: '＜複素数平面＞　三角形（原点を含む）',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '原点を含む（基本）',
            qnum1: '14',
            qnum2: '1',
            quid: '17_15_14_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形（原点を含む）',
            vid: 'qNPUzeTJ8uSx',
            kuids: [ '17_05_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '原点を含む（基本）',
            qnum1: '14',
            qnum2: '2',
            quid: '17_15_14_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形（原点を含む）',
            vid: 'CbvZKAc3wueX',
            kuids: [ '17_05_01' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '原点を含む（応用）',
            qnum1: '15',
            qnum2: '0',
            quid: '17_15_15_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形（原点を含む）',
            vid: 'rbRwTWjPZIsw',
            kuids: [ '17_05_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '16',
        pn: '＜複素数平面＞　三角形（原点を含まない）',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '原点を含まない',
            qnum1: '16',
            qnum2: '0',
            quid: '17_16_16_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形（原点を含まない）',
            vid: '7bYvuOEbCZrG',
            kuids: [ '17_05_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '内分・外分',
            qnum1: '17',
            qnum2: '0',
            quid: '17_16_17_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形（原点を含まない）',
            vid: 'a5wk4Jt9HEHy',
            kuids: [ '17_07_03' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: '平行・垂直条件',
            qnum1: '18',
            qnum2: '0',
            quid: '17_16_18_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形（原点を含まない）',
            vid: 'Eknd8R5YgnXS',
            kuids: [ '17_05_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: '三角形の形状',
            qnum1: '19',
            qnum2: '0',
            quid: '17_16_19_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形（原点を含まない）',
            vid: 'XxYNaePhvhJR',
            kuids: [ '17_05_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '17',
        pn: '＜複素数平面＞　図形と方程式',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '絶対値・偏角の範囲',
            qnum1: '20',
            qnum2: '0',
            quid: '17_17_20_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形と方程式',
            vid: 'xHH3PzblQyPp',
            kuids: [ '17_06_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: 'ベクトル方程式',
            qnum1: '21',
            qnum2: '1',
            quid: '17_17_21_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形と方程式',
            vid: 'RNyijcGIs_aH',
            kuids: [ '17_06_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '3',
            qn: 'ベクトル方程式',
            qnum1: '21',
            qnum2: '2',
            quid: '17_17_21_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形と方程式',
            vid: 'JSZk6iZ6LNwV',
            kuids: [ '17_06_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '4',
            qn: 'ベクトル方程式',
            qnum1: '21',
            qnum2: '3',
            quid: '17_17_21_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形と方程式',
            vid: 'oppWHM4H4FXt',
            kuids: [ '17_06_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '5',
            qn: 'ベクトル方程式',
            qnum1: '21',
            qnum2: '4',
            quid: '17_17_21_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形と方程式',
            vid: 'PgRaMrkrAfP5',
            kuids: [ '17_06_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '6',
            qn: '軌跡（動点利用）',
            qnum1: '22',
            qnum2: '0',
            quid: '17_17_22_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形と方程式',
            vid: 'BEJhhe78OaRN',
            kuids: [ '17_06_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '18',
        pn: '＜複素数平面＞　図形の応用',
        hn: 'C / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '2bc',
            qid: '1',
            qn: '軌跡（実数・純虚数条件）',
            qnum1: '23',
            qnum2: '0',
            quid: '17_18_23_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形の応用',
            vid: 'PwMJu_-rtElY',
            kuids: [ '17_06_02' ],
            record: {}
          },
          {
            uid: '17',
            fid: '2bc',
            qid: '2',
            qn: '総合問題',
            qnum1: '24',
            qnum2: '0',
            quid: '17_18_24_00',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞　図形の応用',
            vid: 'kxp3N8FeKXbU',
            kuids: [ '17_06_02' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '18',
    un: '極限',
    fid: '3',
    hn: 'Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '一般項の極限　その１',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '1',
            quid: '18_01_01_01',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'b1kn70AUuyUs',
            kuids: [ '18_01_02' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '2',
            quid: '18_01_01_02',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'NxdQHjJs9XAG',
            kuids: [ '18_01_02' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '3',
            quid: '18_01_01_03',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: '3ohyZsNnzHFK',
            kuids: [ '18_01_02' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '4',
            quid: '18_01_01_04',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'nEXZXmQ5kLPx',
            kuids: [ '18_01_02' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '5',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '5',
            quid: '18_01_01_05',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: 't46-QM2wyfyT',
            kuids: [ '18_01_03' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '6',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '6',
            quid: '18_01_01_06',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'DaGJneu5eESf',
            kuids: [ '18_01_03' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '7',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '7',
            quid: '18_01_01_07',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'J96TZDA0iAuo',
            kuids: [ '18_01_03' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '8',
            qn: '∞/∞、∞-∞の極限',
            qnum1: '1',
            qnum2: '8',
            quid: '18_01_01_08',
            hn: 'Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'IMOFXGeQBv29',
            kuids: [ '18_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '一般項の極限　その２',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: 'rのn乗の極限',
            qnum1: '2',
            qnum2: '1',
            quid: '18_02_02_01',
            hn: 'Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'badP7p8aBhc8',
            kuids: [ '18_01_04' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: 'rのn乗の極限',
            qnum1: '2',
            qnum2: '2',
            quid: '18_02_02_02',
            hn: 'Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'hNFy7wV0EVhF',
            kuids: [ '18_01_04' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: 'rのn乗の極限',
            qnum1: '2',
            qnum2: '3',
            quid: '18_02_02_03',
            hn: 'Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'JbXJ3DbMDVt5',
            kuids: [ '18_01_05' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: 'rのn乗の極限',
            qnum1: '2',
            qnum2: '4',
            quid: '18_02_02_04',
            hn: 'Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'C_NeMHHYu1Kd',
            kuids: [ '18_01_05' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '5',
            qn: 'rのn乗の極限',
            qnum1: '2',
            qnum2: '5',
            quid: '18_02_02_05',
            hn: 'Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'lEn5BI3Me0bW',
            kuids: [ '18_01_04', '18_01_05' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '6',
            qn: '等比数列の一般項の極限',
            qnum1: '3',
            qnum2: '1',
            quid: '18_02_03_01',
            hn: 'Ⅲ / 極限 / 一般項の極限　その２',
            vid: '6JzF4lWlkQtt',
            kuids: [ '18_01_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '7',
            qn: '等比数列の一般項の極限',
            qnum1: '3',
            qnum2: '2',
            quid: '18_02_03_02',
            hn: 'Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'NGji9j7LmOhb',
            kuids: [ '18_01_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '一般項の極限　その３',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '漸化式→解いてlim',
            qnum1: '4',
            qnum2: '1',
            quid: '18_03_04_01',
            hn: 'Ⅲ / 極限 / 一般項の極限　その３',
            vid: 'g3RcwfbpNPf3',
            kuids: [ '18_01_07' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '漸化式→解いてlim',
            qnum1: '4',
            qnum2: '2',
            quid: '18_03_04_02',
            hn: 'Ⅲ / 極限 / 一般項の極限　その３',
            vid: 'HZmMbeABq9Y5',
            kuids: [ '18_01_07' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '漸化式→解いてlim',
            qnum1: '4',
            qnum2: '3',
            quid: '18_03_04_03',
            hn: 'Ⅲ / 極限 / 一般項の極限　その３',
            vid: 'NC-ogOcyTRLI',
            kuids: [ '18_01_07' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: 'はさみうちの原理',
            qnum1: '5',
            qnum2: '0',
            quid: '18_03_05_00',
            hn: 'Ⅲ / 極限 / 一般項の極限　その３',
            vid: 'EkQSMOxg31wk',
            kuids: [ '18_01_08' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '和の極限',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '無限級数の収束・発散',
            qnum1: '6',
            qnum2: '1',
            quid: '18_04_06_01',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: '5J5lHaXTY8lU',
            kuids: [ '18_01_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '無限級数の収束・発散',
            qnum1: '6',
            qnum2: '2',
            quid: '18_04_06_02',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: 'LuScdvytFXm0',
            kuids: [ '18_01_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '無限級数の収束・発散',
            qnum1: '6',
            qnum2: '3',
            quid: '18_04_06_03',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: '-iHT8CKOl_2_',
            kuids: [ '18_01_13' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: '無限級数の収束・発散',
            qnum1: '6',
            qnum2: '4',
            quid: '18_04_06_04',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: '3BoIQs6HmmIr',
            kuids: [ '18_01_13' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '5',
            qn: '無限級数の収束・発散',
            qnum1: '6',
            qnum2: '5',
            quid: '18_04_06_05',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: 'ls6zfy5edmpo',
            kuids: [ '18_01_13' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '6',
            qn: '無限等比級数の収束・発散',
            qnum1: '7',
            qnum2: '1',
            quid: '18_04_07_01',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: 'Rb0PrHz5oOrW',
            kuids: [ '18_01_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '7',
            qn: '無限等比級数の収束・発散',
            qnum1: '7',
            qnum2: '2',
            quid: '18_04_07_02',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: '7D9JDUIlF8JG',
            kuids: [ '18_01_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '8',
            qn: '無限等比級数の収束・発散',
            qnum1: '7',
            qnum2: '3',
            quid: '18_04_07_03',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: 'WPyLAOr-eHv4',
            kuids: [ '18_01_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '9',
            qn: '循環小数',
            qnum1: '8',
            qnum2: '1',
            quid: '18_04_08_01',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: 'ri7ABUOsMunD',
            kuids: [ '18_01_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '10',
            qn: '循環小数',
            qnum1: '8',
            qnum2: '2',
            quid: '18_04_08_02',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: 'erTAE3JE3G6s',
            kuids: [ '18_01_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '11',
            qn: '無限等比級数の収束条件',
            qnum1: '9',
            qnum2: '0',
            quid: '18_04_09_00',
            hn: 'Ⅲ / 極限 / 和の極限',
            vid: 'H0mwoJPbZ877',
            kuids: [ '18_01_10' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '分数関数・無理関数',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '分数関数の決定　その１',
            qnum1: '10',
            qnum2: '0',
            quid: '18_05_10_00',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数',
            vid: 'w-W_d8tVW_Y8',
            kuids: [ '18_02_01' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '分数関数の決定　その２',
            qnum1: '11',
            qnum2: '0',
            quid: '18_05_11_00',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数',
            vid: 'LZDzBAa6MhOQ',
            kuids: [ '18_02_01' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '分数の方程式・不等式',
            qnum1: '12',
            qnum2: '0',
            quid: '18_05_12_00',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数',
            vid: 'qVpJUI3cIkDp',
            kuids: [ '18_02_01' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: '無理不等式',
            qnum1: '13',
            qnum2: '0',
            quid: '18_05_13_00',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数',
            vid: 'EUK1NAm2rXPB',
            kuids: [ '18_02_02' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '5',
            qn: '無理方程式と実数解の個数',
            qnum1: '14',
            qnum2: '0',
            quid: '18_05_14_00',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数',
            vid: '42ozEahIvE-e',
            kuids: [ '18_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '逆関数・合成関数',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '逆関数を求める',
            qnum1: '15',
            qnum2: '0',
            quid: '18_06_15_00',
            hn: 'Ⅲ / 極限 / 逆関数・合成関数',
            vid: 'RsFbYhaK9ZHI',
            kuids: [ '18_02_03' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '係数決定（逆関数）',
            qnum1: '16',
            qnum2: '0',
            quid: '18_06_16_00',
            hn: 'Ⅲ / 極限 / 逆関数・合成関数',
            vid: '4ik3dQSFMTk3',
            kuids: [ '18_02_03' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '係数決定（合成関数・逆関数）',
            qnum1: '17',
            qnum2: '0',
            quid: '18_06_17_00',
            hn: 'Ⅲ / 極限 / 逆関数・合成関数',
            vid: 'UBYZp7rIyI_h',
            kuids: [ '18_02_03', '18_02_04' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: '係数決定（合成関数）',
            qnum1: '18',
            qnum2: '0',
            quid: '18_06_18_00',
            hn: 'Ⅲ / 極限 / 逆関数・合成関数',
            vid: 'QosJicaWzBvc',
            kuids: [ '18_02_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '分数関数・無理関数の極限',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '分数&無理関数の極限（基本）',
            qnum1: '19',
            qnum2: '1',
            quid: '18_07_19_01',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'vTUVtZgICAtU',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '分数&無理関数の極限（基本）',
            qnum1: '19',
            qnum2: '2',
            quid: '18_07_19_02',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'vT1znD8q5WoX',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '分数&無理関数の極限（基本）',
            qnum1: '19',
            qnum2: '3',
            quid: '18_07_19_03',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'LgdIOYj_ENZ3',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: '分数&無理関数の極限（基本）',
            qnum1: '19',
            qnum2: '4',
            quid: '18_07_19_04',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'tV_KkJUXiFbN',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '5',
            qn: '分数&無理関数の極限（基本）',
            qnum1: '19',
            qnum2: '5',
            quid: '18_07_19_05',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'lPnYTgWMJskn',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '6',
            qn: '分数&無理関数の極限（基本）',
            qnum1: '19',
            qnum2: '6',
            quid: '18_07_19_06',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'mMi5b95RCQWO',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '7',
            qn: '分数&無理関数の極限（基本）',
            qnum1: '19',
            qnum2: '7',
            quid: '18_07_19_07',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'bB2fNFjnC5hH',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '8',
            qn: '分数&無理関数の極限（係数決定）',
            qnum1: '20',
            qnum2: '1',
            quid: '18_07_20_01',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: '9S9_lLFxYM8o',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '9',
            qn: '分数&無理関数の極限（係数決定）',
            qnum1: '20',
            qnum2: '2',
            quid: '18_07_20_02',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'hqLTE9vCXp1J',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '10',
            qn: '分数&無理関数の極限（係数決定）',
            qnum1: '20',
            qnum2: '3',
            quid: '18_07_20_03',
            hn: 'Ⅲ / 極限 / 分数関数・無理関数の極限',
            vid: 'wHr2VRDMyz7x',
            kuids: [ '18_02_05', '18_02_06' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '三角&指数・対数関数の極限',
        hn: 'Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '三角関数の極限（基本）',
            qnum1: '21',
            qnum2: '1',
            quid: '18_08_21_01',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'yVDaUJu3GIip',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '三角関数の極限（基本）',
            qnum1: '21',
            qnum2: '2',
            quid: '18_08_21_02',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'er_35VwUutAY',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '三角関数の極限（基本）',
            qnum1: '21',
            qnum2: '3',
            quid: '18_08_21_03',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'Jp599rztfjeo',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: '三角関数の極限（基本）',
            qnum1: '21',
            qnum2: '4',
            quid: '18_08_21_04',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'Sr7PAmvz2MHx',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '5',
            qn: '三角関数の極限（基本）',
            qnum1: '21',
            qnum2: '5',
            quid: '18_08_21_05',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'G-65wQBzRw-B',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '6',
            qn: '三角関数の極限（係数決定）',
            qnum1: '22',
            qnum2: '0',
            quid: '18_08_22_00',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'B30cnXwFVqhI',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '7',
            qn: '三角関数と数列の極限',
            qnum1: '23',
            qnum2: '0',
            quid: '18_08_23_00',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'pD9Rg22wZwTz',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '8',
            qn: '指数対数関数の極限',
            qnum1: '24',
            qnum2: '1',
            quid: '18_08_24_01',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'Vpq7UWnte_kU',
            kuids: [ '18_02_07', '18_02_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '9',
            qn: '指数対数関数の極限',
            qnum1: '24',
            qnum2: '2',
            quid: '18_08_24_02',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'Y6ZfMpWkKCIe',
            kuids: [ '18_02_07', '18_02_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '10',
            qn: '指数対数関数の極限',
            qnum1: '24',
            qnum2: '3',
            quid: '18_08_24_03',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: '3tz_WvTyOz8T',
            kuids: [ '18_02_07', '18_02_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '11',
            qn: '指数対数関数の極限',
            qnum1: '24',
            qnum2: '4',
            quid: '18_08_24_04',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: '30Xw2ZnoL4QH',
            kuids: [ '18_02_07', '18_02_10' ],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '12',
            qn: '指数対数関数の極限',
            qnum1: '24',
            qnum2: '5',
            quid: '18_08_24_05',
            hn: 'Ⅲ / 極限 / 三角&指数・対数関数の極限',
            vid: 'iGyaxN9oQS-L',
            kuids: [ '18_02_07', '18_02_10' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '19',
    un: '微分',
    fid: '3',
    hn: 'Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '微分計算（基本編）',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '1',
            quid: '19_01_01_01',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'w2qH2QZnP59k',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '2',
            quid: '19_01_01_02',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: '9FVMDGIrcQoZ',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '3',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '3',
            quid: '19_01_01_03',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'LnkJw2ZJt9gL',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '4',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '4',
            quid: '19_01_01_04',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'FoTJ7vjtU0k7',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '5',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '5',
            quid: '19_01_01_05',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: '8XwO-Crs_8MV',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '6',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '6',
            quid: '19_01_01_06',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'mdsapFKJxRoI',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '7',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '7',
            quid: '19_01_01_07',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: '9N2_oV9TSIXz',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '8',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '8',
            quid: '19_01_01_08',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'kJOkMQhhtzOJ',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '9',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '9',
            quid: '19_01_01_09',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'Jt3E4F5NVg7d',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '10',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '10',
            quid: '19_01_01_10',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'hILZFEw2Fp3h',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '11',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '11',
            quid: '19_01_01_11',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: '2f64GSJzF4b6',
            kuids: [ '19_01_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '12',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '12',
            quid: '19_01_01_12',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'CBRrw2GUNT3J',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '13',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '13',
            quid: '19_01_01_13',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: '8tRSoL_sqFwI',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '14',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '14',
            quid: '19_01_01_14',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'qBRhVP21O5la',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '15',
            qn: '微分計算（y=f(x)型）',
            qnum1: '1',
            qnum2: '15',
            quid: '19_01_01_15',
            hn: 'Ⅲ / 微分 / 微分計算（基本編）',
            vid: 'xZPZcfSAwf1f',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '微分計算（応用編）',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '微分計算（陰関数型・媒介変数表示型）',
            qnum1: '2',
            qnum2: '1',
            quid: '19_02_02_01',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'NiC_ocxTRPhY',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '微分計算（陰関数型・媒介変数表示型）',
            qnum1: '2',
            qnum2: '2',
            quid: '19_02_02_02',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'PQiEFy1EUdry',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '3',
            qn: '微分計算（陰関数型・媒介変数表示型）',
            qnum1: '2',
            qnum2: '3',
            quid: '19_02_02_03',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'fC0kA9b2zhyn',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '4',
            qn: '微分計算（陰関数型・媒介変数表示型）',
            qnum1: '2',
            qnum2: '4',
            quid: '19_02_02_04',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'fW1DyV1cT3jG',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '5',
            qn: '微分計算（陰関数型・媒介変数表示型）',
            qnum1: '2',
            qnum2: '5',
            quid: '19_02_02_05',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'mlznGJj4z9De',
            kuids: [ '19_01_02', '19_01_03' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '6',
            qn: '高次導関数',
            qnum1: '3',
            qnum2: '1',
            quid: '19_02_03_01',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'TXKyD3xVwGEK',
            kuids: [ '19_01_04' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '7',
            qn: '高次導関数',
            qnum1: '3',
            qnum2: '2',
            quid: '19_02_03_02',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'NAwzNva96RpG',
            kuids: [ '19_01_04' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '8',
            qn: '高次導関数',
            qnum1: '3',
            qnum2: '3',
            quid: '19_02_03_03',
            hn: 'Ⅲ / 微分 / 微分計算（応用編）',
            vid: 'TDl3VMEbzMlk',
            kuids: [ '19_01_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '接線',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '接線（接点あり）',
            qnum1: '4',
            qnum2: '1',
            quid: '19_03_04_01',
            hn: 'Ⅲ / 微分 / 接線',
            vid: '4VJZEW8xXKGT',
            kuids: [ '19_02_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '接線（接点あり）',
            qnum1: '4',
            qnum2: '2',
            quid: '19_03_04_02',
            hn: 'Ⅲ / 微分 / 接線',
            vid: '5dEPsuzcRP_2',
            kuids: [ '19_02_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '3',
            qn: '接線（接点あり）',
            qnum1: '4',
            qnum2: '3',
            quid: '19_03_04_03',
            hn: 'Ⅲ / 微分 / 接線',
            vid: 'CoXNimpBI3WE',
            kuids: [ '19_02_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '4',
            qn: '接線（接点あり）',
            qnum1: '4',
            qnum2: '4',
            quid: '19_03_04_04',
            hn: 'Ⅲ / 微分 / 接線',
            vid: 'kVvxttMcupZ1',
            kuids: [ '19_02_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '5',
            qn: '接線（接点設定）',
            qnum1: '5',
            qnum2: '0',
            quid: '19_03_05_00',
            hn: 'Ⅲ / 微分 / 接線',
            vid: 'koR-eWQJqwgd',
            kuids: [ '19_02_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '6',
            qn: '２曲線が接する',
            qnum1: '6',
            qnum2: '0',
            quid: '19_03_06_00',
            hn: 'Ⅲ / 微分 / 接線',
            vid: 'j5LxKwFjRUpx',
            kuids: [ '19_02_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: 'グラフ　その１',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '極値・グラフ（基本）',
            qnum1: '7',
            qnum2: '1',
            quid: '19_04_07_01',
            hn: 'Ⅲ / 微分 / グラフ　その１',
            vid: 'oTojT9f6dUTM',
            kuids: [ '19_03_01', '19_03_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '極値・グラフ（基本）',
            qnum1: '7',
            qnum2: '2',
            quid: '19_04_07_02',
            hn: 'Ⅲ / 微分 / グラフ　その１',
            vid: 'VIJCInZZXWBY',
            kuids: [ '19_03_01', '19_03_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '3',
            qn: '極値・グラフ（基本）',
            qnum1: '7',
            qnum2: '3',
            quid: '19_04_07_03',
            hn: 'Ⅲ / 微分 / グラフ　その１',
            vid: 'iN2oZkIAD0rz',
            kuids: [ '19_03_01', '19_03_02' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '4',
            qn: '極値・グラフ（基本）',
            qnum1: '7',
            qnum2: '4',
            quid: '19_04_07_04',
            hn: 'Ⅲ / 微分 / グラフ　その１',
            vid: 'dDC99hGJY5gk',
            kuids: [ '19_03_01', '19_03_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: 'グラフ　その２',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '極値・グラフ（凸凹・漸近線）',
            qnum1: '8',
            qnum2: '1',
            quid: '19_05_08_01',
            hn: 'Ⅲ / 微分 / グラフ　その２',
            vid: 'av4Fci60Ydjf',
            kuids: [ '19_03_05', '19_03_03', '19_03_04' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '極値・グラフ（凸凹・漸近線）',
            qnum1: '8',
            qnum2: '2',
            quid: '19_05_08_02',
            hn: 'Ⅲ / 微分 / グラフ　その２',
            vid: 'j8TJXSW59oP6',
            kuids: [ '19_03_05', '19_03_03', '19_03_04' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: 'グラフ　その３',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: 'グラフ（パラメーター）',
            qnum1: '9',
            qnum2: '0',
            quid: '19_06_09_00',
            hn: 'Ⅲ / 微分 / グラフ　その３',
            vid: 'ipyja2hXfXes',
            kuids: [ '19_03_06' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '極値をもつ条件',
            qnum1: '10',
            qnum2: '0',
            quid: '19_06_10_00',
            hn: 'Ⅲ / 微分 / グラフ　その３',
            vid: '0og7-9ccd2xK',
            kuids: [ '19_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '最大・最小',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '最大最小（基本）',
            qnum1: '11',
            qnum2: '1',
            quid: '19_07_11_01',
            hn: 'Ⅲ / 微分 / 最大・最小',
            vid: 'bq2PP1JXl0NR',
            kuids: [ '19_04_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '最大最小（基本）',
            qnum1: '11',
            qnum2: '2',
            quid: '19_07_11_02',
            hn: 'Ⅲ / 微分 / 最大・最小',
            vid: 's5LtnsNIWlDP',
            kuids: [ '19_04_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '3',
            qn: '最大最小（基本）',
            qnum1: '11',
            qnum2: '3',
            quid: '19_07_11_03',
            hn: 'Ⅲ / 微分 / 最大・最小',
            vid: 'qCeaK2z79UgT',
            kuids: [ '19_04_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '4',
            qn: '最大最小（文章題）',
            qnum1: '12',
            qnum2: '0',
            quid: '19_07_12_00',
            hn: 'Ⅲ / 微分 / 最大・最小',
            vid: 'mjqwMIMZ7Fyb',
            kuids: [ '19_04_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '5',
            qn: '最大最小（応用）',
            qnum1: '13',
            qnum2: '0',
            quid: '19_07_13_00',
            hn: 'Ⅲ / 微分 / 最大・最小',
            vid: 'evUHc22u7s3U',
            kuids: [ '19_04_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '方程式＆不等式への応用',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '方程式への応用',
            qnum1: '14',
            qnum2: '0',
            quid: '19_08_14_00',
            hn: 'Ⅲ / 微分 / 方程式＆不等式への応用',
            vid: 'Y6FHhl9nP44t',
            kuids: [ '19_05_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '接線の本数',
            qnum1: '15',
            qnum2: '0',
            quid: '19_08_15_00',
            hn: 'Ⅲ / 微分 / 方程式＆不等式への応用',
            vid: 'tgRNHtnauenc',
            kuids: [ '19_05_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '3',
            qn: '不等式の証明',
            qnum1: '16',
            qnum2: '0',
            quid: '19_08_16_00',
            hn: 'Ⅲ / 微分 / 方程式＆不等式への応用',
            vid: 'LLM7ZOF6THsW',
            kuids: [ '19_05_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '微分可能と連続&平均値の定理',
        hn: 'Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '微分可能と連続',
            qnum1: '17',
            qnum2: '0',
            quid: '19_09_17_00',
            hn: 'Ⅲ / 微分 / 微分可能と連続&平均値の定理',
            vid: '2_-q34gF7-S8',
            kuids: [ '19_06_01' ],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '平均値の定理',
            qnum1: '18',
            qnum2: '0',
            quid: '19_09_18_00',
            hn: 'Ⅲ / 微分 / 微分可能と連続&平均値の定理',
            vid: 'M5eCcXxcoJ2u',
            kuids: [ '19_06_02' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '20',
    un: '積分',
    fid: '3',
    hn: 'Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '不定積分',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '1',
            quid: '20_01_01_01',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: '0BR46t1mi8P9',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '2',
            quid: '20_01_01_02',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'fFvG4gpKoLcV',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '3',
            quid: '20_01_01_03',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'ssUB-yk15VMG',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '4',
            quid: '20_01_01_04',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'rLqIDKpOG8m1',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '5',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '5',
            quid: '20_01_01_05',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'PQoWLP_EFeJ0',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '6',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '6',
            quid: '20_01_01_06',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'xWYQVAd44DLt',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '7',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '7',
            quid: '20_01_01_07',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'IfyNYORP7RHO',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '8',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '8',
            quid: '20_01_01_08',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'JvugSrw1EWgU',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '9',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '9',
            quid: '20_01_01_09',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'a5M0r0FuaYxS',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '10',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '10',
            quid: '20_01_01_10',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: '5BPkiVgsFfSO',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '不定積分',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '11',
            quid: '20_02_01_11',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'd4EMuEGiNJUW',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '12',
            quid: '20_02_01_12',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'T_eq1i2u4mxR',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '13',
            quid: '20_02_01_13',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'j_I5py98Vul1',
            kuids: [ '20_01_02', '20_01_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '14',
            quid: '20_02_01_14',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'NJCZ8q9MLlgo',
            kuids: [ '20_01_04' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '5',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '15',
            quid: '20_02_01_15',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'kwQZkoiTUzdi',
            kuids: [ '20_01_04' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '6',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '16',
            quid: '20_02_01_16',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'w0vaqRyGpoCm',
            kuids: [ '20_01_04' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '7',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '17',
            quid: '20_02_01_17',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'DZQpcMIos5cp',
            kuids: [ '20_01_04' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '8',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '18',
            quid: '20_02_01_18',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: '8d-zIW7TyGBg',
            kuids: [ '20_01_05' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '9',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '19',
            quid: '20_02_01_19',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'x50ZxpXag9RP',
            kuids: [ '20_01_05' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '10',
            qn: '不定積分の計算',
            qnum1: '1',
            qnum2: '20',
            quid: '20_02_01_20',
            hn: 'Ⅲ / 積分 / 不定積分',
            vid: 'Z7dGZgdQcwgH',
            kuids: [ '20_01_05' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '定積分',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '1',
            quid: '20_03_02_01',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'G8FKF2nbpjnC',
            kuids: [ '20_01_06' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '2',
            quid: '20_03_02_02',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 's3Bb5nueNdLZ',
            kuids: [ '20_01_06' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '3',
            quid: '20_03_02_03',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: '9OAfkgYHbT0g',
            kuids: [ '20_01_06' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '4',
            quid: '20_03_02_04',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'zSXPQpVwYrAZ',
            kuids: [ '20_01_06', '20_01_12' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '5',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '5',
            quid: '20_03_02_05',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'aCdtIA-Kszx2',
            kuids: [ '20_01_07' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '6',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '6',
            quid: '20_03_02_06',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'kb1WOMlCMJWW',
            kuids: [ '20_01_07' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '7',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '7',
            quid: '20_03_02_07',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'G57-SjzjZmHP',
            kuids: [ '20_01_08' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '8',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '8',
            quid: '20_03_02_08',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'eNuXijX_gCpW',
            kuids: [ '20_01_08' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '9',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '9',
            quid: '20_03_02_09',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'wobFW2z9kObY',
            kuids: [ '20_01_08' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '10',
            qn: '定積分の計算',
            qnum1: '2',
            qnum2: '10',
            quid: '20_03_02_10',
            hn: 'Ⅲ / 積分 / 定積分',
            vid: 'JYtah3q9h9Sm',
            kuids: [ '20_01_10' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '定積分で表された関数',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '偶関数・奇関数',
            qnum1: '3',
            qnum2: '0',
            quid: '20_04_03_00',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'cOtgZyRNO8Rn',
            kuids: [ '20_01_13' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '定積分と漸化式',
            qnum1: '4',
            qnum2: '0',
            quid: '20_04_04_00',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'wm18AGjwdVMe',
            kuids: [ '20_01_15' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '関数決定（区間が定数のみ）',
            qnum1: '5',
            qnum2: '1',
            quid: '20_04_05_01',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'yRY8f_gom-GJ',
            kuids: [ '20_02_01', '20_02_02' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: '関数決定（区間が定数のみ）',
            qnum1: '5',
            qnum2: '2',
            quid: '20_04_05_02',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'tPsl879hNlfK',
            kuids: [ '20_02_01', '20_02_02' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '5',
            qn: '微積分学の基本定理',
            qnum1: '6',
            qnum2: '0',
            quid: '20_04_06_00',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'S2JbSldca_MI',
            kuids: [ '20_02_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '6',
            qn: '関数決定（区間に変数含む）',
            qnum1: '7',
            qnum2: '0',
            quid: '20_04_07_00',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'B1nG7utqQ6-y',
            kuids: [ '20_02_01', '20_02_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '7',
            qn: '関数＆定数決定（区間に変数含む）',
            qnum1: '8',
            qnum2: '0',
            quid: '20_04_08_00',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'VCo8GwGRUwls',
            kuids: [ '20_02_01', '20_02_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '面積',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '面積の基本',
            qnum1: '9',
            qnum2: '1',
            quid: '20_05_09_01',
            hn: 'Ⅲ / 積分 / 面積',
            vid: 'xirCDSitxE7d',
            kuids: [ '20_03_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '面積の基本',
            qnum1: '9',
            qnum2: '2',
            quid: '20_05_09_02',
            hn: 'Ⅲ / 積分 / 面積',
            vid: '2dz684SlCYq4',
            kuids: [ '20_03_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '接線と面積',
            qnum1: '10',
            qnum2: '0',
            quid: '20_05_10_00',
            hn: 'Ⅲ / 積分 / 面積',
            vid: 'ZxlJu-tWTqBG',
            kuids: [ '20_03_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: '面積（交点設定）',
            qnum1: '11',
            qnum2: '0',
            quid: '20_05_11_00',
            hn: 'Ⅲ / 積分 / 面積',
            vid: 'Pj8cRWAMMywg',
            kuids: [ '20_03_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '5',
            qn: '陰関数と面積',
            qnum1: '12',
            qnum2: '0',
            quid: '20_05_12_00',
            hn: 'Ⅲ / 積分 / 面積',
            vid: '7ZmsPUu6i_eu',
            kuids: [ '20_03_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '6',
            qn: '媒介変数表示の関数と面積',
            qnum1: '13',
            qnum2: '0',
            quid: '20_05_13_00',
            hn: 'Ⅲ / 積分 / 面積',
            vid: 'hTMFXkPSUKHl',
            kuids: [ '20_03_01' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '区分求積法・不等式への応用',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '区分求積法',
            qnum1: '14',
            qnum2: '1',
            quid: '20_06_14_01',
            hn: 'Ⅲ / 積分 / 区分求積法・不等式への応用',
            vid: 'Uam-0cyDtDaI',
            kuids: [ '20_03_02' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '区分求積法',
            qnum1: '14',
            qnum2: '2',
            quid: '20_06_14_02',
            hn: 'Ⅲ / 積分 / 区分求積法・不等式への応用',
            vid: '5GC9s2l8EEKP',
            kuids: [ '20_03_02' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '長方形の階段',
            qnum1: '15',
            qnum2: '0',
            quid: '20_06_15_00',
            hn: 'Ⅲ / 積分 / 区分求積法・不等式への応用',
            vid: 'WvptoJXuZFUi',
            kuids: [ '20_03_03' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: '定積分と不等式',
            qnum1: '16',
            qnum2: '0',
            quid: '20_06_16_00',
            hn: 'Ⅲ / 積分 / 区分求積法・不等式への応用',
            vid: 'vp4m3u88hBgK',
            kuids: [ '20_03_03' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '体積',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '非回転体の体積',
            qnum1: '17',
            qnum2: '0',
            quid: '20_07_17_00',
            hn: 'Ⅲ / 積分 / 体積',
            vid: 'rH3WfkicteUw',
            kuids: [ '20_04_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: 'ｘ軸まわりの回転（y=f(x)型）',
            qnum1: '18',
            qnum2: '0',
            quid: '20_07_18_00',
            hn: 'Ⅲ / 積分 / 体積',
            vid: '1HuIWpUi_fcX',
            kuids: [ '20_04_02' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: 'ｘ軸、ｙ軸まわりの回転（y=f(x)型）',
            qnum1: '19',
            qnum2: '0',
            quid: '20_07_19_00',
            hn: 'Ⅲ / 積分 / 体積',
            vid: 'lhi8P_RtxusR',
            kuids: [ '20_04_02' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: 'ｘ軸まわりの回転（陰関数型）',
            qnum1: '20',
            qnum2: '0',
            quid: '20_07_20_00',
            hn: 'Ⅲ / 積分 / 体積',
            vid: 'eb24cNT-0eiF',
            kuids: [ '20_04_02' ],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '曲線の長さ',
        hn: 'Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '曲線の長さの基本',
            qnum1: '21',
            qnum2: '1',
            quid: '20_08_21_01',
            hn: 'Ⅲ / 積分 / 曲線の長さ',
            vid: 'vL6F9II6c7A_',
            kuids: [ '20_05_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '曲線の長さの基本',
            qnum1: '21',
            qnum2: '2',
            quid: '20_08_21_02',
            hn: 'Ⅲ / 積分 / 曲線の長さ',
            vid: 'scrMlzDtTm2I',
            kuids: [ '20_05_01' ],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '曲線の長さと極限',
            qnum1: '22',
            qnum2: '0',
            quid: '20_08_22_00',
            hn: 'Ⅲ / 積分 / 曲線の長さ',
            vid: 'JRIFUiiwT9hk',
            kuids: [ '20_05_01' ],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  }
];